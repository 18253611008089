import { daDK, deDE, enUS, esES, fiFI, frFR, huHU, itIT, nbNO, nlNL, plPL, ptBR, ruRU, svSE, zhCN } from '@mui/x-data-grid-premium/locales';
import { addTranslations, ResourceWithFallbackLanguage, typedTranslationBase, useTypedTranslationHook } from '../localization';

import translations_de from "../translations/de.json";
import translations_en from "../translations/en.json";
import translations_fr from "../translations/fr.json";
import translations_hu from "../translations/hu.json";
import translations_it from "../translations/it.json";
import translations_nl from "../translations/nl.json";
import translations_pl from "../translations/pl.json";
import translations_sv from "../translations/sv.json";
import translations_zh from "../translations/zh.json";

export type ProgressBaseUITranslations = typeof translations_de;

const bundleKey = "progress-base-ui";

const shortDisplayNames = Object.freeze({
    de: "Deutsch",
    en: "English",
    fr: "Français",
    nl: "Nederlands",
    hu: "Magyar",
    zh: "简体中文",
    it: "Italiano",
    pl: "Polska",
    sr: "Српски",
    pt: "Português",
    ru: "русский",
    fi: "Suomi",
    nb: "Bokmål",
    da: "Danish",
    es: "Español",
    sv: "Svenska",
});
type KnownLanguageCodes = keyof typeof shortDisplayNames

export const localeToDisplayName = (locale: string): string => {
    if (shortDisplayNames[locale as KnownLanguageCodes]) {
        return shortDisplayNames[locale as KnownLanguageCodes]
    }

    const [languageCode, regionCode] = locale.split('-');
    if (shortDisplayNames[languageCode as KnownLanguageCodes]) {
        return `${shortDisplayNames[languageCode as KnownLanguageCodes]} (${regionCode.toUpperCase()})`;
    }

    return locale;
}

const resources: ResourceWithFallbackLanguage = {
    de: translations_de,
    en: translations_en,
    fr: translations_fr,
    hu: translations_hu,
    it: translations_it,
    nl: translations_nl,
    pl: translations_pl,
    sv: translations_sv,
    zh: translations_zh,
}

export const addResources = () => {
    addTranslations({
        bundleKey,
        resourceBundles: resources
    });
}

export function useTypedTranslation() {
    return useTypedTranslationHook<ProgressBaseUITranslations>(bundleKey);
}

export function typedTranslation() {
    return typedTranslationBase<ProgressBaseUITranslations>(bundleKey)
}

export const getMuiDataGridTranslations = (language: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [languageCode, regionCode] = language.toLowerCase().split('-');

    switch (languageCode) {
        case "en":
            return enUS.components.MuiDataGrid.defaultProps.localeText;
        case "fr":
            return frFR.components.MuiDataGrid.defaultProps.localeText;
        case "it":
            return itIT.components.MuiDataGrid.defaultProps.localeText;
        case "es":
            return esES.components.MuiDataGrid.defaultProps.localeText;
        case "da":
            return daDK.components.MuiDataGrid.defaultProps.localeText;
        case "fi":
            return fiFI.components.MuiDataGrid.defaultProps.localeText;
        case "hu":
            return huHU.components.MuiDataGrid.defaultProps.localeText;
        case "nl":
            return nlNL.components.MuiDataGrid.defaultProps.localeText;
        case "nb":
            return nbNO.components.MuiDataGrid.defaultProps.localeText;
        case "pl":
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        case "pt":
            return ptBR.components.MuiDataGrid.defaultProps.localeText;
        case "ru":
            return ruRU.components.MuiDataGrid.defaultProps.localeText;
        case "zh":
            return zhCN.components.MuiDataGrid.defaultProps.localeText;
        case "sv":
            return svSE.components.MuiDataGrid.defaultProps.localeText;

        // fallback (default language = de)
        case "sr": // => Serbisch is not translated by mui
        case "de":
        default:
            return deDE.components.MuiDataGrid.defaultProps.localeText;
    }
}