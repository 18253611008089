import { api } from '@/definitions';
import { useReduxDispatch, useTypedSelector } from '@/store/configureStore';
import { remoteFilesAdapter, setLoading, setSelectedFile, useGetRemoteFilesQuery } from '@/store/data/configSheet/configSheetSlice';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, List, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import InputAdornment from '@mui/material/InputAdornment';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import Swal from 'sweetalert2';
import { LayoutFile, LayoutFileContent, LayoutFileCreateRequest } from '../../../definitions/autogenerated/types';
import { withCanvasContext } from '../FabricContext';
import { readFileAsText } from '../fileHelpers';
import { ProgressCanvas } from '../ProgressCanvas';

export const TreeExplorer = withCanvasContext(({ canvas }) => {
    const [open, setOpen] = useState(true);

    const { data, isFetching: remoteFilesLoading, refetch } = useGetRemoteFilesQuery();

    const [createLayoutDialogOpen, setCreateLayoutDialogOpen] = useState(false);
    const [search, setSearch] = useState("")
    const [searchActive, setSearchActive] = useState(false)

    const remoteFiles = useMemo(() => {
        if (!data)
            return []
        return remoteFilesAdapter.getSelectors().selectAll(data).filter(file => !search || (file.FileId.toLowerCase().includes(search.toLowerCase())))
    }, [data, search]);

    const showTreeExplorer = useTypedSelector(store => store.data.configSheet.showTreeExplorer)
    const selectedFile = useTypedSelector(store => store.data.configSheet.selectedFile);
    const localFiles = useTypedSelector(store => store.data.configSheet.localFiles);
    const dispatch = useReduxDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const loadRemoteFiles = useCallback(() => {
        refetch()
    }, [refetch])

    useEffect(() => {
        loadRemoteFiles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const selectLocalFileById = useCallback(async (fileId: string) => {
        // check for unsaved changes
        if (canvas.getObjects().filter(e => e.isDirty).length > 0 || canvas.isDirty) {
            // eslint-disable-next-line no-restricted-globals
            if (!confirm("There are unsaved changes. Close config without saving?")) {
                return;
            }
        }

        const fileEntry = localFiles.find(e => e.name === fileId);

        if (fileEntry) {
            // const file = await (fileEntry.fileHandle as FileSystemFileHandle).getFile();
            const file = await fileEntry.fileHandle.getFile();
            const content = await readFileAsText(file) as string;
            const result = JSON.parse(content);
            canvas.discardActiveObject();
            canvas.clearHistory()
            canvas.offHistory();
            canvas.fromJSON(result);
            canvas.fileHash = "";
            canvas.onHistory();

            dispatch(setSelectedFile({ name: fileEntry.name, sourceType: 'localFile' }))
        }
    }, [canvas, dispatch, localFiles])

    const selectPlayground = useCallback(() => {
        // check for unsaved changes
        if (canvas.getObjects().filter(e => e.isDirty).length > 0 || canvas.isDirty) {
            // eslint-disable-next-line no-restricted-globals
            if (!confirm("There are unsaved changes. Close config without saving?")) {
                return;
            }
        }

        canvas.discardActiveObject();
        canvas.clearHistory()
        canvas.offHistory();
        canvas.fromJSON(ProgressCanvas.getInitialJSON());
        canvas.fileHash = "";
        canvas.onHistory();

        dispatch(setSelectedFile({ name: 'playground', sourceType: 'playground' }))
    }, [canvas, dispatch])

    const deleteRemoteFile = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: LayoutFile) => {
        e.preventDefault();
        e.stopPropagation();
        Swal.fire({
            title: 'Confirm',
            text: 'Delete file?',
            showCancelButton: true,
            cancelButtonText: 'No',
            showConfirmButton: true,
            confirmButtonText: 'Yes'
        }).then(result => {
            if (result.isConfirmed) {
                dispatch(setLoading(true))
                axios.delete(api.configSheetRemoteFileById(item.FileId)).then(() => {
                    refetch();
                    enqueueSnackbar("File deleted", { variant: 'success' });
                    canvas.acceptChanges();
                    selectPlayground();
                }).catchError(e => {
                    enqueueSnackbar("Could not delete file: " + e.Description, { variant: 'error' })
                }).finally(() => {
                    dispatch(setLoading(false));
                });
            }
        })
    }, [canvas, dispatch, enqueueSnackbar, refetch, selectPlayground])

    const selectRemoteFile = useCallback((remoteFile: LayoutFile) => {
        // check for unsaved changes
        if (canvas.getObjects().filter(e => e.isDirty).length > 0 || canvas.isDirty) {
            // eslint-disable-next-line no-restricted-globals
            if (!confirm("There are unsaved changes. Close config without saving?")) {
                return;
            }
        }

        canvas.discardActiveObject();

        dispatch(setLoading(true));
        axios.get<LayoutFileContent>(api.configSheetRemoteFileById(remoteFile.FileId)).then(response => {
            const result = JSON.parse(response.data.FileContent) as SerializedData;
            canvas.offHistory();
            canvas.fromJSON(result);
            canvas.fileHash = response.data.FileHash;
            canvas.onHistory();
            canvas.clearHistory()

            refetch()
            dispatch(setSelectedFile({ name: remoteFile.FileId, sourceType: 'remoteFile' }))
        }).catchError(() => {
            enqueueSnackbar("Could not load layout", { variant: 'error' });
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }, [canvas, dispatch, enqueueSnackbar, refetch])

    const handleAddNewLayout = useCallback(() => {
        setCreateLayoutDialogOpen(true)
    }, [])

    const activateSearch = useCallback(() => {
        setSearchActive(true)
    }, [])

    const disableSearch = useCallback(() => {
        setSearchActive(false)
        setSearch("")
    }, [])

    const onNewLayoutCreated = useCallback((remoteFile: LayoutFile) => {
        refetch()
        selectRemoteFile(remoteFile);
    }, [refetch, selectRemoteFile])

    if (!showTreeExplorer) {
        return <></>
    }

    return <div style={{ width: 300, borderRight: 'solid 1px #333', overflowY: 'scroll', flexShrink: 0 }}>
        <List dense sx={{ mx: '5px' }}>
            <ListItem sx={{ background: '#ddd', borderRadius: 2, mb: 1, height: '40px', py: 0 }}>
                <ListItemText primary="Playground" />
            </ListItem>
            <ListItem disablePadding onClick={selectPlayground}>
                <ListItemButton selected={selectedFile.sourceType === 'playground'}>
                    <ListItemText primary="Playground" />
                </ListItemButton>
            </ListItem>

            <ListItem sx={{ background: '#ddd', borderRadius: 2, my: 1, position: 'relative', py: 0 }}>
                {
                    searchActive ?
                        <TextField
                            size="small"
                            style={{ margin: 0, height: 30, maxHeight: 30 }}
                            fullWidth
                            autoFocus
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            InputProps={{ style: { height: 30, maxHeight: 30, borderRadius: 15 }, endAdornment: <IconButton onClick={disableSearch} size="small">X</IconButton> }}
                        />
                        :
                        <>
                            <ListItemText primary="Remote files" />
                            <IconButton edge="end" onClick={activateSearch}>
                                <SearchIcon />
                            </IconButton>
                        </>
                }
                <IconButton edge="end" onClick={handleAddNewLayout}>
                    <AddIcon />
                </IconButton>
                <IconButton edge="end" onClick={loadRemoteFiles}>
                    <RefreshIcon />
                </IconButton>
                <IconButton edge="end" onClick={() => setOpen(o => !o)} disabled={remoteFilesLoading}>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
                <LinearProgress sx={{ visibility: remoteFilesLoading ? 'visible' : 'hidden', position: 'absolute', left: 0, right: 0, bottom: 0 }} />
            </ListItem>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding dense>
                    {
                        remoteFiles.map((item) => {
                            return <ListItem key={item.FileId} disablePadding onClick={() => selectRemoteFile(item)}>
                                <ListItemButton selected={selectedFile.sourceType === 'remoteFile' && selectedFile.name === item.FileId}>
                                    <ListItemText primary={item.FileName} secondary={item.Directory} />
                                    <IconButton edge="end" onClick={(e) => deleteRemoteFile(e, item)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemButton>
                            </ListItem>
                        })
                    }
                    {
                        (remoteFiles.length === 0 && !remoteFilesLoading) && <ListItem disablePadding>
                            <Alert severity="info" sx={{ width: '100%' }}>No remote files.</Alert>
                        </ListItem>
                    }
                </List>
            </Collapse>

            <ListItem sx={{ background: '#ddd', borderRadius: 2, my: 1, height: '40px', py: 0 }}>
                <ListItemText primary="Local files" />
            </ListItem>
            {
                localFiles.map((item, index) => {
                    return <ListItem key={index} disablePadding onClick={() => selectLocalFileById(item.name)}>
                        <ListItemButton selected={selectedFile.sourceType === 'localFile' && selectedFile.name === item.name}>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                })
            }
            {
                localFiles.length === 0 && <ListItem disablePadding>
                    <Alert severity="info" sx={{ width: '100%' }}>No local files. Drag and drop a file to edit.</Alert>
                </ListItem>
            }
        </List>
        {createLayoutDialogOpen && <CreateLayoutDialog onClose={() => setCreateLayoutDialogOpen(false)} onNewLayoutCreated={onNewLayoutCreated} />}
    </div >
})

const CreateLayoutDialog: React.FC<{ onClose(): void, onNewLayoutCreated(remoteFile: LayoutFile): void }> = ({ onClose, onNewLayoutCreated }) => {
    const [loading, setLoading] = useState(false)
    const [directory, setDirectory] = useState("")
    const [fileName, setFileName] = useState("")

    const { enqueueSnackbar } = useSnackbar()

    const generate = useCallback(() => {
        if (!fileName) return;

        setLoading(true)

        const layoutContent = JSON.stringify(ProgressCanvas.getInitialJSON())

        const payload: LayoutFileCreateRequest = {
            FileName: fileName + '.json',
            Directory: directory,
            LayoutContent: layoutContent
        }

        axios.post<LayoutFile>(api.configSheetRemoteFiles, payload).then(response => {
            onNewLayoutCreated(response.data)
            enqueueSnackbar("File created", { variant: 'success' })
            setLoading(false)
            onClose();
        }).catchError((e) => {
            enqueueSnackbar("Could not create file: " + e.Description, { variant: 'error' })
            setLoading(false)
        })
    }, [directory, enqueueSnackbar, fileName, onClose, onNewLayoutCreated])

    return <Dialog open>
        <DialogTitle>Add a new layout</DialogTitle>
        <DialogContent>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField
                        value={directory} onChange={e => setDirectory(e.target.value)} size="small"
                        margin="dense" fullWidth label="Directory"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={fileName}
                        onChange={e => setFileName(e.target.value)}
                        size="small"
                        margin="dense"
                        fullWidth
                        label="Layout name"
                        autoFocus
                        InputProps={
                            {
                                endAdornment: <InputAdornment position="end">.json</InputAdornment>,
                            }
                        }
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button disabled={loading} onClick={onClose}>Cancel</Button>
            <LoadingButton
                loading={loading} disabled={!directory || !fileName} onClick={generate}
                variant="contained"
            >
Create
            </LoadingButton >
        </DialogActions>
    </Dialog>
}