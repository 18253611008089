import { minWidthColumnProps } from '@/components/CustomGridComponents';
import { api, useTypedTranslation } from '@/definitions';
import { DtoConfigContent, DtoConfigUpdateContent, PipelineChange, PipelineChangeLists, PipelineDetailDto, PipelineDto } from '@/definitions/autogenerated/types';
import { AuthenticationUtils } from '@/helpers/authenticationUtils';
import { useReduxDispatch, useTypedSelector } from '@/store/configureStore';
import { fetchPipelines } from '@/store/data/pipelines/actions';
import { Close } from '@mui/icons-material';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, CircularProgress, Container, Grid, IconButton, Paper, styled } from '@mui/material';
import { DataGridPremium, GridCellParams, GridColDef, GridRenderCellParams, GridSlotsComponent, GridToolbarQuickFilter } from '@mui/x-data-grid-premium';
import { AutoRefreshButton, Logger, nameof, ProgressDialog, useOnMount } from '@progress/base-ui';
import 'ace-builds/src-noconflict/ace';
import "ace-builds/src-min-noconflict/ext-searchbox";
import Beautify from 'ace-builds/src-noconflict/ext-beautify';
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-tomorrow";
import axios from 'axios';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AceEditor from "react-ace";
import { defaultGridProps, limitedColumnProps } from '../components/CustomGridComponents';

const StyledContainer = styled(Container)(({ theme }) => ({
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: "2100px !important",

    "& .pipeline-error": {
        color: "red"
    },

    "& .pipeline-success": {
        color: "green"
    },
}));

const rootStyle: React.CSSProperties = {
    margin: "0",
    textAlign: "center"
}

const divStyle: React.CSSProperties = {
    width: "100%",
}

const divStyleHead: React.CSSProperties = {
    display: "inline-block",
    width: "100%",
    alignItems: "relative",
    margin: "1"
}

const PipelineOverview = () => {
    const { t } = useTypedTranslation();
    const dispatch = useReduxDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [pipelineOutputId, setPipelineOutputId] = useState<number>()
    const [pipelineDifferenceId, setPipelineDifferenceId] = useState<number>()


    const hasAppManagementPermission = AuthenticationUtils.hasAppManagementPermission();

    const rows = useTypedSelector(state => state.data.pipelines.pipelines)
    const displayRows = useMemo(() => {
        return rows.map(row => {
            return {
                ...row,
                CreatedAt: DateTime.fromISO(row.CreatedAt).toFormat('yyyy-MM-dd HH:mm:ss'),
                CompletedAt: row.CompletedAt ? DateTime.fromISO(row.CompletedAt).toFormat('yyyy-MM-dd HH:mm:ss') : row.CompletedAt,
            }
        })
    }, [rows]);

    const handleShowOutput = useCallback(({ row }: GridRenderCellParams<PipelineDto, PipelineDto>) => {
        return <IconButton size="large" onClick={() => setPipelineOutputId(row.Id)}>
            <FilePresentIcon />
        </IconButton>
    }, [])

    const handleShowDifference = useCallback(({ row }: GridRenderCellParams<PipelineDto, PipelineDto>) => {
        return <IconButton size="large" onClick={() => setPipelineDifferenceId(row.Id)} disabled={row.Id === 1}>
            <FilePresentIcon />
        </IconButton>
    }, [])

    const columns = useMemo<GridColDef<PipelineDto>[]>(() => [
        {
            width: 75,
            field: nameof<PipelineDto>('Id'),
            headerName: "Id"
        },
        {
            ...minWidthColumnProps,
            field: nameof<PipelineDto>('Name'),
            headerName: "Name"
        },
        {
            width: 125,
            field: nameof<PipelineDto>('Status'),
            headerName: "Status",
            cellClassName: (params: GridCellParams) => {
                switch (params.value as PipelineDto["Status"]) {
                    case "Succeeded": {
                        return "pipeline-success"
                    }

                    case "Failed": {
                        return "pipeline-error"
                    }

                    case "Running": {
                        return ""
                    }

                    default: {
                        return ""
                    }
                }
            }
        },
        {
            width: 300,
            field: nameof<PipelineDto>('CreatedBy'),
            headerName: "Created by"
        },
        {
            width: 175,
            field: nameof<PipelineDto>('CreatedAt'),
            headerName: "Created at"
        },
        {
            width: 175,
            field: nameof<PipelineDto>('CompletedAt'),
            headerName: "Completed at"
        },
        {
            ...limitedColumnProps,
            field: 'action',
            headerName: 'Output',
            resizable: false,
            width: 90,
            renderCell: handleShowOutput,
        },
        {
            ...limitedColumnProps,
            field: 'difference',
            headerName: 'Difference',
            resizable: false,
            width: 90,
            renderCell: handleShowDifference,
        }
    ], [handleShowOutput, handleShowDifference]);

    const loadPipelines = useCallback(() => {
        setIsLoading(true);
        dispatch(fetchPipelines())
            .unwrap()
            .catchError((e) => {
                enqueueSnackbar(t("main", "loadingError"), { variant: 'error' })
                Logger.error(e.Description)
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [dispatch, enqueueSnackbar, t])

    useOnMount(() => {
        loadPipelines();
    });

    const [manualTriggerLoading, setManualTriggerLoading] = useState(false);
    const handleTriggerPipeline = () => {
        setManualTriggerLoading(true)
        axios.post(api.pipelineManualTrigger).then(() => {
            enqueueSnackbar('Success', { variant: 'success' })
            loadPipelines()
        }).catchError(e => {
            enqueueSnackbar(e.Description, { variant: 'error' })
        }).finally(() => {
            setManualTriggerLoading(false)
        })
    }

    return (
        <StyledContainer maxWidth="lg" className="container">
            <div className='container' style={rootStyle}>
                <div className='container' style={divStyle}>
                    <div style={divStyleHead}>
                        <Box
                            m={1}
                            display="flex"
                            gap="10px"
                            alignItems="flex-end"
                        >
                            <h1 className="containerTitle">Pipelines</h1>
                            <div style={{ flexGrow: 1 }} />
                            <SyncZabbixStateButton />
                            <ConfigPreview configName="config" buttonTitle="Show config" />
                            <ConfigPreview configName="aks_stack" buttonTitle="Show AKS stack" />
                            <ConfigPreview configName="zabbix_stack" buttonTitle="Show Zabbix stack" />
                            <LoadingButton
                                variant="outlined"
                                loading={manualTriggerLoading}
                                disabled={manualTriggerLoading || !hasAppManagementPermission}
                                onClick={handleTriggerPipeline}
                            >
                                Trigger pipeline
                            </LoadingButton>
                            <AutoRefreshButton
                                variant="outlined"
                                loading={isLoading}
                                onRefresh={loadPipelines}
                                onClick={loadPipelines}
                                refreshInterval={30000}
                            >
                                {t("main", "refresh")}
                            </AutoRefreshButton>
                        </Box>
                    </div>
                    <Paper className="container">
                        <DataGridPremium
                            {...defaultGridProps}

                            className="progress-table"
                            rows={displayRows}
                            columns={columns}
                            getRowId={getRowId}
                            loading={isLoading}
                            slots={customComponents}
                            slotProps={{loadingOverlay: {variant: "circular-progress"}}}
                        />
                    </Paper>
                </div>
            </div>

            {pipelineOutputId && <PipelineOutputDialog pipelineOutputId={pipelineOutputId} onClose={() => setPipelineOutputId(undefined)} />}
            {pipelineDifferenceId && <PipelineDifferenceDialog pipelineDifferenceId={pipelineDifferenceId} onClose={() => setPipelineDifferenceId(undefined)} />}

        </StyledContainer>
    );
}

export default PipelineOverview;

const getRowId = (row: PipelineDto) => row.Id;

const customComponents: Partial<GridSlotsComponent> = ({
    toolbar: () => {
        return (
            <Grid
                container
                display="flex"
                flexDirection="row"
                width="100%"
                height="60px"
                alignItems="center"
                borderBottom="1px solid #e0e0e0"
            >
                <Grid item xs={12}>
                    <GridToolbarQuickFilter
                        autoComplete='off' variant='outlined' size='small'
                        style={{ padding: '10px 0' }}
                    />
                </Grid>
                <Grid item xs />
            </Grid>
        )
    },
});

const PipelineOutputDialog: React.FC<{ pipelineOutputId: number | undefined, onClose: () => void }> = ({ pipelineOutputId, onClose }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [pipelineDetails, setPipelineDetails] = useState<PipelineDetailDto>()

    useEffect(() => {
        if (pipelineOutputId) {
            setLoading(true)
            axios.get<PipelineDetailDto>(api.pipelineById(pipelineOutputId)).then(response => {
                setPipelineDetails(response.data)
                setLoading(false)
            }).catchError(e => {
                enqueueSnackbar(e.Description, { variant: 'error' })
            })
        } else {
            setPipelineDetails(undefined);
        }
    }, [pipelineOutputId, enqueueSnackbar])

    return <ProgressDialog
        open
        variant="fullPage"
        closeButtonProps={
            {
                style: {
                    color: "#000",
                    backgroundColor: "#e0e0e0",
                    textTransform: "none",
                    borderColor: "#000"
                },
                size: "small",
                startIcon: <Close />
            }
        }
        onClose={() => { setPipelineDetails(undefined); onClose() }}
        title="Output"
    >
        <Box
            p="10px"
            flex={1}
            sx={
                {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: loading ? 'center' : 'flex-start',
                    height: '100%'
                }
            }
        >
            {
                loading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, width: '100%' }}>
                    <CircularProgress />
                </div>
            }
            {pipelineDetails && <pre>{pipelineDetails.Output ?? "No content"}</pre>}
        </Box>
    </ProgressDialog>
}

const PipelineDifferenceDialog: React.FC<{ pipelineDifferenceId: number | undefined, onClose: () => void }> = ({ pipelineDifferenceId, onClose }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [pipelineDetails, setPipelineDetails] = useState<PipelineChangeLists>()

    useEffect(() => {
        if (pipelineDifferenceId) {
            setLoading(true)
            axios.get<PipelineChangeLists>(api.getPipelineDifferenceById(pipelineDifferenceId)).then(response => {
                setPipelineDetails(response.data)
                setLoading(false)
            }).catchError(e => {
                enqueueSnackbar(e.Description, { variant: 'error' })
            })
        } else {
            setPipelineDetails(undefined);
        }
    }, [pipelineDifferenceId, enqueueSnackbar])

    return <ProgressDialog
        open
        variant="fullPage"
        closeButtonProps={
            {
                style: {
                    color: "#000",
                    backgroundColor: "#e0e0e0",
                    textTransform: "none",
                    borderColor: "#000"
                },
                size: "small",
                startIcon: <Close />
            }
        }
        onClose={() => { setPipelineDetails(undefined); onClose() }}
        title="Difference"
    >
        <Box
            p="10px"
            flex={1}
            sx={
                {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: loading ? 'center' : 'flex-start',
                    height: '100%'
                }
            }
        >
            {
                loading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, width: '100%' }}>
                    <CircularProgress />
                </div>
            }

            {
                (pipelineDetails && pipelineDetails.ConfigChanges.at(0)) &&
                <pre>
                    Configuration:
                    {"\n\n"}
                    {
                        pipelineDetails?.ConfigChanges.map((pipelineLineChange, index) => {
                            const bgColor = pipelineLineChange.Change === PipelineChange.Removed ? 'rgb(255, 235, 233)' : pipelineLineChange.Change === PipelineChange.Added ? 'rgb(218, 251, 225)' : 'transparent';
                            return (
                                <div key={index} style={{ backgroundColor: bgColor }}>
                                    {pipelineLineChange.Line}

                                </div>
                            );
                        })
                    }
                    {"\n\n"}
                </pre>
            }

            {
                (pipelineDetails && pipelineDetails.GlobalVersionsChanges.at(0)) &&
                <pre>
                    Global Version:
                    {"\n\n"}
                    {
                        pipelineDetails?.GlobalVersionsChanges.map((line, index) => {
                            const bgColor = line.Change === PipelineChange.Removed ? 'rgb(255, 235, 233)' : line.Change === PipelineChange.Added ? 'rgb(218, 251, 225)' : 'transparent';
                            return (
                                <div key={index} style={{ backgroundColor: bgColor }}>
                                    {line.Line}
                                </div>
                            );
                        })
                    }
                </pre>

            }

            {
                (pipelineDetails && !pipelineDetails.ConfigChanges.at(0) && !pipelineDetails.GlobalVersionsChanges.at(0)) &&
                <pre>
                    {"No difference"}
                </pre>
            }
        </Box>
    </ProgressDialog>
}

const ConfigPreview: React.FC<{ configName: string, buttonTitle: string }> = ({ configName, buttonTitle }) => {
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [config, setConfig] = useState<string>()
    const [originalConfig, setOriginalConfig] = useState<string>()
    const { enqueueSnackbar } = useSnackbar();

    const hasConfigAdminPermission = AuthenticationUtils.hasConfigAdminPermission();

    const handleOpen = useCallback(() => {
        setOpen(true)
        setConfig(undefined)
        setOriginalConfig(undefined)
        setLoading(true)
        axios.get<DtoConfigContent>(api.configurationByName(configName)).then(response => {
            const config = JSON.parse(response.data.Config);
            setConfig(JSON.stringify(config, null, 2))
            setOriginalConfig(response.data.Config)
            setLoading(false)
        }).catchError(e => {
            enqueueSnackbar(e.Description, { variant: 'error' })
            setConfig(undefined)
            setOriginalConfig(undefined)
            setOpen(false)
        })
    }, [configName, enqueueSnackbar])

    if (!hasConfigAdminPermission) {
        return null;
    }

    const handleReload = () => {
        setLoading(true)
        axios.get<DtoConfigContent>(api.configurationByName(configName)).then(response => {
            const config = JSON.parse(response.data.Config);
            setConfig(JSON.stringify(config, null, 2))
            setOriginalConfig(response.data.Config)
        }).catchError(e => {
            enqueueSnackbar(e.Description, { variant: 'error' })
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleSave = () => {
        if (!originalConfig || !config) { return }

        setLoading(true)

        const payload: DtoConfigUpdateContent = {
            OldConfig: originalConfig,
            NewConfig: config
        }

        axios.put(api.configurationByName(configName), payload).then(response => {
            enqueueSnackbar('Config updated successfully', { variant: 'success' })
            setLoading(false)
            setConfig(undefined)
            setOriginalConfig(undefined)
            setOpen(false)
        }).catchError(e => {
            enqueueSnackbar(e.Description, { variant: 'error' })
            setLoading(false)
        })
    }

    const handleFormat = () => {
        if (!config) { return }

        try {
            const value = JSON.parse(config)
            setConfig(JSON.stringify(value, null, 2));
        } catch (e) {
            Logger.debug("JSON is invalid and cannot be beautified", e)
            enqueueSnackbar('JSON is invalid', { variant: 'error' })
        }
    }

    return <>
        <Button
            variant="outlined"
            onClick={handleOpen}
        >
            {buttonTitle}
        </Button>

        {
            open && <ProgressDialog
                open
                variant="fullPage"
                onClose={() => { setConfig(undefined); setOpen(false); }}
                title={configName}
                actions={
                    <>
                        <LoadingButton loading={loading} onClick={handleFormat}>Format</LoadingButton>
                        <LoadingButton loading={loading} onClick={handleReload}>Reload</LoadingButton>
                        <LoadingButton loading={loading} variant='contained' onClick={handleSave}>Save</LoadingButton>
                    </>
                }
            >
                <Box p="10px" flex={1} sx={{ display: 'flex', flex: 1, height: '100%' }}>
                    {
                        loading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                            <CircularProgress />
                        </div>
                    }
                    {
                        (!loading) && (
                            <AceEditor
                                id="edit"
                                style={{ border: "1px solid lightgray", borderRadius: "5px", height: '100%' }}
                                width="100%"
                                editorProps={{ $blockScrolling: true }}
                                placeholder=""
                                mode="json"
                                theme="tomorrow"
                                minLines={15}
                                fontSize={18}
                                showGutter
                                commands={Beautify.commands}
                                onChange={
                                    (value: string) => {
                                        setConfig(value)
                                    }
                                }
                                highlightActiveLine
                                value={config}
                                setOptions={
                                    {
                                        enableBasicAutocompletion: false,
                                        enableLiveAutocompletion: false,
                                        enableSnippets: false,
                                        showLineNumbers: true,
                                        showPrintMargin: false,
                                        tabSize: 2,
                                        wrap: true,
                                        useWorker: false,
                                    }
                                }
                            />
                        )
                    }
                </Box>
            </ProgressDialog>
        }

    </>
}

const SyncZabbixStateButton: React.FC = () => {
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar();

    const hasConfigAdminPermission = AuthenticationUtils.hasConfigAdminPermission();

    const handleOpen = useCallback(() => {
        setLoading(true)
        axios.put(api.syncZabbixState).then(() => {
            enqueueSnackbar('Success', { variant: 'success' })
        }).catchError(e => {
            enqueueSnackbar(e.Description, { variant: 'error' })
        }).finally(() => {
            setLoading(false)
        })
    }, [enqueueSnackbar])

    if (!hasConfigAdminPermission) {
        return null;
    }

    return <LoadingButton
        variant="outlined"
        onClick={handleOpen}
        loading={loading}
        disabled={loading}
    >
        Sync Zabbix state
    </LoadingButton>

}