import { Matrix3 } from "./matrix3";
import { Vector3 } from "./vector3";

export interface Matrix4 {
    m11: number, m12: number, m13: number, m14: number,
    m21: number, m22: number, m23: number, m24: number,
    m31: number, m32: number, m33: number, m34: number,
    m41: number, m42: number, m43: number, m44: number,
}

/**
 * Matrix4 functions.
 * Taken from R5dLib.Calc, if any functions you need are missing, check the R5dLib.Calc code for reference.
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Matrix4 {
    export const fromValues = (m11: number, m12: number, m13: number, m14: number,
        m21: number, m22: number, m23: number, m24: number,
        m31: number, m32: number, m33: number, m34: number,
        m41: number, m42: number, m43: number, m44: number): Readonly<Matrix4> => {
        return Object.freeze({
            m11, m12, m13, m14,
            m21, m22, m23, m24,
            m31, m32, m33, m34,
            m41, m42, m43, m44,
        })
    }

    export const identity = Matrix4.fromValues(
        1, 0, 0, 0,
        0, 1, 0, 0,
        0, 0, 1, 0,
        0, 0, 0, 1);

    export const fromRowMajorArray = (v: number[]) => {
        return Matrix4.fromValues(
            v[0], v[1], v[2], v[3],
            v[4], v[5], v[6], v[7],
            v[8], v[9], v[10], v[11],
            v[12], v[13], v[14], v[15],
        )
    }

    export const toRowMajorArray = (m: Matrix4) => {
        return [
            m.m11, m.m12, m.m13, m.m14,
            m.m21, m.m22, m.m23, m.m24,
            m.m31, m.m32, m.m33, m.m34,
            m.m41, m.m42, m.m43, m.m44]
    }

    export const fromRotationAndTranslation = (r: Matrix3, t: Vector3) => {
        return Matrix4.fromValues(
            r.m11, r.m12, r.m13, t.x,
            r.m21, r.m22, r.m23, t.y,
            r.m31, r.m32, r.m33, t.z,
            0, 0, 0, 1,
        )
    }

    export const translation = (t: Vector3) => {
        return Matrix4.fromValues(
            1, 0, 0, t.x,
            0, 1, 0, t.y,
            0, 0, 1, t.z,
            0, 0, 0, 1,
        )
    }

    export const rotation = (axis: Vector3, angleRAD: number) => {
        const rotation = Matrix3.fromAxisAngle(axis, -angleRAD)
        return Matrix4.fromRotationAndTranslation(rotation, Vector3.zero)
    }

    export const rotationX = (angleRAD: number) =>
        Matrix4.rotation(Vector3.fromValues(1, 0, 0), angleRAD)

    export const rotationY = (angleRAD: number) =>
        Matrix4.rotation(Vector3.fromValues(0, 1, 0), angleRAD)

    export const rotationZ = (angleRAD: number) =>
        Matrix4.rotation(Vector3.fromValues(0, 0, 1), angleRAD)

    const _multiplyMatrixInternal = (a: Matrix4, b: Matrix4) => {
        return Matrix4.fromValues(
            a.m11 * b.m11 + a.m12 * b.m21 + a.m13 * b.m31 + a.m14 * b.m41, a.m11 * b.m12 + a.m12 * b.m22 + a.m13 * b.m32 + a.m14 * b.m42, a.m11 * b.m13 + a.m12 * b.m23 + a.m13 * b.m33 + a.m14 * b.m43, a.m11 * b.m14 + a.m12 * b.m24 + a.m13 * b.m34 + a.m14 * b.m44,
            a.m21 * b.m11 + a.m22 * b.m21 + a.m23 * b.m31 + a.m24 * b.m41, a.m21 * b.m12 + a.m22 * b.m22 + a.m23 * b.m32 + a.m24 * b.m42, a.m21 * b.m13 + a.m22 * b.m23 + a.m23 * b.m33 + a.m24 * b.m43, a.m21 * b.m14 + a.m22 * b.m24 + a.m23 * b.m34 + a.m24 * b.m44,
            a.m31 * b.m11 + a.m32 * b.m21 + a.m33 * b.m31 + a.m34 * b.m41, a.m31 * b.m12 + a.m32 * b.m22 + a.m33 * b.m32 + a.m34 * b.m42, a.m31 * b.m13 + a.m32 * b.m23 + a.m33 * b.m33 + a.m34 * b.m43, a.m31 * b.m14 + a.m32 * b.m24 + a.m33 * b.m34 + a.m34 * b.m44,
            a.m41 * b.m11 + a.m42 * b.m21 + a.m43 * b.m31 + a.m44 * b.m41, a.m41 * b.m12 + a.m42 * b.m22 + a.m43 * b.m32 + a.m44 * b.m42, a.m41 * b.m13 + a.m42 * b.m23 + a.m43 * b.m33 + a.m44 * b.m43, a.m41 * b.m14 + a.m42 * b.m24 + a.m43 * b.m34 + a.m44 * b.m44)
    }

    /** Multiply matrices in right to left order. */
    export const multiply = (...matrices: Matrix4[]): Matrix4 => {
        if (matrices.length === 0)
            return Matrix4.identity

        let m = matrices[matrices.length - 1]
        for (let i = matrices.length - 2; i >= 0; i--) {
            m = _multiplyMatrixInternal(matrices[i], m)
        }

        return m;
    }

    export const applyToPoint = (m: Matrix4, pt: Vector3) => {
        return Vector3.fromValues(
            m.m11 * pt.x + m.m12 * pt.y + m.m13 * pt.z + m.m14,
            m.m21 * pt.x + m.m22 * pt.y + m.m23 * pt.z + m.m24,
            m.m31 * pt.x + m.m32 * pt.y + m.m33 * pt.z + m.m34,
        )
    }

    export const getTranslation = (m: Matrix4) => {
        return Vector3.fromValues(m.m14, m.m24, m.m34)
    }

    export const getRotationMatrix = (m: Matrix4) => {
        return Matrix3.fromValues(
            m.m11, m.m12, m.m13,
            m.m21, m.m22, m.m23,
            m.m31, m.m32, m.m33,
        )
    }

    export const transpose = (m: Matrix4) => {
        return Matrix4.fromValues(
            m.m11, m.m21, m.m31, m.m41,
            m.m12, m.m22, m.m32, m.m42,
            m.m13, m.m23, m.m33, m.m43,
            m.m14, m.m24, m.m34, m.m44);
    }

    export const decompose = (m: Matrix4) => {
        const scale = Vector3.fromValues(
            Vector3.length(Vector3.fromValues(m.m11, m.m12, m.m13)),
            Vector3.length(Vector3.fromValues(m.m21, m.m22, m.m23)),
            Vector3.length(Vector3.fromValues(m.m31, m.m32, m.m33)),
        )
        const isx = 1 / scale.x
        const isy = 1 / scale.y
        const isz = 1 / scale.z

        const rotation = Matrix3.fromValues(
            m.m11 * isx, m.m12 * isx, m.m13 * isx,
            m.m21 * isy, m.m22 * isy, m.m23 * isy,
            m.m31 * isz, m.m32 * isz, m.m33 * isz,
        )
        const translation = Vector3.fromValues(m.m14 / m.m44, m.m24 / m.m44, m.m34 / m.m44)

        return { scale, rotation, translation };
    }
}
