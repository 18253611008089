import { Alert, AlertTitle, InputLabel, TextField } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { api } from '../definitions';
import { WarningAmber } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

type CustomerDomainProps = {
    fieldKey: string
    fieldPlaceholder: string | undefined
    fieldLabel: string
    index: number
    values: Record<string, string | number | boolean | string[]>
    onChange: (key: string, newValue: string | number | boolean | string[]) => void
}

const AddCustomerDomain = (props: CustomerDomainProps) => {

    const [similarName, setSimilarName] = useState<string[] | undefined>()
    const [loadingNameCheck, setLoadingNameCheck] = useState<boolean>(false)

    const namesCheck = useCallback((name: string, controller: AbortController) => {

        name = name.toLowerCase()
        if (name === '') {
            setSimilarName(undefined)
            setLoadingNameCheck(false)
        } else {
            axios.get<string[]>(api.progressAIRequest(name), { signal: controller.signal })
                .then((response) => {
                    if (response.data.length) {
                        setSimilarName(response.data)
                    } else {
                        setSimilarName(undefined)
                    }
                })
                .finally(() =>
                    setLoadingNameCheck(false)
                )
        }

    }, [])

    const [tempVal, setTempVal] = useState<string>("")

    const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, propKey: string) => {
        setLoadingNameCheck(true)
        const { value } = e.target
        props.onChange(propKey, value)
        setTempVal(value)
    }, [props])

    useEffect(() => {
        const abortController = new AbortController()
        const debounceTimer = setTimeout(() => {
            namesCheck(tempVal, abortController)
        }, 500)
        return () => {
            abortController.abort()
            clearTimeout(debounceTimer)
        }
    }, [namesCheck, tempVal])


    return (
        <>
            <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                <InputLabel htmlFor={`${props.fieldKey}-${props.index}`} style={{ width: 225, textAlign: 'right' }}>{props.fieldLabel}</InputLabel>
                <TextField
                    id={`${props.fieldKey}-${props.index}`}
                    fullWidth
                    autoFocus={props.index === 0}
                    autoComplete="off"
                    placeholder={props.fieldPlaceholder}
                    value={props.values[props.fieldKey] ?? ''}
                    onChange={(e) => handleInputChange(e, props.fieldKey)}
                    variant="outlined"
                    size="small"
                />
            </div>

            <div>
                <Alert
                    severity="warning"
                    sx={{ marginBottom: '10px', bgcolor: 'rgb(255, 244, 229)' }}
                    style={{ alignItems: 'center', padding: '10px' }}
                    variant='outlined'
                    icon={
                        <div style={{ width: '50px', textAlign: 'center', verticalAlign: 'center', marginRight: '18px' }}>
                            {!loadingNameCheck ? <WarningAmber /> : <LoadingButton loading style={{ marginRight: '40px' }} />}
                        </div>
                    }
                >
                    <AlertTitle>Avoid duplicate customer domains!!!</AlertTitle>
                    Before adding a new customer domain, check if it already exists.
                    <br />
                    For example, progress-ag and progressag are the same.
                    <br />
                    Avoid creating customers twice.
                    <br />
                    If you are unsure, ask someone who has been with Progress for a while and knows the customers.
                    <br />
                    <a
                        href="https://progress-group.atlassian.net/wiki/spaces/PROGRESSGROUP/pages/2772205569/Set+up+a+new+CustomerPortal#1.-Create-a-customer"
                        target='_blank'
                        rel="noreferrer"
                    >
                        See guidelines for customer naming.
                    </a>
                    {
                        similarName?.length && (
                            <>
                                <br />
                                <Alert
                                    severity="warning"
                                    sx={{ my: '10px', bgcolor: 'rgb(255, 244, 229)' }}
                                    variant='outlined'
                                    icon=' '

                                >
                                    <AlertTitle>The following customers already exist:</AlertTitle>
                                    <ul>
                                        {
                                            similarName.map((name, index) => (
                                                <li key={index}>
                                                    {name}
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </Alert>
                            </>
                        )
                    }
                </Alert>
            </div>
        </>
    )
}

export default AddCustomerDomain