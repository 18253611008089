import { LogoComponentProps, replaceHtmlPlaceholders } from "./LogoUtils";
import html from "./customerportal.html?raw";

const CustomerPortalLogo = ({ fontSizeInPx }: LogoComponentProps) => {
    const logo = replaceHtmlPlaceholders(html, fontSizeInPx);
    return <div dangerouslySetInnerHTML={{ __html: logo }}></div>
}

export default CustomerPortalLogo;

