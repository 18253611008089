import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, CardHeader, Divider, Typography } from "@mui/material";
import { format, parseISO } from 'date-fns';
import { useTypedTranslation } from "../definitions/languages";
import { DeploymentInformation } from "../definitions/autogenerated/types";
import InfoRow from "./InfoRow";
import UptimeIndicator from "./UptimeIndicator";

export const DeploymentInformationVisualizer = (props: { info: DeploymentInformation, lastFetchTS: number }) => {
    const { info, lastFetchTS } = props;
    const { t } = useTypedTranslation();

    return <>
        <Card style={{ margin: '10px 0' }}>
            <CardHeader title={t('about', 'sectionApplication')} style={{ paddingBottom: 10 }} />
            <Divider />
            <CardContent>
                <InfoRow title={t('about', 'applicationTitle')} value={info.Application.Title} />
                <InfoRow title={t('about', 'applicationVersion')} value={info.Application.Version} />
                <InfoRow title={t('about', 'applicationBuildDate')} value={format(parseISO(info.Application.BuildDate), 'yyyy-MM-dd HH:mm:ss')} />
                <InfoRow title={t('about', 'applicationCopyright')} value={info.Application.Copyright} />
                <InfoRow title={t('about', 'applicationUptime')} value={<UptimeIndicator lastFetchTS={lastFetchTS} remoteNowTS={info.Application.Uptime.RemoteNowTS} remoteStartTS={info.Application.Uptime.RemoteStartTS} />} />
                <InfoRow title={t('about', 'applicationRAMUsed')} value={Math.ceil(info.Application.RAMUsageBytes / 1024 / 1024)} />
            </CardContent>
        </Card>
        <Card style={{ margin: '10px 0' }}>
            <CardHeader title={t('about', 'sectionCI')} style={{ paddingBottom: 10 }} />
            <Divider />
            <CardContent>
                <InfoRow title={t('about', 'ciHash')} value={info.CIInfo.Hash} />
                <InfoRow title={t('about', 'ciSHA1')} value={<a target="_blank" rel="noreferrer" href={`https://github.com/ProgressGroupDev/Projekte/commit/${info.CIInfo.SHA1}`}>{info.CIInfo.SHA1}</a>} />
                <InfoRow title={t('about', 'ciPipelineId')} value={info.CIInfo.PipelineID} />
                <InfoRow title={t('about', 'ciCommitTag')} value={info.CIInfo.CommitTag} />
                <InfoRow title={t('about', 'ciCommitDate')} value={format(parseISO(info.CIInfo.CommitDate), 'yyyy-MM-dd HH:mm:ss')} />
                <InfoRow title={t('about', 'ciBranch')} value={info.CIInfo.Branch} />
                <InfoRow title={t('about', 'ciBuildDate')} value={format(parseISO(info.CIInfo.BuildDate), 'yyyy-MM-dd HH:mm:ss')} />
                <InfoRow title={t('about', 'ciBuildServer')} value={info.CIInfo.BuildServer} />
            </CardContent>
        </Card>
        <Card style={{ margin: '10px 0' }}>
            <CardHeader title={t('about', 'sectionEnvironment')} style={{ paddingBottom: 10 }} />
            <Divider />
            <CardContent>
                <InfoRow title={t('about', 'environmentVersion')} value={info.Environment.Version} />
                <InfoRow title={t('about', 'environmentIsOS64Bit')} value={info.Environment.IsOS64Bit} />
                <InfoRow title={t('about', 'environmentIsProcessor64Bit')} value={info.Environment.IsProcessor64Bit} />
                <InfoRow title={t('about', 'environmentMachine')} value={info.Environment.Machine} />
                <InfoRow title={t('about', 'environmentUser')} value={info.Environment.User} />
                <InfoRow title={t('about', 'environmentUptime')} value={<UptimeIndicator lastFetchTS={lastFetchTS} remoteNowTS={info.Environment.Uptime.RemoteNowTS} remoteStartTS={info.Environment.Uptime.RemoteStartTS} />} />
                <InfoRow title={t('about', 'environmentTotalRAM')} value={info.Environment.TotalRAM} />
                <InfoRow title={t('about', 'environmentFreeRAM')} value={info.Environment.FreeRAM} />
                <InfoRow title={t('about', 'environmentUsedRAM')} value={info.Environment.UsedRAM} />
            </CardContent>
        </Card>

        <Accordion TransitionProps={{ timeout: 500 }} style={{ margin: '10px 0' }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="h5" component="span">{t('about', 'sectionDirectAssemblies')}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ width: '100%', flexDirection: 'column' }}>
                {info.DirectAssemblies.map((assembly, index) => <InfoRow key={index} title={assembly.Title} value={assembly.Version} />)}
            </AccordionDetails>
        </Accordion>

        <Accordion TransitionProps={{ timeout: 500 }} style={{ margin: '10px 0' }} >
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="h5" component="span">{t('about', 'sectionAllAssemblies')}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ width: '100%', flexDirection: 'column' }}>
                {info.AllAssemblies.map((assembly, index) => <InfoRow key={index} title={assembly.Title} value={assembly.Version} />)}
            </AccordionDetails>
        </Accordion>
    </>
}