import { AppBar, Toolbar } from "@mui/material"
import React from "react";
import { ColorScheme } from "./colors";

type ProgressAppBarProps = {
  colors: ColorScheme
}

export const ProgressAppBar: React.FC<ProgressAppBarProps> = (props) => {
    return <AppBar position="fixed" sx={{ zIndex: theme => theme.zIndex.drawer + 2 }} >
        <Toolbar sx={{ padding: "0px !important", backgroundColor: props.colors.background }}>
            {props.children}
        </Toolbar>
    </AppBar>
}