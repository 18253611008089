import React from 'react';

/**
 * Executes the given function exactly once, on component mount.
 *
 * @param {React.EffectCallback} f - The function to be executed.
 * @return {void} This function does not return a value.
 */
export function useOnMount(func: React.EffectCallback) { // the parameter function is called exactly once - on component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(func, []);
}