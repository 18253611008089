import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import Layout from './components/Layout';
import './custom.scss';
import { routes } from './definitions';
import About from './pages/AboutPage';
import ApplicationInsights from './pages/applicationInsights/ApplicationInsights';
import ConfigSheet from './pages/configSheetEditor/ConfigSheet';
import CustomerList from './pages/CustomerList/CustomerList';
import GithubList from './pages/GithubList';
import PipelineOverview from './pages/PipelineOverview';
import ProgressAppsList from './pages/ProgressAppsList';
import ProgressBinUpdater from './pages/ProgressBinUpdater';
import Tools from './pages/Tools';

const App: React.FC = () => {
    return (
        <Layout>
            <Switch>
                <Route exact path={routes.customerList} component={CustomerList} />
                <Route exact path={routes.progressApps} component={ProgressAppsList} />
                <Route exact path={routes.progressBinUpdater} component={ProgressBinUpdater} />
                <Route exact path={`${routes.applicationInsights}/:type?/:customer?`} component={ApplicationInsights} />
                <Route exact path={routes.configSheet} component={ConfigSheet} />
                <Route exact path={routes.about} component={About} />
                <Route exact path={routes.githubList} component={GithubList} />
                <Route exact path={routes.pipelines} component={PipelineOverview} />
                <Route exact path={routes.tools} component={Tools} />

                <Redirect exact from="/" to={routes.customerList} />

                <Route render={() => <Redirect to={routes.customerList} />} />
            </Switch>
        </Layout>
    );
}

export default App;
