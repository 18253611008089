import ScreenLoading from '@/components/ScreenLoading';
import { ProgressAdmin } from '@/definitions/autogenerated/types';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, CircularProgress, InputLabel, LinearProgress, TextField } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { ProgressDialog, useOnMount } from '@progress/base-ui';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useState, useCallback } from 'react';
import { api } from '../../definitions';

type ProgressAdminDialogProps = {
    onClose: () => void,
}

export const ProgressAdminDialog: React.FC<ProgressAdminDialogProps> = (props) => {
    const [dataLoading, setDataLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState("");
    const [progressAdmins, setProgressAdmins] = useState<ProgressAdmin[]>()
    const { enqueueSnackbar } = useSnackbar();

    const loadProgressAdmins = useCallback(() => {
        setDataLoading(true)
        axios.get<ProgressAdmin[]>(api.progressAdmins).then(result => {
            setProgressAdmins(result.data);
        }).catchError(e => {
            enqueueSnackbar('Unable to load Progress Admins: ' + e.Description, { variant: 'error' })
        }).finally(() => {
            setDataLoading(false)
        })
    }, [enqueueSnackbar])

    useOnMount(loadProgressAdmins)

    const handleAdd = useCallback(() => {
        if (!value)
            return;

        setLoading(true)
        axios.post<ProgressAdmin[]>(api.progressAdmins, {
            Email: value,
            UserId: "",
        }).then(result => {
            setValue("");
            setProgressAdmins(result.data);
        }).catchError(e => {
            enqueueSnackbar('Unable to add Progress Admins: ' + e.Description, { variant: 'error' })
        }).finally(() => {
            //
            setLoading(false)
        })
    }, [enqueueSnackbar, value])

    const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.code === 'Enter' || e.code === "NumpadEnter") {
            e.stopPropagation();
            e.preventDefault();
            handleAdd();
        }
    }, [handleAdd])

    const handleDelete = useCallback((progressAdmin: ProgressAdmin) => {
        setLoading(true)

        axios.delete<ProgressAdmin[]>(api.progressAdminById(progressAdmin.UserId)).then(result => {
            setValue("");
            setProgressAdmins(result.data);
        }).catchError(e => {
            enqueueSnackbar('Unable to delete Progress Admins: ' + e.Description, { variant: 'error' })
        }).finally(() => {
            //
            setLoading(false)
        })
    }, [enqueueSnackbar])

    return <ProgressDialog
        open
        onClose={props.onClose}
        title="Progress Admins"
        maxWidth="sm"
        fullWidth
        actions={
            <>
                <Button variant="outlined" onClick={loadProgressAdmins}>Refresh</Button>
                <Button variant="contained" onClick={props.onClose}>Close</Button>
            </>
        }
    >

        {
            progressAdmins === undefined && <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress size={75} />
            </div>
        }
        {
            progressAdmins && <>
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', marginBottom: '10px', justifyContent: 'center' }}>
                    <InputLabel htmlFor="customer" style={{ width: 225, textAlign: 'right' }}>E-Mail</InputLabel>
                    <TextField
                        size="small"
                        id="customer"
                        fullWidth
                        InputProps={{ style: { margin: 0 } }}
                        value={value}
                        autoComplete='off'
                        onChange={(e) => setValue(e.target.value)}
                        onKeyDown={e => handleKeyDown(e)}
                    />
                    <Button onClick={handleAdd} variant="contained">
                        <AddIcon />
                    </Button>
                </div>

                <LinearProgress sx={{ opacity: dataLoading ? 1 : 0 }} />
                <div style={{ display: 'flex', overflow: 'hidden', flex: 1, flexDirection: 'column' }}>
                    {progressAdmins.length}
                    {' '}
Items
                    <div style={{ display: 'flex', overflowY: 'auto', flex: 1 }}>
                        <List dense sx={{ flex: 1 }}>
                            {
                                progressAdmins.map(progressAdmin => {
                                    return <ListItem
                                        key={progressAdmin.UserId}
                                        secondaryAction={
                                            <IconButton edge="end" onClick={() => handleDelete(progressAdmin)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar {...stringAvatar(progressAdmin.Email)} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={progressAdmin.Email}
                                            secondary={progressAdmin.UserId}
                                        />
                                    </ListItem>
                                })
                            }
                        </List>
                    </div>
                </div>
            </>
        }

        {loading && <ScreenLoading />}
    </ProgressDialog>
}

const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

const stringAvatar = (name: string) => {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split('@')[0][0]}`,
    };
}