import { useReduxDispatch } from '@/store/configureStore';
import { fetchCustomers } from '@/store/data/customers/actions';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { Logger, ProgressDialog } from '@progress/base-ui';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { api } from '../definitions';
import { IConfigAssemblyMapping } from '../definitions/autogenerated/assemblyMappings';
import { fetchDeployments } from '../store/data/deployments/actions';
import { ApplicationFields } from './ApplicationFields';
import ScreenLoading from './ScreenLoading';
import { CreateApplicationRequest, IConfig } from '../definitions/autogenerated/types';

type AddApplicationProps = {
    title: string
    onClose: () => void,
    applicationType: keyof typeof IConfigAssemblyMapping | "Customer"
}

export const AddApplication: React.FC<AddApplicationProps> = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useReduxDispatch();

    const [data, setData] = useState<Record<string, string | number | boolean | string[]>>({})
    const [loading, setLoading] = useState(false)

    const handleSubmit = () => {

        let applicationType = props.applicationType

        // applicationType = 'Customer' is only needed to show the correct ApplicationFields but is is not a valid ApplicationType
        if (props.applicationType === "Customer") {
            applicationType = "AdminCenterYC"
        }

        const assembly = IConfigAssemblyMapping[applicationType as keyof typeof IConfigAssemblyMapping]

        if (!assembly) {
            const message = "[AddApplication] ApplicationType is not valid! ApplicationType=" + applicationType
            enqueueSnackbar(message, { variant: "error" })
            Logger.error(message)
            return;
        }

        setLoading(true)

        const requestObject = {
            $type: assembly,
            ...data,
        }

        const request: CreateApplicationRequest = {
            Requests: [
                requestObject as unknown as IConfig
            ]
        };

        if (props.applicationType === "Customer" && data["CreateCustomerPortal"] === true) {

            const customerPortal = {
                $type: IConfigAssemblyMapping["CustomerPortal"],
                Domain: data["Domain"],
                ClusterId: data["ClusterId"]
            }
            request.Requests.push(customerPortal as unknown as IConfig)
        }

        axios.post(api.configurationAddConfiguration, request).then(() => {
            setData({});
            dispatch(fetchCustomers());
            dispatch(fetchDeployments());
            enqueueSnackbar('Success', { variant: 'success' })
            props.onClose();
        }).catchError((e) => {
            enqueueSnackbar(e.Description, { variant: 'error', autoHideDuration: 3000 })
            if (e.ValidationErrors) {
                Object.values(e.ValidationErrors).forEach(errors => {
                    errors?.forEach(error => {
                        enqueueSnackbar(error.Message, { variant: 'error', autoHideDuration: 3000, })
                    })
                })
            }
            setLoading(false)
        })
    }

    const handleCancel = useCallback(() => {
        props.onClose();
    }, [props])

    const handleFieldChange = useCallback((key: string, value: string | number | boolean | string[]) => {
        setData(oldValue => ({ ...oldValue, [key]: value }))
    }, [])

    return <ProgressDialog
        open
        onClose={props.onClose}
        title={props.title}
        maxWidth="md"
        fullWidth
        actions={
            <>
                <Button variant="text" onClick={handleCancel} disabled={loading}>Cancel</Button>
                <LoadingButton variant="contained" onClick={handleSubmit} loading={loading}>Create</LoadingButton>
            </>
        }
    >
        <ApplicationFields
            operation="create"
            applicationType={props.applicationType}
            values={data}
            onChange={handleFieldChange}
        />
        {loading && <ScreenLoading />}
    </ProgressDialog >
}