
/**
 * Checks if the given input is a valid number.
 * @param s The input to be checked, typed as unknown.
 * @returns boolean indicating whether the input is a valid number.
 */
export function isValidNumber(s: unknown): boolean {
    if (typeof s === 'string' || typeof s === 'number') {
        return !isNaN(parseFloat(s.toString())) && isFinite(parseFloat(s.toString()))
    }
    return false
}

/**
 * Checks if the given number is out of int or double range. Assumes it is INT when decimals is 0, otherwise it is DOUBLE.
 *
 * @param {number} number - The number to check.
 * @param {number | undefined} decimals - The number of decimals (optional).
 * @return {boolean} True if the number is out of range, false otherwise.
 */
export function numberOutOfRange(number: number, decimals: number | undefined): boolean {
    return (decimals === 0)
        ? number > 2147483647 || number < -2147483648 //C# int range https://learn.microsoft.com/en-us/dotnet/csharp/language-reference/builtin-types/integral-numeric-types
        : number > Number.MAX_VALUE || number < -Number.MAX_VALUE //C# / JS double range
}

/**
 * Clamps a number within the inclusive range specified by the min and max values.
 * @param value - The number to be clamped
 * @param min - The minimum value to clamp to
 * @param max - The maximum value to clamp to
 * @returns The clamped value within the range [min, max]
 */
export function clamp(value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max) // Clamps value between min and max
}

/**
 * Rounds a number to the specified number of decimal places
 * @param value - The number to be rounded
 * @param decimals - The number of decimal places to round to
 * @returns The rounded number
 */
export function roundTo(value: number, decimals: number): number {
    const factor = Math.pow(10, decimals) // calculate the factor
    return Math.round(value * factor) / factor // round the value and divide by the factor
}