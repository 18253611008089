import { routes, useTypedTranslation } from "@/definitions";
import { getImage } from "@/helpers";
import { apiKeyAdapter, useGetApiKeysQuery } from "@/store/data/applicationInsights/actions";
import { FilterAlt } from "@mui/icons-material";
import { Button, Container, Grid, ListItem, ListItemIcon, ListItemText, Paper, styled, Typography } from "@mui/material";
import { DataGridPremium as DataGrid, GridColDef, GridRenderCellParams, GridRowParams, GridSlotsComponent } from "@mui/x-data-grid-premium";
import { GridInitialStatePremium as GridInitialState } from "@mui/x-data-grid-premium/models/gridStatePremium";
import { nameof } from '@progress/base-ui';
import { getMuiDataGridTranslations } from "@progress/base-ui/src/definitions/languages";
import React, { useMemo } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { CustomGridToolbar, defaultGridProps, limitedColumnProps, minWidthColumnProps } from "../../components/CustomGridComponents";
import { APIKey } from "../../definitions/autogenerated/types";
import ApplicationInsightsData from "./ApplicationInsightsData";

const StyledContainer = styled(Container)(({ theme }) => ({
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: "2100px !important"
}));

const rootStyle: React.CSSProperties = {
    textAlign: "center"
}

const divStyle: React.CSSProperties = {
    width: "100%",
}

const ApplicationInsights = () => {
    const params = useParams<{ type?: string, customer?: string }>()

    const category = params.type ? ` - ${params.type}` : ""

    return <StyledContainer maxWidth="lg" className="container">
        <div className='container' style={rootStyle}>
            <div className='container' style={divStyle}>
                <Grid container justifyContent='space-between' alignItems='center'>
                    {/* left */}
                    <Grid item xs={8} container >
                        <Grid item m={1}>
                            <h1 className="containerTitle">
                                {`Application Insights${category}`}
                            </h1>
                        </Grid>
                        {
                            params.customer && <Grid item>
                                <ListItem sx={{ margin: "0.67em 0 0.67em 0", fontSize: "2em" }} >
                                    <ListItemIcon >
                                        <FilterAlt />
                                    </ListItemIcon>
                                    <ListItemText >
                                        <Typography variant="h6">{params.customer}</Typography>
                                    </ListItemText>
                                </ListItem>
                            </Grid>
                        }
                    </Grid>
                    {/* right */}
                    <Grid item justifyContent='flex-end' xs={4}>
                        <Button
                            variant="outlined"
                            style={{ float: "right" }}
                            component="a"
                            target="_blank"
                            href="https://portal.azure.com/?l=#blade/HubsExtension/BrowseResource/resourceType/microsoft.insights%2Fcomponents"
                        >
                            Application Insights
                        </Button>
                    </Grid>
                </Grid >

                <div className="container">
                    <Switch>
                        <Route exact path={routes.applicationInsights} >
                            <ApplicationSelection />
                        </Route>

                        <Route path={routes.applicationInsights}>
                            <ApplicationInsightsData {...params} />

                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    </StyledContainer>
}

const ApplicationSelection = () => {
    const { t, language } = useTypedTranslation();

    const history = useHistory();

    const { data: apiKeyData } = useGetApiKeysQuery();

    const columns = useMemo<GridColDef<APIKey>[]>(() => [
        { ...limitedColumnProps, ...minWidthColumnProps, field: nameof<APIKey>('Type'), flex: 1, headerName: t("main", "type"), renderCell: handleRenderCell },
        { ...limitedColumnProps, ...minWidthColumnProps, field: nameof<APIKey>('Name'), flex: 1, headerName: t("main", "displayName") }
    ], [t]);

    const onAppSelectionRowClick = React.useCallback(({ row }: GridRowParams<APIKey>) => {
        history.push(`${routes.applicationInsights}/${row.Type}`);
    }, [history]);

    const initialState: GridInitialState = {
        sorting: {
            sortModel: [{ field: 'Type', sort: 'asc' }]
        }
    }

    const messages = React.useMemo(() => getMuiDataGridTranslations(language), [language]);

    return (
        (<Paper className="container">
            {
                apiKeyData &&
                <DataGrid
                    {...defaultGridProps}
                    columns={columns}
                    rows={apiKeyAdapter.getSelectors().selectAll(apiKeyData)}
                    getRowId={getApplicationSelectionRowId}
                    onRowClick={onAppSelectionRowClick}
                    initialState={initialState}
                    columnHeaderHeight={42}
                    slots={customComponents}
                    localeText={messages}
                />
            }
        </Paper >)
    );
}

export default ApplicationInsights;

const customComponents: Partial<GridSlotsComponent> = ({ toolbar: CustomGridToolbar });

const getApplicationSelectionRowId = (apiKey: APIKey) => apiKey.Key;

const handleRenderCell = ({ row }: GridRenderCellParams<APIKey>) => getImage(row.Type);