export const readFileAsText = (file: File) => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function () {
            resolve(this.result as string);
        }

        reader.onerror = function (event) {
            reject(event)
        }
        reader.readAsText(file);
    })
}

export const readFileAsDataURL = (file: File) => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function () {
            resolve(this.result as string);
        }

        reader.onerror = function (event) {
            reject(event)
        }
        reader.readAsDataURL(file);
    })
}
