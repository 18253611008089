import { Breakpoint, Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogProps, SxProps, Typography } from "@mui/material";
import React from 'react';
import { createPortal } from "react-dom";
import { useTypedTranslation } from "../definitions/languages";
import { contentContainerId } from "../frame/internal/ProgressContentContainer";

type FullPageDialog = {
    variant: "fullPage",
    onClose?: () => void,
    contentContainerId?: string
    closeButtonProps?: Partial<ButtonProps>
}

type BaseDialogProps = {
    variant?: undefined
    fullWidth?: boolean
    maxWidth?: Breakpoint
}

type BaseProps = {
    actions?: React.ReactNode,
    title: string,
    open: boolean,
    hideHeader?: boolean
}

export type ProgressDialogProps = BaseProps & (FullPageDialog | BaseDialogProps)

const fullPageSlotProps: DialogProps["slotProps"] = { backdrop: { style: { height: "100%", width: "100%", position: "absolute" } } }
const fullPageDialogStyle: SxProps = {
    "& > .MuiDialog-container": {
        padding: "10px"
    },
    height: "100%",
    width: "100%",
    position: "absolute"
}
const fullPagePaperProps: DialogProps["PaperProps"] = { style: { borderRadius: "4px" } }

export type ProgressDialogHeaderProps = {
    title: string
    actions?: React.ReactNode
    variant?: "fullPage"
    onClose?: () => void
    closeButtonProps?: Partial<ButtonProps>
}

export const ProgressDialogHeader = (props: ProgressDialogHeaderProps) => {
    const { t } = useTypedTranslation();

    return (
        <DialogActions style={{ backgroundColor: "#f2f2f2", borderBottom: "1px solid lightGray", padding: "8px", display: "flex", alignItems: "center", minHeight: 48 }}>
            <Typography
                variant="h3"
                fontSize="23px"
                gutterBottom
                display="flex"
                alignItems="center"
                marginBottom="0px"
                height="100%"
                marginRight="auto"
                whiteSpace="nowrap"
            >
                {props.title}
            </Typography>

            {
                props.variant === "fullPage" &&
                props.actions
            }

            {
                props.variant === "fullPage" &&
                <Button
                    variant="outlined"
                    {...props.closeButtonProps}
                    onClick={props.onClose}
                >
                    {t("close")}
                </Button>
            }
        </DialogActions>
    )
}

const DialogComponent = (props: React.PropsWithChildren<ProgressDialogProps>) => {
    return (
        <Dialog
            open={props.open}
            disablePortal={props.variant === "fullPage"}
            fullScreen={props.variant === "fullPage"}
            fullWidth={!props.variant ? props.fullWidth : undefined}
            slotProps={props.variant === "fullPage" ? fullPageSlotProps : undefined}
            sx={props.variant === "fullPage" ? fullPageDialogStyle : undefined}
            PaperProps={props.variant === "fullPage" ? fullPagePaperProps : undefined}
            maxWidth={!props.variant ? props.maxWidth : undefined}
        >
            {
                !props.hideHeader &&
                <ProgressDialogHeader
                    {...props}
                />
            }
            <DialogContent style={props.variant === "fullPage" ? { padding: "0px" } : undefined}>
                {props.children}
            </DialogContent>
            {
                props.variant === undefined && props.actions &&
                <DialogActions style={{ borderTop: "1px solid lightGray" }}>
                    {props.actions}
                </DialogActions>
            }
        </Dialog>
    )
}

export const ProgressDialog = (props: React.PropsWithChildren<ProgressDialogProps>) => {
    const containerElement = React.useMemo(() => document.getElementById(props.variant === "fullPage" && props.contentContainerId ? props.contentContainerId : contentContainerId), [props])

    return (
        <>
            {
                // The build in 'component' property of the Dialog, does not always work
                containerElement && props.variant === "fullPage" ?
                    createPortal(<DialogComponent {...props} />, containerElement)
                    : <DialogComponent {...props} />
            }
        </>
    )
}