import { Vector3 } from "./vector3";

export interface Box3 {
    min: Vector3,
    max: Vector3,
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Box3 {
    export const fromValues = (...values: Vector3[]) => {
        if (values.length === 0)
            return undefined

        const minX = values.reduce((min, v) => Math.min(v.x, min), +Infinity)
        const minY = values.reduce((min, v) => Math.min(v.y, min), +Infinity)
        const minZ = values.reduce((min, v) => Math.min(v.z, min), +Infinity)
        const maxX = values.reduce((min, v) => Math.max(v.x, min), -Infinity)
        const maxY = values.reduce((min, v) => Math.max(v.y, min), -Infinity)
        const maxZ = values.reduce((min, v) => Math.max(v.z, min), -Infinity)

        return Object.freeze({
            min: Vector3.fromValues(minX, minY, minZ),
            max: Vector3.fromValues(maxX, maxY, maxZ),
        })
    }

    export const zero: Readonly<Box3> = Object.freeze({
        min: Vector3.zero,
        max: Vector3.zero,
    })

    export const fromBoxes = (...boxes: Box3[]) => {
        if (boxes.length === 0)
            return undefined

        const values = [...boxes.map(v => v.min), ...boxes.map(v => v.max)]
        return Box3.fromValues(...values)
    }

    export const getCenter = (box: Box3) => {
        return Vector3.scale(Vector3.add(box.min, box.max), 1.0 / 2.0)
    }

    export const getSize = (box: Box3) => {
        return Vector3.subtract(box.max, box.min)
    }

    export const offset = (box: Box3, offset: Vector3) => {
        return Object.freeze({
            min: Vector3.add(box.min, offset),
            max: Vector3.add(box.max, offset),
        })
    }
}
