import { ProgressCanvas } from "./ProgressCanvas";

export const initHistory = (canvas: ProgressCanvas, maxItems: number) => {

    const historyNext = () => {
        return JSON.stringify(canvas.serialize());
    }

    const historySaveAction = () => {
        if (historyProcessing)
            return;

        const json = historyNextState;
        historyUndo = historyUndo.slice(- maxItems + 1);
        historyUndo.push(json);
        historyNextState = historyNext();
        canvas.fire('history:append', { json: json });

        canvas.isDirty = true;
    }

    const loadHistory = async (history: string, event: string) => {
        const isDirty = canvas.isDirty;
        await canvas.fromJSON(JSON.parse(history));
        canvas.isDirty = isDirty;

        canvas.discardActiveObject();
        canvas.renderAll();
        canvas.fire(event);
        historyProcessing = false;
    }

    const clearHistory = () => {
        historyUndo = [];
        historyRedo = [];
        canvas.fire('history:clear');
    }

    const offHistory = () => {
        historyProcessing = true;
    }

    const onHistory = () => {
        historyProcessing = false;
        historySaveAction();
        canvas.isDirty = false;
    }

    // const maxItems = maxItems;
    let historyUndo: string[] = [];
    let historyRedo: string[] = [];
    let historyProcessing = false;
    let historyNextState = historyNext();

    canvas.on('object:added', historySaveAction);
    canvas.on('object:removed', historySaveAction);
    canvas.on('object:modified', historySaveAction);
    canvas.on('object:skewing', historySaveAction);

    const undo = async () => {
        // The undo process will render the new states of the objects
        // Therefore, object:added and object:modified events will triggered again
        // To ignore those events, we are setting a flag.
        historyProcessing = true;

        const history = historyUndo.pop();
        if (history) {
            // Push the current state to the redo history
            historyRedo = historyRedo.slice(- maxItems + 1);

            historyRedo.push(historyNext());
            historyNextState = history;
            await loadHistory(history, 'history:undo');
        } else {
            historyProcessing = false;
        }
    }

    const redo = async () => {
        // The undo process will render the new states of the objects
        // Therefore, object:added and object:modified events will triggered again
        // To ignore those events, we are setting a flag.
        historyProcessing = true;

        const history = historyRedo.pop();

        if (history) {
            // Every redo action is actually a new action to the undo history
            historyUndo = historyUndo.slice(- maxItems + 1);

            historyUndo.push(historyNext());
            historyNextState = history;
            await loadHistory(history, 'history:redo');
        } else {
            historyProcessing = false;
        }
    }

    canvas.undo = undo;
    canvas.redo = redo;
    canvas.clearHistory = clearHistory;
    canvas.offHistory = offHistory;
    canvas.onHistory = onHistory;
}