import * as pako from 'pako';
import { MutableRefObject } from "react";
import { Log } from "./debug";

export function assertUnreachable(x: never): never {
    throw new Error("Didn't expect to get here" + JSON.stringify(x));
}

export function unreachableReducer(state: unknown): never {
    return state as never;
}

export const nameof = <T = unknown>(name: keyof T & string): keyof T & string => name;

/**
 * Function to recursively check if an element or parent has the indicated id
 * @returns true if the element contains a parent with the indicated id or the element itself has this id, false otherwise
 */
export function hasParentWithId(id: string, element: Element): boolean {
    if (!id || !element) {
        return false
    }

    if (element.id === id) {
        return true
    }

    if (element.parentElement) {
        return hasParentWithId(id, element.parentElement);
    }

    return false
}

/** 
 * Download a binary buffer to filename. 
 * 
 * Although the function code may seem rustical, it is the preferred way: https://javascript.info/file
 * There's no need to use "file-saver" library, unless there are incompatible browsers in use and you need fallbacks (download popup or navigating to data url). 
 * FileSaver.js does this internally too: https://github.com/Infinidat/file-saver/blob/master/FileSaver.js 
 * 
 * @param {Uint8Array} buffer - The buffer containing the file data.
 * @param {string} filename - The name of the file to be downloaded.
 * @return {void} This function does not return anything.
*/
export const fileDownload = (buffer: Uint8Array, filename: string): void => {
    const link = document.createElement("a")
    if (!("download" in link))
        return Log.error("Download via link is not supported by this browser, consider using file-saver library.")
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob)
    link.download = filename
    link.href = url
    link.click()
    URL.revokeObjectURL(url) //cleanup
    link.remove()
}

/**
 * Reads the first kb from the given file without loading the whole file into memory.
 *
 * @param {File} file - The file to read from.
 * @param {number} [kb] - The number of kilobytes to read. If not provided, defaults to 1.
 * @return {Promise<string>} A promise that resolves to the string representation of the first kb of the file.
 */
export const fileReadSlice = async (file: File, kb?: number): Promise<string> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        const fileSlice = file.slice(0, (kb && kb > 0 ? Math.round(kb) : 1) * 1024) //slice the first kb (1 by default or parameter)
        fileReader.onload = () => {
            if (fileReader.result) {
                //decode the array buffer to string and resolve promise
                resolve((new TextDecoder("utf-8")).decode(fileReader.result as ArrayBuffer))
            } else {
                reject("File could not be read.")
            }
        }
        fileReader.onerror = error => reject(error)
        fileReader.readAsArrayBuffer(fileSlice)
    });
}

/**
 * Represents a file with its associated metadata.
 * @property {string} name - The name of the file.
 * @property {Blob} data - The file data, which can be in a compressed or uncompressed format.
 * @property {boolean} compressed - Indicates whether the file data is in a compressed (ZIP) format.
 * @property {number} dataSize - The size of the `data` property, which can be either in its compressed or uncompressed form.
 * This size is typically used to determine the amount of data being transmitted or stored.
 * @property {number} fileSize - The size of the file when it's fully uncompressed.
 * This provides an understanding of the actual file size irrespective of its current compression state.
 */
export type BlobFile = {
    name: string;
    data: Blob;
    compressed: boolean;
    dataSize: number;
    fileSize: number;
}
/**
 * Reads the content of a given file and returns it as a BlobFile.
 * 
 * @param {File} file - The file to be read.
 * @param {boolean} compress - If true, will zip the file blob. Defaults to false.
 * @returns {Promise<Blob>} A promise that resolves to the file's content as a Blob.
 * @throws Will throw an error if there's an issue reading the file.
 * 
 * @example
 * const file = new File(["content"], "filename.txt");
 * readFileAsBlob(file).then(blobFile => {
 *   console.log(blobFile);
 * });
 */
export const readFileAsBlob = (file: File, compress = false): Promise<BlobFile> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
            const blob = new Blob(compress
                ? [pako.gzip(new Uint8Array(fileReader.result as ArrayBuffer))]
                : [new Uint8Array(fileReader.result as ArrayBuffer)]);
            resolve({
                name: file.name,
                data: blob,
                compressed: compress ?? false,
                dataSize: blob.size,
                fileSize: file.size
            });
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
        fileReader.readAsArrayBuffer(file);
    });
}

/**
 * Calculates the total file size in MB of an array of BlobFiles
 *
 * @param {BlobFile[]} files - An array of BlobFile objects representing files.
 * @return {number} The total (compressed) file size in bytes.
 */
export const totalFileSize = (files: BlobFile[]): number => {
    return files.reduce((acc, file) => acc + file.dataSize, 0) / 1024 / 1024;
}

/**
 * Debounces a function using requestAnimationFrame.
 *
 * @param {() => void} callback - The function to be debounced.
 * @return {() => void} - A debounced version of the input function.
 */
export function debouncePerFrame(callback: () => void) {
    let animationFrameId: number | null = null;
    return function () {
        if (animationFrameId !== null) {
            cancelAnimationFrame(animationFrameId);
        }
        animationFrameId = requestAnimationFrame(() => {
            callback();
        });
    };
}

/**
 * Checks if the reference value is undefined or null, and returns it if not.
 *
 * @param {MutableRefObject<T>} ref - the reference object to check
 * @param {string} name - the name of the reference value
 * @return {T} the reference value if it's not undefined or null
 */
export function requiredRef<T>(ref: MutableRefObject<T>, name?: string) {
    if (ref.current === undefined || ref.current === null)
        return Log.error(`the reference value ${name} is currently undefined/null`, ref.current)
    return ref.current
}
