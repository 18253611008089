import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { Box, CircularProgress, Container, IconButton, Paper, styled, Typography } from '@mui/material';
import { AutoRefreshButton, Logger } from '@progress/base-ui';
import axios from "axios";
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useCallback, useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { api, useTypedTranslation } from "../definitions";

const StyledContainer = styled(Container)(({ theme }) => ({
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: "2100px !important"
}));

const rootStyle: React.CSSProperties = {
    textAlign: "center"
}

const divStyle: React.CSSProperties = {
    width: "100%",
}

const divStyleHead: React.CSSProperties = {
    display: "inline-block",
    width: "100%",
    alignItems: "relative",
}

const contentStyle: React.CSSProperties = {
    margin: "20px",
    display: "flex",
    flexDirection: "column",
    textAlign: "start"
}

const loadingStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const marginTopStyle: React.CSSProperties = { marginTop: "5px" }

const ProgressBinUpdater: React.FC = () => {
    const { t } = useTypedTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [data, setData] = useState<{ currentToken: string, secret: string }>()
    const [loading, setLoading] = useState<boolean>(false);

    const loadData = useCallback(() => {
        setLoading(true);
        axios.get<{ currentToken: string, secret: string }>(api.progressBinUpdater)
            .then(x => setData(x.data))
            .catchError((e) => {
                enqueueSnackbar(t("main", "loadingError"), { variant: 'error' })
                Logger.error(e.Description)
            })
            .finally(() => setLoading(false));
    }, [enqueueSnackbar, t]);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClick = useCallback(() => {
        navigator.clipboard.writeText(data?.currentToken ?? "")
            .then(() => {
                enqueueSnackbar(t("binUpdater", "copySuccess"), { variant: 'success' });
            })
            .catchError(() => {
                enqueueSnackbar(t("binUpdater", "copyError"), { variant: 'error' })
            })
    }, [data, enqueueSnackbar, t]);

    return (
        <StyledContainer maxWidth="lg" className="container">
            <div className='container' style={rootStyle}>
                <div className='container' style={divStyle}>
                    <div style={divStyleHead}>
                        <Box
                            m={1}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="flex-end"
                        >
                            <h1 className="containerTitle">{t("binUpdater", "title")}</h1>
                        </Box>
                    </div>

                    <Paper className="container">
                        {
                            data &&
                            <div style={contentStyle}>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <Typography style={{ marginRight: "5px" }}>{t("binUpdater", "currentToken")}</Typography>
                                    <Typography fontWeight="bold">{data.currentToken}</Typography>
                                </div>
                                <div style={marginTopStyle}>
                                    <AutoRefreshButton
                                        onClick={onClick}
                                        onRefresh={loadData}
                                        refreshInterval={30000}
                                        loading={loading}
                                        disabled={loading}
                                        variant="outlined"
                                    >
                                        {t("binUpdater", "copyToClipboard")}
                                    </AutoRefreshButton>

                                </div>

                                <div style={{ marginTop: "50px" }}>
                                    <Typography>{t("binUpdater", "alternativeHint")}</Typography>
                                    <QRCode value={`otpauth://totp/Progress%20Bin%20Updater:dashboard.progress-cloud.com?secret=${data.secret}`} style={marginTopStyle} />
                                </div>

                                <Typography style={{ marginTop: "50px" }}>
                                    {t("binUpdater", "wiki")}
                                    <IconButton href="https://progress-group.atlassian.net/wiki/spaces/PROGRESSGROUP/pages/2932113500/ProgressBinUpdater" target="_blank">
                                        <OpenInNewIcon color="primary" />
                                    </IconButton>
                                </Typography>
                            </div>
                        }
                        {
                            !data && <div style={loadingStyle}>
                                <CircularProgress size={75} disableShrink />
                            </div>
                        }
                    </Paper>
                </div>
            </div>
        </StyledContainer >
    );
}

export default ProgressBinUpdater;