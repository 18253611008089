/** 
 * Sort numbers ascending, callback for sorting array of numbers with .sort() 
 * @example
 * [1, 100, 2].sort(sortNumericAsc)
*/
export const sortNumbersAsc = (a: number, b: number) => a - b

/** 
 * Sort numbers descending, callback for sorting array of numbers with .sort() 
 * @example
 * [1, 100, 2].sort(sortNumericAsc)
*/
export const sortNumbersDesc = (a: number, b: number) => b - a

/**
 * Provides a natural string sort for an array of strings.
 * @param a 
 * @param b 
 */
export const sortNaturalAsc = <T>(sortValue: (item: T) => string | undefined, locales: string | string[] | undefined = ['de', 'it', 'en']) => {
    const collator = new Intl.Collator(locales, { numeric: true, sensitivity: "base" })
    return (a: T, b: T) => collator.compare(sortValue(a) ?? '', sortValue(b) ?? '')
}

/**
 * Custom comparer function generator to sort an array of dot-separated paths either ascending or descending.
 * Numeric parts are compared numerically, and string parts are compared naturally using Intl.Collator.
 * This function abstracts common logic for both ascending and descending sorting.
 * 
 * @param direction The direction of the sort, either 'asc' for ascending or 'desc' for descending.
 * @param sortValue A function that returns the value to be sorted from an item.
 * @param locales The locales argument for the Intl.Collator.
 */
const createSortDotPath = <T>(
    direction: 'asc' | 'desc',
    sortValue: (item: T) => string | undefined,
    locales: string | string[] | undefined = ['de', 'it', 'en']
) => {
    const collator = new Intl.Collator(locales, { numeric: true, sensitivity: "base" });
    return (a: T, b: T) => {
        const partsA = (sortValue(a) ?? '').split('.');
        const partsB = (sortValue(b) ?? '').split('.');
        const minLength = Math.min(partsA.length, partsB.length);

        for (let i = 0; i < minLength; i++) {
            const partA = partsA[i];
            const partB = partsB[i];

            const isNumberA = !isNaN(Number(partA));
            const isNumberB = !isNaN(Number(partB));

            if (isNumberA && isNumberB) {
                const numA = parseFloat(partA);
                const numB = parseFloat(partB);

                if (numA !== numB) return (numA < numB ? -1 : 1) * (direction === 'asc' ? 1 : -1);
            } else if (isNumberA && !isNumberB) {
                return -1 * (direction === 'asc' ? 1 : -1);
            } else if (!isNumberA && isNumberB) {
                return 1 * (direction === 'asc' ? 1 : -1);
            } else {
                const compareResult = collator.compare(partA, partB);
                if (compareResult !== 0) return compareResult * (direction === 'asc' ? 1 : -1);
            }
        }

        return (partsA.length - partsB.length) * (direction === 'asc' ? 1 : -1);
    }
}

/**
 * Creates a comparer function for ascending sort of an array of dot-separated paths. 
 * This function sorts the elements based on the specified `sortValue` function, 
 * which extracts the string to compare from each element. Numeric parts of the paths 
 * are compared numerically, while string parts are compared naturally, respecting 
 * the provided locales. This function is useful for sorting object identifiers 
 * or any similar structured strings in ascending order.
 * 
 * @typeparam T The type of the elements in the array to be sorted.
 * 
 * @param sortValue A function that takes an element of type `T` and returns the 
 * string value to sort by, or `undefined` if there is no value to sort by.
 * @param locales Optional. One or more locales used for comparison. 
 * Defaults to `['de', 'it', 'en']`. This parameter is passed to `Intl.Collator` 
 * for natural string comparison.
 * 
 * @returns A comparer function that can be used with `Array.prototype.sort` 
 * for sorting an array of elements based on dot-separated path strings in ascending order.
 * 
 * @example
 * const items = [{ id: '1.10.2' }, { id: '1.2.2' }, { id: '1.2.10' }];
 * const sortedItems = items.sort(sortDotPathAsc(item => item.id));
 * // sortedItems is now [{ id: '1.2.2' }, { id: '1.2.10' }, { id: '1.10.2' }]
 */
export const sortDotPathAsc = <T>(sortValue: (item: T) => string | undefined, locales?: string | string[]) =>
    createSortDotPath('asc', sortValue, locales);

/**
 * Creates a comparer function for descending sort of an array of dot-separated paths.
 * This function is similar to {@link sortDotPathAsc}, but sorts the elements in descending order.
 * Refer to the documentation of `sortDotPathAsc` for detailed information on parameters and usage. */
export const sortDotPathDesc = <T>(sortValue: (item: T) => string | undefined, locales?: string | string[]) =>
    createSortDotPath('desc', sortValue, locales);

/**
 * Returns a new array with only the distinct elements from the input array.
 *
 * @param {T[]} array - The input array.
 * @return {T[]} The new array with distinct elements.
 */
export const arrayUnique = <T>(array: T[]): T[] => {
    return [...new Set(array)]
}

/**
 * Calculates the sum of all numbers in the given array.
 *
 * @param {number[]} arr - The array of numbers to be summed.
 * @return {number} The sum of all numbers in the array.
 */
export const arraySum = (arr: number[]): number => {
    return arr.reduce((acc, curr) => { return acc + curr }, 0);
}

/**
 * Returns the minimum value in an array of numbers, or a default value if the array is empty.
 * Please note that Math.min(...[]) is Infinity.
 *
 * @param {number[]} arr - The array of numbers to find the minimum value from
 * @param {T} defaultValue - The default value to return if the array is empty
 * @return {number | T} The minimum value in the array or the default value
 */
export const arrayMin = <T>(arr: number[], defaultValue: T): number | T => {
    return arr.length > 0 ? Math.min(...arr) : defaultValue
}

/**
 * Returns the maximum value in an array of numbers, or a default value if the array is empty.
 * Please note that Math.max(...[]) is -Infinity.
 * 
 * @param {number[]} arr - The array of numbers to find the maximum value from
 * @param {T} defaultValue - The default value to return if the array is empty
 * @return {number | T} The maximum value in the array or the default value
 */
export const arrayMax = <T>(arr: number[], defaultValue: T): number | T => {
    return arr.length > 0 ? Math.max(...arr) : defaultValue
}

/**
 * Chunks an array into smaller arrays of a specified size.
 * @param array - The array to chunk.
 * @param size - The size of each chunk.
 * @returns An array of chunks.
 */
export const arrayChunk = <T>(array: T[], size: number): T[][] => {
    const result: T[][] = []
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size)) // Create a chunk and add it to the result
    }
    return result
}