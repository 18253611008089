import { ButtonProps, LinearProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React from 'react';

type AutoRefreshButtonProps = ButtonProps & {
    onRefresh(): void
    refreshInterval: number
    loading: boolean
}

/**
 * Refresh of a component after a given interval
 * @param props as {@link AutoRefreshButtonProps}
 */
export const AutoRefreshButton: React.FC<AutoRefreshButtonProps> = (props) => {
    const { onRefresh, refreshInterval, loading, children, ...rest } = props;

    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        let timer: NodeJS.Timeout | null = null;
        if (!props.loading && !props.disabled) {
            timer = setInterval(() => {
                let shouldRefresh = false;
                setProgress((oldProgress) => {
                    if (oldProgress >= 100) {
                        shouldRefresh = true;
                        return 0;
                    }
                    const diff = 100 / refreshInterval * 1000;
                    return Math.min(oldProgress + diff, 100);
                });
                if (shouldRefresh) {
                    onRefresh();
                }
            }, 1000);
        }

        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [props.loading, onRefresh, refreshInterval, props.disabled]);

    return <>
        <LoadingButton loading={loading} {...rest} style={{ position: 'relative', minWidth: '100px' }} >
            {children}
            <div style={{ width: '100%', position: 'absolute', bottom: 0, left: 0, right: 0 }}>
                <LinearProgress variant="determinate" value={progress} />
            </div>
        </LoadingButton>
    </>
}
