import { Button, Paper } from '@mui/material';
import React from 'react';
import { useTypedTranslation } from '../../definitions';

const divStyleBody: React.CSSProperties = {
    width: "100%",
    padding: "20px",
    paddingTop: 0
}

const Email: React.FC = () => {

    const { t } = useTypedTranslation();

    return (
        <Paper style={{ margin: "20px", marginBottom: "0px" }} elevation={0} variant="outlined">
            <div style={{ padding: "20px", paddingTop: 0 }}>
                <h3 >{t("tools", "email", "descriptionTitle")}</h3>
                {t("tools", "email", "description") + " "}
                {' '}
            </div>
            <div style={divStyleBody}>
                <a href="mailto: helpdesk@progress-psd.com?subject=Neuer%20Service-Mitarbeiter%20für%20AAD-Gruppe&body=Hallo,%0D%0A%0D%0Abitte%20folgenden%20Mitarbeiter%20der%20AD%20Gruppe%20„HO-Azure-AAD-Identity-provider-for-keycloak-support“%20zuweisen:%0D%0A<email>%0D%0A%0D%0AViele Grüße,">
                    <Button variant='outlined'>
                        {t("tools", "email", "newService-MemberForAAD-Group")}
                    </Button>
                </a>
            </div>
        </Paper>
    )
}

export default Email