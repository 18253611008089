
import styled from "@emotion/styled";
import RotateRightIcon from '@mui/icons-material/RotateRight';
import ClearIcon from '@mui/icons-material/SearchOff';
import WidthFulllIcon from '@mui/icons-material/WidthFull';
import WidthNormalIcon from '@mui/icons-material/WidthNormal';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { useCallback } from "react";
import { PDFDocumentInfo } from "../hooks/usePdfConverter";
import { pdfViewerClass } from "./pdfViewerStyles";
import { PDFViewerToolbarFeatures, PDFViewerHandle } from "./pdfViewerTypes";

export type PDFViewerToolbarProps = {
    documentInfo: PDFDocumentInfo | undefined
    features?: PDFViewerToolbarFeatures[]
    disabled?: boolean
    pdfViewer: () => PDFViewerHandle
}

const PDFViewerToolbar = ({
    documentInfo,
    disabled = false,
    features,
    pdfViewer,
}: PDFViewerToolbarProps) => {

    const isFeatureEnabled = useCallback((feature: PDFViewerToolbarFeatures) => {
        if (!features)
            return true
        return features.indexOf(feature) > -1
    }, [features])

    return <PDFViewerToolbarContainer {...pdfViewerClass("toolbar")}>
        {
            isFeatureEnabled('zoom') && <button disabled={disabled} {...pdfViewerClass("toolbar-button", "zoomIn")} onClick={() => pdfViewer().zoomIn()}>
                <ZoomInIcon />
            </button>
        }
        {
            isFeatureEnabled('zoom') && <button disabled={disabled} {...pdfViewerClass("toolbar-button", "zoomOut")} onClick={() => pdfViewer().zoomOut()}>
                <ZoomOutIcon />
            </button>
        }
        {
            isFeatureEnabled('zoom') && <button disabled={disabled} {...pdfViewerClass("toolbar-button", "reset")} onClick={() => pdfViewer().resetView(false, false, false)}>
                <ClearIcon />
            </button>
        }
        {
            isFeatureEnabled('pageFit') && <button disabled={disabled} {...pdfViewerClass("toolbar-button", "fit-width")} onClick={() => pdfViewer().setPageFit('width')}>
                <WidthFulllIcon />
            </button>
        }
        {
            isFeatureEnabled('pageFit') && <button disabled={disabled} {...pdfViewerClass("toolbar-button", "fit-page")} onClick={() => pdfViewer().setPageFit('height')}>
                <WidthNormalIcon />
            </button>
        }
        {
            isFeatureEnabled('rotate') && <button disabled={disabled} {...pdfViewerClass("toolbar-button", "rotate-page")} onClick={() => pdfViewer().setPageRotation()}>
                <RotateRightIcon />
            </button>
        }
    </PDFViewerToolbarContainer>
}

const PDFViewerToolbarContainer = styled('div')({
    position: "absolute",
    bottom: "5%",
    right: 42,
    background: "#fff",
    [`& .${pdfViewerClass("toolbar-button").className}`]: {
        width: 36,
        height: 36,
        padding: 4,
        margin: 1
    },
})
export default PDFViewerToolbar