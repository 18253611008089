import { MutableRefObject, useLayoutEffect, useRef, useState } from "react";

/** type to hold width and height of an html element */
type HtmlElementSize = {
    width: number;
    height: number;
}

/** 
 * Hook to track any element resize event via ref. Enhances performance by debouncing resize events using requestAnimationFrame.
 * @typeparam ElementType - Type of the HTML element
 * @returns Array - [ref object, size state (object with width and height)]
 */
export function useResize<ElementType extends HTMLElement = HTMLDivElement>(): [MutableRefObject<ElementType | null>, HtmlElementSize] {
    const target = useRef<ElementType | null>(null)
    const [size, setSize] = useState<HtmlElementSize>({
        width: 0,
        height: 0
    })
    const animationFrameId = useRef<number | null>(null)

    const setRoundedSize = ({ width, height }: HtmlElementSize) => {
        if (animationFrameId.current !== null) {
            cancelAnimationFrame(animationFrameId.current) // Cancel the previous animation frame request
        }
        animationFrameId.current = requestAnimationFrame(() => {
            setSize({ width: Math.round(width), height: Math.round(height) }) // Update size within the animation frame for efficiency
        })
    }

    useLayoutEffect(() => {
        if (!target.current)
            return
        const observer = new ResizeObserver(entries => {
            for (const entry of entries) {
                const { inlineSize: width, blockSize: height } = entry.contentBoxSize[0]
                setRoundedSize({ width, height })
            }
        })
        observer.observe(target.current)
        const currentTarget = target.current
        return () => {
            if (currentTarget)
                observer.unobserve(currentTarget)
            if (animationFrameId.current !== null) {
                cancelAnimationFrame(animationFrameId.current) // Ensure to cancel any pending animation frame request
            }
        }
    }, [target])

    return [target, size]
}