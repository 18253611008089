
export interface Vector3 {
    x: number,
    y: number,
    z: number,
}

/**
 * Vector3 functions.
 * Taken from R5dLib.Calc, if any functions you need are missing, check the R5dLib.Calc code for reference.
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Vector3 {
    export const fromValues = (x: number, y: number, z: number): Readonly<Vector3> => {
        return Object.freeze({ x, y, z })
    }

    /** Zero vector */
    export const zero = Vector3.fromValues(0, 0, 0)

    /** Unit vector in x direction */
    export const ux = Vector3.fromValues(1, 0, 0)

    /** Unit vector in y direction */
    export const uy = Vector3.fromValues(0, 1, 0)

    /** Unit vector in z direction */
    export const uz = Vector3.fromValues(0, 0, 1)

    export const length = (v: Vector3) => {
        return Math.sqrt(v.x * v.x + v.y * v.y + v.z * v.z)
    }

    export const lengthSquared = (v: Vector3) => {
        return v.x * v.x + v.y * v.y + v.z * v.z
    }

    export const add = (a: Vector3, b: Vector3) => {
        return Vector3.fromValues(a.x + b.x, a.y + b.y, a.z + b.z)
    }

    export const subtract = (a: Vector3, b: Vector3) => {
        return Vector3.fromValues(a.x - b.x, a.y - b.y, a.z - b.z)
    }

    /** Scale the individual components of the vector */
    export const scale = (v: Vector3, u: number) => {
        return Vector3.fromValues(v.x * u, v.y * u, v.z * u)
    }

    /** Dot product */
    export const dot = (a: Vector3, b: Vector3) => {
        return a.x * b.x + a.y * b.y + a.z * b.z
    }

    /** Cross product */
    export const cross = (a: Vector3, b: Vector3) => {
        return Vector3.fromValues(
            a.y * b.z - a.z * b.y,
            a.z * b.x - a.x * b.z,
            a.x * b.y - a.y * b.x)
    }

    /** Create a Vector3 from a PXML Point3D. Also converts the Point3D from mm to m. */
    export const fromPoint3D = (pt: { X: number, Y: number, Z: number }) => {
        return Vector3.fromValues(pt.X / 1000.0, pt.Y / 1000.0, pt.Z / 1000.0)
    }
}
