import { LocalFile, setLoading, setLocalFiles, setSelectedFile } from "@/store/data/configSheet/configSheetSlice";
import { fabric } from "fabric";
import { store } from '../../store/configureStore';
import { readFileAsText } from "./fileHelpers";
import { LocFlag } from "./objects/locFlag";
import { MileStone } from "./objects/milestone";
import { CarouselLocation, DryChamberLocation, DryHourLegend, Image, Line, Rectangle, StockyardLocation, Text } from './objects/objects';
import { PalletStackLabel } from "./objects/palletStackLabel";
import { PLCLabel } from "./objects/plcLabel";
import { RuntimeParamEditor } from "./objects/runtimeParamEditor";
import { RuntimeParamLabel } from "./objects/runtimeParamLabel";
import { TransportJobLabel } from "./objects/transportJobLabel";
import { ProgressCanvas } from "./ProgressCanvas";
import { Logger } from "@progress/base-ui";

export const copy = (canvas: ProgressCanvas) => {
    if (navigator.clipboard) {
        const objects: object[] = [];
        if (canvas.getActiveObjects().length)
            if (canvas.getActiveObjects().length > 0) {
                if (canvas.getActiveObjects().length > 1) {
                    let x = 0
                    canvas?.getActiveObjects().forEach(function (o) {
                        objects.push(o.serialize())
                        if (o.left && o.group.left && o.group.width && o.top && o.group.top && o.group.height) {
                            objects[x] = { ...objects[x], left: (o.left + o.group.left + o.group.width / 2) }
                            objects[x] = { ...objects[x], top: (o.top + o.group.top + o.group.height / 2) }
                            x++;
                        }
                    })
                }
                else {
                    canvas?.getActiveObjects().forEach(function (o) {
                        objects.push(o.serialize())
                    })
                }
                const jsonString = `${(
                    JSON.stringify(objects)
                )}`;
                navigator.clipboard.writeText(jsonString)
            }
            else {
                Logger.debug("noothing to copy")
            }
    }
}

export const paste = (canvas: ProgressCanvas) => {
    if (navigator.clipboard) {

        navigator.clipboard.readText()
            .then(async (text) => {
                const controls = JSON.parse(text);
                const offsetLeft = 5
                const offsetTop = 5

                const elementsToAdd: fabric.Object[] = []

                for (const control of controls) {
                    const fabricControl = await createFabricControl(control);
                    if (fabricControl) {
                        fabricControl.left !== undefined && (fabricControl.left += offsetLeft);
                        fabricControl.top !== undefined && (fabricControl.top += offsetTop);
                        elementsToAdd.push(fabricControl);
                    }
                }

                if (elementsToAdd.length > 1) {
                    // disable the undo/redo history since this would save each object as an individual history entry
                    canvas.offHistory()
                }

                elementsToAdd.forEach(item => canvas.add(item));
                canvas.discardActiveObject();
                canvas.renderAll()

                if (elementsToAdd.length === 1) {
                    canvas.setActiveObject(elementsToAdd[0])
                } else {
                    const activeSelection = new fabric.ActiveSelection(elementsToAdd, { canvas: canvas });
                    canvas.setActiveObject(activeSelection);
                }

                if (elementsToAdd.length > 1) {
                    // enable undo/redo history
                    canvas.onHistory()
                }
                canvas.isDirty = true
                canvas.requestRenderAll();
            })
    }
}

export const createFabricControl = async (options: { type: string }) => {
    const { type, ...rest } = options;
    switch (type.toLocaleLowerCase()) {
        case "carousellocation":
            return new CarouselLocation(rest);
        case "drychamberlocation":
            return new DryChamberLocation(rest);
        case "stockyardlocation":
            return new StockyardLocation(rest);
        case "label":
            return new Text(rest as { text: string });
        case "rectangle":
            return new Rectangle(rest);
        case "dryhourlegend":
        case "legend":
            return new DryHourLegend(rest);
        case "line": {
            const { left, top, width, height, ...r } = rest as { left: number, top: number, width: number, height: number }
            return new Line([left, top, left + width, top + height], r);
        }
        case "image": {
            const imageProps: { dataUri?: string } = rest;
            if (imageProps.dataUri) {
                const { dataUri, ...r } = imageProps;
                const image = await Image.fromDataURL(dataUri, r);
                return image;
            } else {
                Logger.debug("Image does not provide a dataUri: ", options)
            }
            break;
        }
        case "plcvar":
            return new PLCLabel(rest);

        case "storepalletstack":
            return new PalletStackLabel(rest);

        case "runtimeparamlabel":
            return new RuntimeParamLabel(rest);
        case "runtimeparameditor":
            return new RuntimeParamEditor(rest);
        case "transportjoblabel":
            return new TransportJobLabel(rest);
        case "locflag":
            return new LocFlag(rest);
        case "milestone":
            return new MileStone(rest);
        default:
            Logger.debug("unknown type: ", type, rest);
            break;
    }

    return null;
}

export const addNativeFileHandle = (canvas: ProgressCanvas, fileHandle: FileSystemFileHandle) => {
    const item = {
        name: fileHandle.name,
        // file: file,
        fileHandle: fileHandle,
    }

    const localFiles = store.getState().data.configSheet.localFiles
    store.dispatch(setLocalFiles([...localFiles.filter(x => x.name !== fileHandle.name), item]))
    selectLocalFile(canvas, item);
}

export const selectLocalFile = async (canvas: ProgressCanvas, fileEntry: LocalFile) => {
    // check for unsaved changes
    if (canvas.getObjects().filter(e => e.isDirty).length > 0 || canvas.isDirty) {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm("There are unsaved changes. Close config without saving?")) {
            return;
        }
    }

    store.dispatch(setLoading(true))

    const file = await fileEntry.fileHandle.getFile();
    const content = await readFileAsText(file);
    const result = JSON.parse(content);
    canvas.clearHistory()
    canvas.offHistory();
    canvas.fromJSON(result);
    canvas.onHistory();

    store.dispatch(setLoading(false))
    store.dispatch(setSelectedFile({ name: fileEntry.name, sourceType: 'localFile' }))
}