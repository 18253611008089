import { LicenseInfo } from '@mui/x-license';
/**
 * Sets the license key for the MUI X DataGrid Premium version.
 * Call this function before React renders the first component.
 *
 * @see https://mui.com/x/advanced-components/#license-key-installation
 */
export function applyMUIGridLicense() {
    // valid until May 7 2024; support key: 89808
    LicenseInfo.setLicenseKey('1b8699722b16caed4bd40d6dc11b2138Tz04OTgwOCxFPTE3NDY2MjMxNjgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');
}