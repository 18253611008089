import { FilterOptionsState, ListItem, ListItemText } from '@mui/material';
import Autocomplete, { autocompleteClasses, AutocompleteCloseReason } from '@mui/material/Autocomplete';
import ListSubheader from '@mui/material/ListSubheader';
import Popper, { PopperProps } from '@mui/material/Popper';
import { styled, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useEffect } from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { useTypedTranslation } from '../definitions/languages';

interface IAutoCompleteInputProps {
    placeholder?: string,
    clearable?: boolean,
    items: string[],
    value?: string,
    onChange(value: string): void,
    required?: boolean,
    disabled?: boolean,
    restrictInput?: boolean,
    className?: string,
    onEnter?(e: React.KeyboardEvent<HTMLDivElement>): void,
    size?: "small" | "medium"
}

/**
 * * Autocomplete for a simple string to work out of the box as an extension
 * of the `Autocomplete` provided by mui
 * 
 * @param props determining string and behavior of the component
 * @returns JSX Element working simialar to `Autocomplete`
 * @see https://mui.com/material-ui/api/autocomplete/
 */
export const AutoCompleteInput: React.FC<IAutoCompleteInputProps> = (props) => {
    const { t } = useTypedTranslation();
    return (
        <Autocomplete
            className={`${props.className ?? ''} autcomplete`}
            size={props.size}
            options={props.items}
            getOptionLabel={(option) => option}
            clearOnBlur={!!props.restrictInput}
            clearOnEscape={!!props.restrictInput}
            freeSolo
            noOptionsText={t('autoCompleteInput', 'noData')}
            disableClearable={!props.clearable}
            disabled={props.disabled}
            inputValue={props.value}
            onInputChange={(e, newInputValue) => props.onChange(newInputValue)}
            renderInput={
                (params) => {
                    return (
                        <TextField
                            {...params}
                            required={props.required}
                            margin="dense"
                            className="my-0"
                            placeholder={props.placeholder}
                            onKeyPress={
                                e => {
                                    if (props.onEnter) {
                                        if (e.key === "Enter") {
                                            props.onEnter(e)
                                        }
                                    }
                                }
                            }
                            variant="outlined"
                        />
                    )
                }
            }
        />
    );
}

/**
 * Function that can be passed in AutoCompleteOptionInput's "filterOption" property.
 * @returns A filter that considers both label and subtitle (if defined), aka all visible text, when searching. If this filter function is not passed, 
 * AutoCompleteOptionInput will only filter through the labels.
 */
export function filterOptions<T extends { label: string, subtitle?: string }>(options: T[], state: FilterOptionsState<T>) {
    return options.filter(option =>
        option.label.toLowerCase().includes(state.inputValue.toLowerCase()) ||
        (option.subtitle && option.subtitle.toLowerCase().includes(state.inputValue.toLowerCase()))
    );
}

/**
 * used by `AutoCompleteOptionInput` to provide meaningful Items
 */
export type AutoCompleteItem = {
    id: string
    label: string
    subtitle?: string
}

type DataAttribute = {
    [key: `data-${string}`]: string | number | boolean | undefined;
};

interface AutoCompleteOptionInputPropsBase<T extends AutoCompleteItem> {
    placeholder?: string,
    clearable?: boolean,
    items: T[],
    required?: boolean,
    disabled?: boolean,
    className?: string,
    label?: string,
    size?: "small" | "medium",
    id?: string,
    autoFocus?: boolean
    isOptionEqualToValue?: (option: T, value: T) => boolean
    /** in addition to filter on label (default), also filter subtitle if available
     * @default false (filter by label only) */
    filterSubtitles?: boolean
    popperProps?: Partial<PopperProps> & DataAttribute //allow to pass custom data-* attributes
}

export interface AutoCompleteOptionInputProps<T extends AutoCompleteItem> extends AutoCompleteOptionInputPropsBase<T> {
    value?: T | null,
    onChange(item: T | null): void,
    freeSolo?: false,
    open?: boolean,
    onOpen?: (event: React.SyntheticEvent) => void,
    onClose?: (event: React.SyntheticEvent, reason: AutocompleteCloseReason) => void
}

export interface AutoCompleteOptionInputFreeSoloProps<T extends AutoCompleteItem> extends AutoCompleteOptionInputPropsBase<T> {
    value?: T | null | string,
    onChange(item: T | null | string): void,
    freeSolo: true,
    open?: boolean,
    onOpen?: (event: React.SyntheticEvent) => void,
    onClose?: (event: React.SyntheticEvent, reason: AutocompleteCloseReason) => void
}

type AutocompleteRenderItem<T extends AutoCompleteItem> = {
    props: React.HTMLAttributes<HTMLLIElement>
    item: T
}

/**
 * Autocomplete for a multivalue extension using `AutoCompleteItem` to provide a simpler version
 * of the `Autocomplete` provided by mui
 * 
 * @param props determining what generic type and behavior of the component
 * @returns JSX Element working simialar to `Autocomplete`
 * @see https://mui.com/material-ui/api/autocomplete/
 */
export function AutoCompleteOptionInput<T extends AutoCompleteItem>(props: AutoCompleteOptionInputProps<T> | AutoCompleteOptionInputFreeSoloProps<T>) {
    const { t } = useTypedTranslation();

    const [value, setValue] = React.useState("");
    const clearAfterInput = props.value === undefined;

    useEffect(() => {
        if (props.value === undefined || props.value === null) {
            setValue("")
        }
    }, [props.value])

    return (
        <Autocomplete
            id={props.id}
            className={`${props.className ?? ''} autcomplete`}
            size={props.size}
            options={props.items}
            clearOnBlur={!props.freeSolo}
            clearOnEscape={!props.freeSolo}
            freeSolo={props.freeSolo}
            PopperComponent={(popperProps) => <StyledPopper {...popperProps} {...props.popperProps} />}
            filterOptions={props.filterSubtitles ? filterOptions : undefined}
            isOptionEqualToValue={props.isOptionEqualToValue}
            open={props.open}
            onOpen={props.onOpen}
            onClose={props.onClose}
            // virtualization
            ListboxComponent={ListboxComponent}
            noOptionsText={t('autoCompleteInput', 'noData')}
            disableClearable={!props.clearable}
            disabled={props.disabled}
            inputValue={value}
            onInputChange={(_, newInputValue) => setValue(newInputValue)}
            value={props.value === undefined ? null : props.value}
            renderOption={(props, option) => ({ props: props, item: option } as unknown as AutocompleteRenderItem<T>)}
            onChange={
                (_, newValue) => {
                    if (typeof newValue === "string") {
                        // freeSolo allowed => pass it to onChange
                        if (props.freeSolo) {
                            props.onChange(newValue);
                        }
                        // if not, it's an invalid input => some random string => ignore this event
                    } else {
                        props.onChange(newValue);
                    }
                    if (clearAfterInput) {
                        setTimeout(() => {
                            setValue("");
                        }, 100)
                    }
                }
            }
            renderInput={
                (params) => {
                    return (
                        <TextField
                            {...params}
                            required={props.required}
                            margin="dense"
                            size="small"
                            className="my-0"
                            label={props.label}
                            placeholder={props.placeholder}
                            variant="outlined"
                            autoFocus={props.autoFocus}
                            onBlur={
                                (e) => {
                                    if (props.freeSolo && !props.value) {
                                        //if actually free text and not a selected item from list pass it on
                                        props.onChange(e.currentTarget.value)
                                    }
                                }
                            }
                            onKeyPress={
                                e => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }
                                }
                            }
                        />
                    )
                }
            }
        />
    );
}

// helpers to virtualize the autocomplete dropdown

const LISTBOX_PADDING = 2; // px

function renderRow(props: ListChildComponentProps<AutocompleteRenderItem<AutoCompleteItem>[]>) {
    const { data, index, style } = props;
    const dataSet = data[index]; // dataSet[0] = props, dataSet[1] = item T
    const inlineStyle = {
        ...style,
        top: (style.top as number) + LISTBOX_PADDING,
        borderBottom: 'solid 1px #ddd',
    };

    if (Object.prototype.hasOwnProperty.call(dataSet, 'group')) {
        return (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            <ListSubheader key={(dataSet as any).key} component="div" style={inlineStyle}>{(dataSet as any).group}</ListSubheader>
        );
    }

    const item = dataSet.item;

    return (
        <ListItem {...dataSet.props} style={inlineStyle}>
            <ListItemText primary={item.label} secondary={item.subtitle} />
        </ListItem>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>(function OuterElementType(props, ref) {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: number) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;

    const itemData = React.useMemo(() => {
        const itemData: AutocompleteRenderItem<AutoCompleteItem>[] = [];
        (children as AutocompleteRenderItem<AutoCompleteItem>[]).forEach(
            (item: AutocompleteRenderItem<AutoCompleteItem> & { children?: AutocompleteRenderItem<AutoCompleteItem>[] }) => {
                itemData.push(item);
                itemData.push(...(item.children || []));
            },
        );

        return itemData;
    }, [children])

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 44;

    const getChildSize = (data: AutocompleteRenderItem<AutoCompleteItem>) => {
        if (Object.prototype.hasOwnProperty.call(data, 'group')) {
            return 48;
        }

        if (data.item.subtitle && data.item.subtitle !== "") {
            return itemSize + 16;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    React.useEffect(() => {
        const index = itemData.findIndex(item => item.props['aria-selected'] === true);
        if (index >= 0) {
            gridRef.current?.scrollToItem(index, 'start')
        }
    }, [gridRef, itemData])

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});