
import { createTypedAsyncThunk } from "@/helpers/helpers";
import { Customer } from "@/definitions/autogenerated/types";
import axios from "axios";
import { api } from "../../../definitions";

export const fetchCustomers = createTypedAsyncThunk<Customer[], void>(
    "customers/fetch",
    async () => {
        const response = await axios.get<Customer[]>(api.configurationCustomer);
        return response.data;
    }
)