import { BaseComponent } from "./baseComponent";
import { fabric } from "fabric";
import * as d3 from "d3";
import { EditableProperty } from './objects'
import { PalDataMilestones } from "../../../definitions/autogenerated/types";
import { loadImage } from "./image";
import { nameof } from "@progress/base-ui";

export class MileStone extends BaseComponent {
    controlType = 'milestone';
    locationName: string
    milestoneType: keyof (typeof PalDataMilestones) | undefined
    activeImageSrc: string | undefined
    notActiveImageSrc: string | undefined
    toolTipTextKey: string

    // only for internal use
    private img: HTMLImageElement | undefined
    private visibleImageSrc: string | undefined

    constructor(options: fabric.IRectOptions & { locationName?: string, milestoneType?: keyof (typeof PalDataMilestones), activeImageSrc?: string, notActiveImageSrc?: string, toolTipTextKey?: string }) {
        super({
            //default
            ...{
                fill: '#ff8C00',
                stroke: '#000000',
                strokeWidth: 0,
                width: 20,
                height: 20,
                objectCaching: false,
                cornerColor: "black",
                borderColor: "black",
            },
            ...options,
        });

        this.locationName = options.locationName ?? ""
        this.milestoneType = options.milestoneType
        this.activeImageSrc = options.activeImageSrc
        this.notActiveImageSrc = options.notActiveImageSrc
        this.toolTipTextKey = options.toolTipTextKey ?? ""
    }

    _render(ctx: CanvasRenderingContext2D) {
        super._render(ctx);
        ctx.font = '12px sans-serif';
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        if (this.notActiveImageSrc) {
            if (!this.img || this.visibleImageSrc !== this.notActiveImageSrc) {
                loadImage(this.notActiveImageSrc)
                    .then(image => {
                        this.img = image
                        this.visibleImageSrc = this.notActiveImageSrc
                        ctx.drawImage(this.img, -(this.width ? this.width / 2 : 0), -(this.height ? this.height / 2 : 0), this.width ?? 0, this.height ?? 0)

                        // render the canvas to fix the image position
                        this.canvas?.renderAll()
                    })
            } else {
                ctx.drawImage(this.img, -(this.width ? this.width / 2 : 0), -(this.height ? this.height / 2 : 0), this.width ?? 0, this.height ?? 0)
            }
        } else {
            ctx.fillText(this.milestoneType ?? "Milestone", 0, 0);
        }
    }

    update<K extends keyof this>(propertyName: K, value: this[K] | ((value: this[K]) => this[K])): fabric.Object {
        this.set(propertyName, value);
        this.set('dirty', true)
        this.isDirty = true;
        this.canvas?.fire('object:modified')
        return this;
    }

    getProperties(): EditableProperty[] {
        return [
            ...super.getProperties(),
            { propertyName: nameof<MileStone>("locationName"), displayName: "locationName", type: "string" },
            // get only the string keys and not the numbers
            { propertyName: nameof<MileStone>("milestoneType"), displayName: "milestoneType", type: "string[]", elements: Object.keys(PalDataMilestones).filter(k => Number.isNaN(Number(k))) },
            { propertyName: nameof<MileStone>("toolTipTextKey"), displayName: "Tooltip", type: "string" },
            { propertyName: nameof<MileStone>("activeImageSrc"), displayName: "Active Image", type: "image" },
            { propertyName: nameof<MileStone>("notActiveImageSrc"), displayName: "Not active Image", type: "image", optional: true },
        ];
    }

    serialize() {
        const top = this.top ?? 0;
        const left = this.left ?? 0;

        const base = {
            type: this.controlType,
            width: this.width ?? 0,
            height: this.height ?? 0,
            locationName: this.locationName,
            milestoneType: this.milestoneType,
            activeImageSrc: this.activeImageSrc,
            notActiveImageSrc: this.notActiveImageSrc,
            toolTipTextKey: this.toolTipTextKey,
        }

        if (this.group) {
            const groupTop = this.group.top ?? 0;
            const groupLeft = this.group.left ?? 0;
            const groupHeight = this.group.height ?? 0;
            const groupWidth = this.group.width ?? 0;

            return {
                ...base,
                top: top + groupTop + groupHeight / 2,
                left: left + groupLeft + groupWidth / 2,
            }
        } else {
            return {
                ...base,
                top: top,
                left: left,
            }
        }
    }

    renderToSVG(svgElement: SVGSVGElement) {
        d3.select(svgElement)
            .append("rect")
            .attr("x", this.left ?? 0)
            .attr("y", this.top ?? 0)
            .attr("width", this.width ?? 0)
            .attr("height", this.height ?? 0)
            .style("fill", (this.fill ?? '') as string)
            .style("stroke", this.stroke ?? "#000")
            .attr("type", this.controlType)
            .attr("locationName", this.locationName)
            .attr("milestoneType", this.milestoneType ?? "")
            .attr("activeImageSrc", this.activeImageSrc ?? "")
            .attr("notActiveImageSrc", this.notActiveImageSrc ?? "")
            .attr("toolTipTextKey", this.toolTipTextKey ?? "")

        return svgElement;
    }
}
