import { roundTo, useEventListener } from "@progress/base-ui";
import { PropsWithChildren, forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import {
    ReactZoomPanPinchProps,
    ReactZoomPanPinchRef, TransformComponent, TransformWrapper
} from "react-zoom-pan-pinch";
import { Log } from "../utils/debug";

export type ZoomPanProps = ReactZoomPanPinchProps & PropsWithChildren<{
    /** Zoom step in percent, when zooming in or out through the +/- button */
    manualZoomStep?: number
    /** amount of pixels to scroll with one wheel step */
    scrollStep?: number
    /** Called when the zoom has really changed and animation stopped. Unlike onZoomStart/Stop, this also works with button zoom */
    onZoomChanged: (scale: number) => void
    /** Called when the pan has really changed and animation stopped */
    onPanChanged?: (x: number, y: number) => void
}>

export type ZoomPanHandle = {
    /** Zoom the viewer to the given scale. */
    setZoom: (scale: number) => void,
    /** Pan the viewer to the given position, but always keeping the content in view */
    setPan: (pan: { x: number, y: number }) => void
    /** Get the current pan of the viewer */
    getPan: () => { x: number, y: number }
    /** always keep the content in view */
    adjustToContent: () => void
    /** zoom in by a factor */
    zoomIn: () => void
    /** zoom out by a factor */
    zoomOut: () => void
}

export const ZoomPan = forwardRef<ZoomPanHandle, ZoomPanProps>(function ZoomPan(props, ref) {

    const zoomPanPinchRef = useRef<ReactZoomPanPinchRef | null>(null)
    const zoomPanPinchStateRef = useRef({ scale: 1, positionX: 0, positionY: 0 })
    const scrollParentRef = useRef<HTMLDivElement>(null)

    const { children, scrollStep = 200, manualZoomStep = 0.25, onZoomChanged, onPanChanged, onTransformed, ...zoomPanPinchProps } = props

    const zoomPan = useCallback(() => {
        if (!zoomPanPinchRef.current)
            throw new Error("zoom-pan-pinch is not initialized.")
        return zoomPanPinchRef.current
    }, [zoomPanPinchRef])

    const adjustToContent = useCallback(() => {
        const contentSize = zoomPanContent().getBoundingClientRect()
        const wrapperSize = zoomPanWrapper().getBoundingClientRect()
        Log.debug("adjustToContent containerSize", contentSize.width, 'x', contentSize.height, ' - ', wrapperSize.width, 'x', wrapperSize.height)
        //adjust horizontally
        if (contentSize.width < wrapperSize.width) // content width is smaller than the wrapper, must be centered horizontally
            zoomPan().setTransform((wrapperSize.width - contentSize.width) / 2, zoomPanState().positionY, zoomPanState().scale, 0)
        else if (zoomPanState().positionX > 0) //content width is bigger than the wrapper, but is shift to the right, must be 0
            zoomPan().setTransform(0, zoomPanState().positionY, zoomPanState().scale, 0)
        else if (zoomPanState().positionX < -(contentSize.width - wrapperSize.width)) //content width is bigger than the wrapper, but is shifted to the left, must set to maximum left
            zoomPan().setTransform(-(contentSize.width - wrapperSize.width), zoomPanState().positionY, zoomPanState().scale, 0)
        //adjust vertically
        if (contentSize.height < wrapperSize.height) // content height is smaller than the wrapper, must be centered vertically
            zoomPan().setTransform(zoomPanState().positionX, (wrapperSize.height - contentSize.height) / 2, zoomPanState().scale, 0)
        else if (zoomPanState().positionY > 0) // content height is bigger than the wrapper, but is shift to the bottom, must be 0
            zoomPan().setTransform(zoomPanState().positionX, 0, zoomPanState().scale, 0)
        else if (zoomPanState().positionY < -(contentSize.height - wrapperSize.height)) // content height is bigger than the wrapper, but is shifted to the top, must set to maximum top
            zoomPan().setTransform(zoomPanState().positionX, -(contentSize.height - wrapperSize.height), zoomPanState().scale, 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useImperativeHandle(ref, () => ({
        setPan: ({ x, y }) => {
            Log.debug("setPan", { x, y })
            zoomPan().setTransform(x, y, zoomPanState().scale, 0)
            adjustToContent()
        },
        getPan: () => ({
            x: zoomPanState().positionX,
            y: zoomPanState().positionY
        }),
        setZoom: (scale) => {
            zoomPan().setTransform(zoomPanState().positionX, zoomPanState().positionY, scale, 0)
            adjustToContent()
        },
        zoomIn: zoomPan().zoomIn,
        zoomOut: zoomPan().zoomOut,
        adjustToContent: adjustToContent
    }))

    const zoomPanWrapper = useCallback(() => {
        const wrapper = zoomPanPinchRef.current?.instance.wrapperComponent
        if (!wrapper)
            throw new Error("zoomPanScroll wrapper not found")
        return wrapper
    }, [])

    const zoomPanContent = useCallback(() => {
        const content = zoomPanPinchRef.current?.instance.contentComponent
        if (!content)
            throw new Error("zoomPanScroll content not found")
        return content
    }, [])

    const zoomPanState = useCallback(() => {
        if (!zoomPanPinchStateRef.current)
            throw new Error("zoomPanScroll state not found")
        return {
            scale: roundTo(zoomPanPinchStateRef.current.scale, 2),
            positionX: Math.floor(zoomPanPinchStateRef.current.positionX),
            positionY: Math.floor(zoomPanPinchStateRef.current.positionY)
        }
    }, [])

    /** Scrolls a zoomable and pannable container in response to a wheel event, considering shift key for horizontal scroll. */
    const handleScroll = useCallback((e: WheelEvent) => {
        const { positionX, positionY, scale } = zoomPanState()
        Log.debug("handleScroll", { positionX, positionY, scale, e })
        const scrollContent = (isHorizontal: boolean) => {
            const contentSize = isHorizontal
                ? Math.floor(zoomPanContent().getBoundingClientRect().width)
                : Math.floor(zoomPanContent().getBoundingClientRect().height)
            const wrapperSize = isHorizontal
                ? Math.floor(zoomPanWrapper().clientWidth)
                : Math.floor(zoomPanWrapper().clientHeight)
            if (!contentSize || !wrapperSize || contentSize < wrapperSize)
                return { positionX, positionY }

            let position = isHorizontal ? positionX : positionY

            position += e.deltaY < 0 ? scrollStep : -scrollStep
            const maxPosition = -(contentSize - wrapperSize)
            position = Math.min(0, Math.max(position, maxPosition)) //clamp position between 0 and maxPosition (negative value)
            return isHorizontal
                ? { ...{ positionX: position }, positionY }
                : { positionX, ...{ positionY: position } }
        }

        const { positionX: newPositionX, positionY: newPositionY } = scrollContent(e.shiftKey)
        Log.debug("handleScroll done", { positionX: newPositionX, positionY: newPositionY })
        zoomPan().setTransform(newPositionX, newPositionY, scale, 0)
    }, [scrollStep, zoomPan, zoomPanContent, zoomPanState, zoomPanWrapper])

    const handleZoom = useCallback((e: WheelEvent) => {
        if (e.deltaY < 0)
            zoomPan().zoomIn(manualZoomStep, 0);
        else
            zoomPan().zoomOut(manualZoomStep, 0);
    }, [zoomPan, manualZoomStep]);

    const lastZoomActionRef = useRef<{ scale: number }>({ scale: 1 })
    const lastZoomAction = useCallback(() => lastZoomActionRef.current, [])

    const lastPanActionRef = useRef<{ x: number, y: number }>({ x: 0, y: 0 })
    const lastPanAction = useCallback(() => lastPanActionRef.current, [])

    /** custom onTransformed to fix the missing call to onZoomStart/Stop when manually zooming */
    const handleOnTransformed = useCallback((ref, state) => {
        zoomPanPinchStateRef.current = state // update the state in the ref
        // Call the original onTransformed if provided
        onTransformed
            && onTransformed(ref, state)
        if (onZoomChanged) {
            const scale = roundTo(state.scale, 2)
            if (lastZoomAction().scale !== scale) {
                lastZoomAction().scale = scale
                onZoomChanged(scale);
            }
        }
        if (onPanChanged) {
            const positionX = Math.floor(state.positionX)
            const positionY = Math.floor(state.positionY)
            if (lastPanAction().x !== positionX || lastPanAction().y !== positionY) {
                lastPanAction().x = positionX
                lastPanAction().y = positionY
                onPanChanged(positionX, positionY)
            }
        }
    }, [lastPanAction, lastZoomAction, onPanChanged, onTransformed, onZoomChanged]);

    const handleWheel = useCallback((event: WheelEvent) => {
        if (event.ctrlKey) {
            event.preventDefault()
            handleZoom(event)
        } else
            handleScroll(event)
    }, [handleScroll, handleZoom])

    // Use the utility hook to attach a 'wheel' event listener
    useEventListener(scrollParentRef, 'wheel', handleWheel, { passive: false })

    Log.debug('*** Render ZoomPan')

    return (
        <div ref={scrollParentRef} style={{ height: '100%', width: '100%' }}>
            <TransformWrapper
                ref={zoomPanPinchRef}
                {...zoomPanPinchProps}
                zoomAnimation={
                    {
                        disabled: true
                    }
                }
                disablePadding
                onTransformed={handleOnTransformed}
                /**
                 * Disable the internal wheel handler and use our own. 
                 * scroll on wheel up/down, zoom on ctrl+scroll, scroll horizontally on shift+scroll 
                 */
                wheel={{ disabled: true }}
            >
                <TransformComponent wrapperStyle={{ height: '100%', width: '100%' }}>
                    {children}
                </TransformComponent>
            </TransformWrapper>
        </div>
    )
})