import { styled } from "@mui/material"
import React from "react";
import { TopBarOffset } from "./sharedStyledComponents";

export const contentContainerId = "progress-layout-content-container"

export const ContentContainer = styled('div')(() => ({
    display: "flex !important",
    flexDirection: "column",
    flexGrow: 0,
    overflowY: 'auto', position: 'relative', height: 'inherit'
}))

type ProgressContentContainerProps = {
  showTopBar: boolean
}

export const ProgressContentContainer: React.FC<ProgressContentContainerProps> = (props) => {
    return <main style={
        {
            flexGrow: 1,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: "auto",
        }
    }
    >
        {props.showTopBar && <TopBarOffset />}
        <ContentContainer id={contentContainerId}>
            {props.children}
        </ContentContainer>
    </main>
}