export type LogoComponentProps = {
    fontSizeInPx: number
}

export const replaceHtmlPlaceholders = (html: string, fontSizeInPx: number): string => {
    const logo = html
        .replaceAll('\r\n', "")
        .replaceAll(/>[\s]+</g, '><') //replace any whitespaces between tags
        .replaceAll('[fontSize]', `${fontSizeInPx}px`)
        .replaceAll('[fontSizeSup]', `${Math.round(fontSizeInPx * 0.7)}px`)
        .replaceAll('[marginSize]', `${Math.round(fontSizeInPx * 0.067)}px`)

    return logo;
}