import React from 'react';
import { Typography, Alert as MuiAlert, Button, styled } from '@mui/material';
import { Logger } from '../logging/logger';

export type ErrorComponentProps = {
    error: Error
    resetErrorBoundary: () => void
}

type ErrorBoundaryState = {
    error: Error | undefined
}

export type ErrorBoundaryProps = {
    children?: React.ReactNode
    ErrorComponent?: React.FC<ErrorComponentProps>
    onError?: (error: Error, info: React.ErrorInfo) => void
    onReset?: () => void
}

const logger = Logger.createSubcategoryLogger("ErrorBoundary");

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { error: undefined };
        this.reset = this.reset.bind(this);
    }

    static getDerivedStateFromError(error: Error) {
        return { error: error };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logger.error("Caught error", error, errorInfo)
        this.props.onError && this.props.onError(error, errorInfo);
    }

    reset() {
        this.props.onReset && this.props.onReset();
        this.setState({ error: undefined })
    }

    render() {
        const { children, ErrorComponent } = this.props;
        const FallbackComponent = ErrorComponent ?? DefaultErrorComponent;

        if (this.state.error) {
            return <FallbackComponent error={this.state.error} resetErrorBoundary={this.reset} />;
        }
        return children;
    }

}

const Alert = styled(MuiAlert)(({ theme }) => ({
    paddingLeft: '5px',
    borderLeft: 'solid 5px',
    borderLeftColor: theme.palette.error.main,
    '.MuiAlert-icon': {
        alignItems: 'center'
    }
}))

const DefaultErrorComponent: React.FC<ErrorComponentProps> = (props) => {
    return <div id="errorboundary-defaulterrorcomponent" style={{ display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Alert severity="error">
            <Typography fontWeight="bold" fontSize={18} textAlign="center">Error</Typography>
            <Typography >
                Oops, something went wrong. Please try again later.
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                <Button variant="outlined" color="error" onClick={props.resetErrorBoundary}>Retry</Button>
            </div>
        </Alert>
    </div>
}