
import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { api } from "../../../definitions";
import { APIKey } from "../../../definitions/autogenerated/types";
import { GridValidRowModel, GridColDef } from "@mui/x-data-grid-premium";
import moment from "moment";

export const apiKeyAdapter = createEntityAdapter<APIKey>({
    selectId: apiKey => apiKey.Type
})
export const appplicationInsightsAPI = createApi({
    baseQuery: fetchBaseQuery({ baseUrl: "" }),
    endpoints: build => ({
        getApiKeys: build.query<EntityState<APIKey>, void>({
            query: () => ({
                url: api.applicationInsights,
                method: "GET"
            }),
            transformResponse: (response: APIKey[]) => apiKeyAdapter.setAll(apiKeyAdapter.getInitialState(), response)
        }),

        postLogs: build.mutation<{ rows: GridValidRowModel[], columns: GridColDef<GridValidRowModel>[] }, { key: APIKey, parameter: { days: number, table: string, customer?: string } }>({
            query: ({ key, parameter }) => ({
                url: `https://api.applicationinsights.io/v1/apps/${key.ApplicationId}/query?timespan=P${parameter.days}D`,
                method: "post",
                body: {
                    "query": `${parameter.table}  | project ${parameter.table === "customEvents" ? "name," : ""} customDimensions, timestamp ${parameter.table === "customEvents" ? "" : ", details"}    ${parameter.customer
                        ? `| where customDimensions.customer contains "${parameter.customer}"`
                        : ""
                    }`
                },
                headers: {
                    "x-api-key": key.Key || "",
                },

            }),
            transformResponse: (rawResult:
                {
                    tables: {
                        columns: {
                            name: string,
                            type: string
                        }[],
                        rows: string[][]
                    }[]
                }) => {
                if (rawResult.tables.length === 0)
                    return { columns: [], rows: [] }

                const columns = rawResult.tables[0].columns
                const rows = rawResult.tables[0].rows

                const completeRows = rows.map((row, rowIndex) => {
                    let res: Record<string, string | number> = {};
                    columns.forEach((col, colIndex) => {
                        if (col.type === "dynamic") { //check if colum is string or stringified object
                            const colObject = JSON.parse(row[colIndex])
                            if (Array.isArray(colObject)) {
                                res = { ...res, ...{ details: JSON.stringify(colObject[0]) } }
                            } else {    //specific columns can be added here
                                if (colObject && colObject["timestamp"]) {  //parse time and date from timestamp
                                    const timestamp = moment(colObject["timestamp"])
                                    colObject["time"] = timestamp.format("HH:mm:ss")
                                    colObject["date"] = timestamp.format("YYYY-MM-DD")
                                }
                                res = { ...res, ...colObject }
                            }

                        } else {  //append normal columns to res object
                            res[col.name] = row[colIndex]
                        }

                        res["id"] = rowIndex + colIndex  //every row needs an ID in MUI DataGrid
                        if (!res["customer"])         //MUI DataGrid cannot group by undefined, inorder to make a group of all row that dont have a customer, customer is set to "" if it is undefined
                            res["customer"] = ""
                    });
                    return res
                })

                const rowColumns = Array.from(new Set(completeRows.flatMap((row: object) => Object.keys(row)))) //generate columns from rows

                rowColumns.forEach((column, index) => {     //set specific columns to beginning of Datagrid
                    if (column === "time" || column === "date" || column === "customer") {
                        rowColumns.splice(index, 1);
                        rowColumns.unshift(column);
                    }
                })

                const completeColumns = rowColumns.map(column => ({
                    minWidth: 170,
                    field: column
                }))

                return { columns: completeColumns, rows: completeRows }
            }
        })
    })
})

export const { useGetApiKeysQuery, usePostLogsMutation } = appplicationInsightsAPI