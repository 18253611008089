
import { fabric } from "fabric";
import { EditableProperty } from './objects'

export abstract class BaseComponent extends fabric.Rect {
    abstract controlType: string;
    isDirty = false;

    constructor(options?: fabric.IRectOptions) {
        options || (options = {});

        if (!options?.width) {
            options.width = 100;
        }
        if (!options?.height) {
            options.height = 50;
        }
        if (!options?.left) {
            options.left = 10;
        }
        if (!options?.top) {
            options.top = 10;
        }

        options.objectCaching = false;
        super(options);
        this.strokeUniform = true

        this.on('scaling', () => {
            const w = (this.width ?? 0) * (this.scaleX ?? 0);
            const h = (this.height ?? 0) * (this.scaleY ?? 0);
            this.height = h;
            this.width = w;
            this.scaleX = 1;
            this.scaleY = 1;
        })

        this.set("objectCaching", false);
        this.set("cornerColor", "black");
        this.set("borderColor", "black");
        this.setControlsVisibility({ mtr: false });
    }

    getProperties(): EditableProperty[] {
        return [
            { propertyName: 'left', displayName: 'X', type: 'number' },
            { propertyName: 'top', displayName: 'Y', type: 'number' },
            { propertyName: 'width', displayName: 'Width', type: 'number' },
            { propertyName: 'height', displayName: 'Height', type: 'number' },
        ]
    }

    update<K extends keyof this>(propertyName: K, value: this[K] | ((value: this[K]) => this[K])): fabric.Object {
        this.set(propertyName, value);
        this.set('dirty', true)
        this.isDirty = true;
        this.canvas?.fire('object:modified')

        return this;
    }

    serialize() {
        const top = this.top ?? 0;
        const left = this.left ?? 0;

        const base = {
            type: this.controlType,
            width: this.width ?? 0,
            height: this.height ?? 0,
        }

        if (this.group) {
            const groupTop = this.group.top ?? 0;
            const groupLeft = this.group.left ?? 0;
            const groupHeight = this.group.height ?? 0;
            const groupWidth = this.group.width ?? 0;

            return {
                ...base,
                top: top + groupTop + groupHeight / 2,
                left: left + groupLeft + groupWidth / 2,
            }
        } else {
            return {
                ...base,
                top: top,
                left: left,
            }
        }
    }

}