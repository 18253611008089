/** 
 * ATTENTION:
 * pdfjs-dist MUST NOT be imported directly, since it contains a top-level await, which does not work on some (older) browsers, 
 * therefore it must be lazy loaded: 
 * const pdfjsDist = await import("pdfjs-dist");
 */
import { PDFDocumentLoadingTask, PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist";
import pdfJSWorkerURL from "pdfjs-dist/build/pdf.worker?url";
import { useCallback, useRef, useState } from 'react';
import { useTypedTranslation } from '../definitions/languages';
import { Log } from '../utils/debug';

export type PDFSize = {
    height: number;
    width: number;
}

export type PDFPageRotation = 0 | 90 | 180 | 270

/** pages available immediately after load */
export type PDFLoadedPage = {
    size: PDFSize
    page: PDFPageProxy
}

/** rendered page when scale known */
export type PDFRenderedPage = {
    pageNumber: number
    size: PDFSize
    scale: number
    scaledSize: PDFSize
    canvas: HTMLCanvasElement
    //rotation is ignored yet, extend if needed
}

/** all needed info about the document, available immediately after load (before rendering) */
export type PDFDocumentInfo = {
    documentId: string
    pageCount: number
    pageSizes: PDFSize[]
}

export type PDFLoadedPages = PDFLoadedPage[]
export type PDFRenderedPages = PDFRenderedPage[]

export enum PDFConverterError {
    UnknownError = "unknownError",
    DocumentNotLoaded = "documentNotLoaded",
    DocumentLoadingFailed = "documentLoadingFailed",
    DocumentHasNoPages = "documentHasNoPages",
    PageRenderingError = "pageRenderingError",
    PageLoadingError = "pageLoadingError",
    PageDoesNotExist = "pageDoesNotExist"
}

/** Prepare canvas using PDF page dimensions - this is a very basic operation, if it fails it will throw an exception */
function createCanvas(width: number, height: number): { canvas: HTMLCanvasElement, context: CanvasRenderingContext2D } {
    const canvas = document.createElement('canvas')
    if (!canvas)
        throw new Error("Failed to create canvas")
    const context = canvas?.getContext('2d')
    if (!context)
        throw new Error("Failed to get context from canvas")
    canvas.width = width
    canvas.height = height
    return { canvas, context }
}

export function usePdfConverter() {

    const { t } = useTypedTranslation()

    const pdfProxyRef = useRef<PDFDocumentProxy | undefined>(undefined)
    const pdfLoadingTaskRef = useRef<PDFDocumentLoadingTask | undefined>(undefined)
    const pdfPagesProxyRef = useRef<PDFPageProxy[]>([])
    const renderingTasksRef = useRef<Map<number, { promise: Promise<PDFRenderedPage>, shouldCancel: boolean }>>(new Map());

    const [pdfDocumentInfo, setPDFDocumentInfo] = useState<PDFDocumentInfo | undefined>(undefined)
    const pagesRenderingRef = useRef<number[]>([])
    const [pagesRendering, setPagesRendering] = useState(false)
    const renderedPagesRef = useRef<(PDFRenderedPage | undefined)[]>([])

    const [documentLoading, setDocumentLoading] = useState(false)
    const [pageRendersFirstTime, setPageRendersFirstTime] = useState(false)
    const renderedPagesSetRef = useRef(new Set<number>()) // Keep track of rendered pages

    const renderPDFPage = useCallback(async (pageNumber: number, renderScale = 1, pageRotation: PDFPageRotation = 0): Promise<PDFRenderedPage> => {
        if (!pdfDocumentInfo) {
            Log.error('No PDF document loaded when requesting page ' + pageNumber)
            throw t('pdfConverter', PDFConverterError.DocumentNotLoaded)
        }
        if (pdfDocumentInfo.pageCount < pageNumber) {
            Log.error('The PDF document does not have page ' + pageNumber, { pdfDocumentInfo, pageNumber })
            throw t('pdfConverter', PDFConverterError.PageDoesNotExist)
        }

        const existingTask = renderingTasksRef.current.get(pageNumber);
        if (existingTask) {
            existingTask.shouldCancel = true; // Mark existing task to be ignored.
        }

        Log.debug('Rendering page ' + pageNumber + ' in scale ' + renderScale + ' with rotation ' + pageRotation);
        let shouldCancel = false; // Local flag to control cancellation.

        // Add pageNumber to pagesRenderingRef and set pagesRendering to true
        pagesRenderingRef.current.push(pageNumber)
        setPagesRendering(true)

        // Set pageRendersFirstTime to true if it's the first time this page is being rendered
        if (!renderedPagesSetRef.current.has(pageNumber)) {
            setPageRendersFirstTime(true)
        }
        // Create a promise outside of the task object to control the async flow.
        // eslint-disable-next-line no-async-promise-executor -- using async resolution because render threads can be cancelled and render is async
        const renderPromise = new Promise<PDFRenderedPage>(async (resolve, reject) => {
            try {
                const page = pdfPagesProxyRef.current[pageNumber - 1];
                const scaledPageViewport = page.getViewport({ scale: renderScale, rotation: (page.rotate + pageRotation) % 360 });
                const { canvas, context } = createCanvas(scaledPageViewport.width, scaledPageViewport.height);

                if (shouldCancel || !pdfDocumentInfo)
                    return; // Abort if marked as canceled or document closed.

                await page.render({
                    canvasContext: context,
                    viewport: scaledPageViewport
                }).promise;

                if (shouldCancel || !pdfDocumentInfo)
                    return; // Abort if marked as canceled or document closed.

                const pageSize = pdfDocumentInfo.pageSizes[pageNumber - 1]

                const pdfPage: PDFRenderedPage = {
                    pageNumber,
                    canvas,
                    size: pageSize,
                    scale: renderScale,
                    scaledSize: {
                        width: scaledPageViewport.width,
                        height: scaledPageViewport.height
                    }
                };

                Log.debug('Rendered page ' + pageNumber + ' in scale ' + renderScale, page, pdfPage);
                renderedPagesRef.current[pageNumber - 1] = pdfPage;
                resolve(pdfPage);
            } catch (error) {
                Log.debug(PDFConverterError.PageRenderingError);
                Log.error(error);
                if (!shouldCancel) {
                    reject(error); // Only reject if not canceled.
                }
            } finally {
                if (!shouldCancel) {
                    // Cleanup after rendering or on cancellation.
                    pagesRenderingRef.current = pagesRenderingRef.current.filter(p => p !== pageNumber);
                    setPagesRendering(pagesRenderingRef.current.length > 0);
                    if (!renderedPagesSetRef.current.has(pageNumber)) {
                        setPageRendersFirstTime(false)
                        renderedPagesSetRef.current.add(pageNumber) // Mark this page as rendered
                    }
                }
            }
        });

        // Set up the task object with the render promise and the cancellation flag.
        const currentTask = { promise: renderPromise, shouldCancel: false };
        renderingTasksRef.current.set(pageNumber, currentTask);

        // Setup a cancellation logic right before executing the promise.
        shouldCancel = currentTask.shouldCancel;

        try {
            // Wait for the rendering to complete or to be cancelled.
            const renderedPage = await renderPromise;
            // Ensure to clear the task after completion.
            renderingTasksRef.current.delete(pageNumber);
            return renderedPage;
        } catch (error) {
            // In case of error, ensure to clear the task.
            renderingTasksRef.current.delete(pageNumber);
            throw error;
        }
    }, [pdfDocumentInfo, t]);

    const destroyPDFLoadingTask = useCallback(async () => {
        Log.debug('Destroying PDF loading task', pdfLoadingTaskRef.current)
        if (pdfLoadingTaskRef.current) {
            await pdfLoadingTaskRef.current.destroy()
            pdfLoadingTaskRef.current = undefined
        }
    }, [])

    const closePDFDocument = useCallback(() => {
        setDocumentLoading(false)
        setPDFDocumentInfo(undefined)
        renderedPagesRef.current = []
        pdfProxyRef.current = undefined
        pdfPagesProxyRef.current = []
        renderedPagesSetRef.current.clear() // Clear the rendered pages set        
    }, [])

    const loadPDFDocument = useCallback(async (base64Data: string): Promise<PDFDocumentInfo> => {
        setDocumentLoading(true)
        setPDFDocumentInfo(undefined)
        renderedPagesRef.current = []
        pdfProxyRef.current = undefined
        pdfPagesProxyRef.current = []
        renderedPagesSetRef.current.clear() // Reset the rendered pages set        
        await destroyPDFLoadingTask()
        try {
            const data = window.atob(base64Data)
            //because of top level await which is not supported by older browsers, we need to import this dynamically, so it only would fail at this point.
            const pdfjsDist = await import("pdfjs-dist");
            if (pdfjsDist.GlobalWorkerOptions.workerSrc !== pdfJSWorkerURL) {
                pdfjsDist.GlobalWorkerOptions.workerSrc = pdfJSWorkerURL;
            }
            pdfLoadingTaskRef.current = pdfjsDist.getDocument({ data })
            pdfProxyRef.current = await pdfLoadingTaskRef.current.promise
            const numberOfPages = pdfProxyRef.current.numPages
            renderedPagesRef.current = Array(numberOfPages).fill(undefined) // Initialize with undefined for each page
            if (numberOfPages < 1) {
                Log.error('PDF document has no pages')
                throw t('pdfConverter', PDFConverterError.DocumentHasNoPages)
            }
            for (let pageNumber = 1; pageNumber <= numberOfPages; pageNumber++) {
                //after document load, immediately get all the page proxies and sizes, so pdf page sizes are available before rendering any page.
                pdfPagesProxyRef.current.push(await pdfProxyRef.current.getPage(pageNumber)) //the proxy, not actually loaded page
            }
            Log.debug('PDF Document info building', pdfPagesProxyRef.current.length)
            const documentInfo = {
                documentId: pdfProxyRef.current.fingerprints.filter(f => f).join('-'), //first fingerprint is the document, second the revision (if set)
                pageCount: numberOfPages,
                pageSizes: pdfPagesProxyRef.current.map(page => {
                    const viewPort = page.getViewport({ scale: 1 }) //original page sizes, not scaled
                    return { width: viewPort.width, height: viewPort.height }
                }) //for pdf, we can have different page sizes in a single document
            }
            setPDFDocumentInfo(documentInfo)
            Log.debug('PDF document loaded. ', documentInfo.documentId, 'pages:', documentInfo.pageCount, 'sizes:', documentInfo.pageSizes)
            return documentInfo
        } catch (error) {
            console.error(error);
            throw t('pdfConverter', PDFConverterError.DocumentLoadingFailed)
        } finally {
            setDocumentLoading(false) // Destroy the loading task here, if it was created
        }
    }, [destroyPDFLoadingTask, t])

    return {
        loadPDFDocument,
        closePDFDocument,
        renderPDFPage,
        pdfDocumentInfo,
        pdfDocumentLoading: documentLoading,
        pdfPagesRendering: pagesRendering,
        pdfPageRendersFirstTime: pageRendersFirstTime
    }

}