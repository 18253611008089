import { fetchReleases } from '@/store/data/release/actions';
import { LoadingButton } from '@mui/lab';
import { Button, InputLabel, MenuItem, Select } from '@mui/material';
import { ProgressDialog } from '@progress/base-ui';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import ScreenLoading from '../../components/ScreenLoading';
import { api } from '../../definitions';
import { useReduxDispatch, useTypedSelector } from '../../store/configureStore';
import { fetchDeployments } from '../../store/data/deployments/actions';
import { deploymentUIActions } from '../../store/ui/deploymentsUISlice';

export const UpdateDialog = () => {
    const appToUpdate = useTypedSelector(state => state.ui.deployments.appToUpdate);
    const allReleases = useTypedSelector(state => state.data.releases.releases)
    const dispatch = useReduxDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState("");

    useEffect(() => {
        dispatch(fetchReleases())

        setValue(appToUpdate?.currentVersion ?? '')
    }, [appToUpdate, dispatch])

    const hancleCancel = useCallback(() => {
        dispatch(deploymentUIActions.setAppToUpdate(undefined))
    }, [dispatch])

    const handleSubmit = useCallback(() => {
        if (!appToUpdate) {
            return;
        }
        setLoading(true)

        const url = appToUpdate.appType === 'non-global'
            ? api.configurationUpdateVersion(appToUpdate.appIdentifier, value)
            : api.configurationUpdateGlobalVersion(appToUpdate.appIdentifier, value)

        axios.put(url)
            .then(() => {
                enqueueSnackbar('Success', { variant: 'success' })
                dispatch(deploymentUIActions.setAppToUpdate(undefined))
                setValue("")

                dispatch(fetchDeployments())
            }).catchError((e) => {
                enqueueSnackbar(e.Description, { variant: 'error' })
            }).finally(() => {
                setLoading(false)
            })
    }, [appToUpdate, dispatch, enqueueSnackbar, value])

    if (!appToUpdate) {
        return null;
    }
    const releases = allReleases[appToUpdate.type] ?? []

    return <ProgressDialog
        title="update"
        open
        onClose={hancleCancel}
        actions={
            <>
                <Button variant="text" onClick={hancleCancel} disabled={loading}>Cancel</Button>
                <LoadingButton
                    variant="contained" onClick={handleSubmit} loading={loading}
                    disabled={!value || value === appToUpdate.currentVersion || loading}
                >
                    Update
                </LoadingButton>
            </>
        }
    >
        <div>
            Type:
            {appToUpdate.type}
        </div>
        <div>
            Id:
            {appToUpdate.appIdentifier}
        </div>
        <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
            <InputLabel htmlFor="select-tag" style={{ width: 200, textAlign: 'right' }}>Version</InputLabel>
            <Select
                fullWidth
                id='select-tag'
                autoFocus
                sx={{ width: 300 }}
                placeholder="Select"
                autoComplete="off"
                size="small"
                value={value}
                onChange={(e) => setValue(e.target.value)}
            >
                {
                    releases.map(r => {
                        return <MenuItem key={r} value={r}>{r}</MenuItem>
                    })
                }
            </Select>
        </div>
        {loading && <ScreenLoading />}
    </ProgressDialog >
}

// => single update
// eboswebapi
// ebosyc

// => multitenant
// mebosycwebapp
// admincenteryc's
// avicadyc's