import axios from 'axios';
import i18next, { i18n, ResourceKey, ResourceLanguage, TOptions, TOptionsBase } from 'i18next';
import LanguageDetector, { DetectorOptions } from 'i18next-browser-languagedetector';
import { Settings } from 'luxon';
import { useMemo } from 'react';
import { initReactI18next, useTranslation } from 'react-i18next';
import { Log } from '../utils/debug';

const languageDetectorOptions: DetectorOptions = {
    // order and from where user language should be detected
    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

    // keys or params to lookup language from
    lookupQuerystring: 'Language',
    lookupCookie: 'Language',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language onas
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    cookieMinutes: 120,
    // cookieDomain: 'myDomain',

    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,

    // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
    cookieOptions: { path: '/', sameSite: 'strict' }
}

export type Language = {
    name: string
    short: string
}

export type ResourceWithFallbackLanguage = {
    de: ResourceLanguage;
    [language: string]: ResourceLanguage;
}

export type LocalizationProps = {
    resourceBundles: ResourceWithFallbackLanguage
    onLanguageChanged?: (language: string) => void
    languageDetectorOptions?: DetectorOptions,
    supportedCultures?: string[],
}

export type StringMap<T = unknown> = { [key: string]: T };

const logger = Log.createSubcategoryLogger("localization");

export const initializeLocalization = (props: LocalizationProps) => {
    const { resourceBundles, supportedCultures } = props;
    const languages = Object.keys(resourceBundles);
    const fallbackLng = 'de';
    logger.debug("i18next -- initialize language", resourceBundles)

    /** 
     * Make sure that fallback language "de" is always supported (for lib translations if not).
     * By adding it to extendedSupportLangs, we avoid that the languageDetector detects "de" and sets it although it isn't configured.
    */
    const extendedSupportedLangs = supportedCultures ? [...supportedCultures] : [...languages];

    if (!extendedSupportedLangs.includes(fallbackLng)) {
        extendedSupportedLangs.push(fallbackLng); //fallback lang
    }

    // for all languages like "en-GB" we also need to register the base language "en"
    [...extendedSupportedLangs].forEach(lng => {
        if (lng.includes("-")) {
            const baseLanguage = lng.split("-")[0];

            if (baseLanguage && !extendedSupportedLangs.includes(baseLanguage)) {
                extendedSupportedLangs.push(baseLanguage);
            }
        }
    })

    logger.debug('strings', resourceBundles, createResourceCopy(resourceBundles))

    i18next
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            interpolation: { escapeValue: false },
            fallbackLng,
            load: 'all',
            resources: createResourceCopy(resourceBundles),
            supportedLngs: extendedSupportedLangs,
            detection: props.languageDetectorOptions ?? languageDetectorOptions,
            nonExplicitSupportedLngs: true,
            returnObjects: true
        })
        .then(() => {
            // when i18next is initialized, we have to check if the language is supported
            // if the language (could come from the browser like de-DE) is not supported,
            // we check if "de" => substr(0,2) of "de-DE" is supported
            // otherwise we take the fallback-language "de"
            const lng = i18next.language;

            const availableLanguages = supportedCultures ?? languages;
            if (!availableLanguages.find(item => item === lng)) {
                let fallback = fallbackLng;
                if (availableLanguages.find(item => item === lng.substring(0, 2))) {
                    fallback = lng.substring(0, 2);
                }
                else if (availableLanguages.length > 0) {
                    fallback = availableLanguages[0];
                }
                i18next.changeLanguage(fallback);
            } else {
                i18next.changeLanguage(lng);
            }

            logger.info(`language detected: "${lng}"`);
            replaceHtmlLang(lng);
            addNoTranslateMetaTag();
        });

    i18next.on("languageChanged", (lang: string) => {
        replaceHtmlLang(lang);

        logger.info(`languageChanged: "${lang}"`);

        // make sure that luxon interprets 'en' as 'en-GB'
        Settings.defaultLocale = lang === "en" ? "en-GB" : lang;

        if (props.onLanguageChanged) {
            props.onLanguageChanged(lang);
        }
    });

    // always include the current culture
    axios.interceptors.request.use(function (config) {
        const lang = i18next.language;
        config.headers = { ...config.headers, Language: lang };
        return config;
    });
}

/**
 * Replaces the language code in <html lang=""> tag and with the passed parameter
 * @param lang short language code (e.g. "de", "en",...)
 */
export const replaceHtmlLang = (lang: string) => {
    document.querySelector("html")?.setAttribute("lang", lang);
}

const addNoTranslateMetaTag = () => {
    const head = document.querySelector("head");
    if (head) {
        const metaTag = head.querySelector("meta[name='google']");
        if (!metaTag) {
            const meta = document.createElement('meta');
            meta.name = "google";
            meta.content = "notranslate";
            head.appendChild(meta);
        }
    }
}

/**
 * Deletes all current resources in i18next and reapplies the passed resources
 * @param resources default resources
 */
export const resetResourcesToDefault = (resources: ResourceWithFallbackLanguage) => {
    const newResources = structuredClone(resources);

    for (const [lang, t] of Object.entries(newResources)) {
        // delete previous translations
        Object.keys(t).forEach(ns => {
            i18next.removeResourceBundle(lang, ns);
        });

        // restore original translations
        for (const [ns, r] of Object.entries(t)) {
            i18next.addResourceBundle(lang, ns, r);
        }
    }
}

export type TranslationsProps = {
    bundleKey: string
    resourceBundles: ResourceWithFallbackLanguage
}

/**
 * @param resources a LibYC.WebComponents.PropertyGrid.TranslationObject containing the translations
 */
export type CustomerSpecificTranslationsProps = {
    resources: Record<string, Record<string, string>>
}

const addResourceBundles = (props: TranslationsProps) => {
    const { bundleKey } = props;
    const resourceBundles = createResourceCopy(props.resourceBundles);
    logger.debug(bundleKey + " language --  i18next initialized", resourceBundles);
    for (const key in resourceBundles) {
        i18next.addResourceBundle(key, bundleKey, resourceBundles[key], true, false);
    }
}

/**
 * add translations as resource bundles to i18next
 */
export const addTranslations = (props: TranslationsProps) => {
    if (i18next.isInitialized) {
        addResourceBundles(props);
    } else {
        logger.debug(props.bundleKey + " language --  waiting for initialization")
        i18next.on("initialized", () => addResourceBundles(props));
    }
}

export const addCustomerSpecificTranslations = (props: CustomerSpecificTranslationsProps) => {
    for (const [translationKey, translationItems] of Object.entries(props.resources)) {
        for (const [lang, translation] of Object.entries(translationItems)) {
            //split at first occurrence of ':' to extract namespace and key(s)
            const [namespace, ...keys] = translationKey.split(':');
            if (keys && keys.length > 0) {
                i18next.addResource(lang, namespace, keys.join('.'), translation);
            }
            // else ignore this namespace since it is invalid without a key
        }
    }
}
const createResourceCopy = (
    originalResources: ResourceWithFallbackLanguage
): ResourceWithFallbackLanguage => {
    const fallbackLanguage = 'de';

    // Deep clone the original resources to start with
    const mergedResources: ResourceWithFallbackLanguage = structuredClone(originalResources);

    Object.keys(originalResources).forEach((language) => {
        if (language === fallbackLanguage) {
            // No need to merge the fallback language with itself
            return;
        }

        const fallbackResourceLanguage = originalResources[fallbackLanguage];
        const targetResourceLanguage = mergedResources[language] || {};

        // Merge namespaces
        for (const namespace in fallbackResourceLanguage) {
            if (
                Object.prototype.hasOwnProperty.call(fallbackResourceLanguage, namespace)
            ) {
                const fallbackNamespaceKeys = fallbackResourceLanguage[namespace];
                const targetNamespaceKeys = targetResourceLanguage[namespace];

                // Merge the namespace keys
                const mergedNamespaceKeys = mergeResourceKeys(
                    fallbackNamespaceKeys,
                    targetNamespaceKeys,
                    `${language}.${namespace}`
                );

                targetResourceLanguage[namespace] = mergedNamespaceKeys;
            }
        }

        // Assign the merged language back to the mergedResources
        mergedResources[language] = targetResourceLanguage;
    });

    return mergedResources;
};

function mergeResourceKeys(
    fallbackKeys: ResourceKey,
    targetKeys: ResourceKey | undefined,
    path: string
): ResourceKey {
    if (typeof fallbackKeys === 'string') {
        if (typeof targetKeys === 'string') {
            // Both are strings, use the target value
            return targetKeys;
        } else {
            // Types differ or target is undefined, overwrite with fallback and log assertion
            if (targetKeys !== undefined) {
                logger.warn(
                    `Type mismatch at "${path}": Expected a string but got ${getTypeDescription(
                        targetKeys
                    )}. Overwriting with fallback value.`
                );
            }
            return fallbackKeys;
        }
    } else if (isResourceKeyObject(fallbackKeys)) {
        if (typeof targetKeys === 'string') {
            // Types differ, overwrite with fallback and log assertion
            logger.warn(
                `Type mismatch at "${path}": Expected an object but got string. Overwriting with fallback value.`
            );
            return fallbackKeys;
        } else if (isResourceKeyObject(targetKeys)) {
            // Both are objects, merge recursively
            for (const key in fallbackKeys) {
                if (Object.prototype.hasOwnProperty.call(fallbackKeys, key)) {
                    const newPath = `${path}.${key}`;
                    const fallbackValue = fallbackKeys[key];
                    const targetValue = targetKeys[key];

                    targetKeys[key] = mergeResourceKeys(
                        fallbackValue,
                        targetValue,
                        newPath
                    );
                }
            }
            return targetKeys;
        } else {
            // Target keys is undefined or null, use fallback keys
            return fallbackKeys;
        }
    } else {
        // Fallback keys is neither string nor object, handle unexpected type
        logger.warn(
            `Unexpected type for fallback keys at "${path}": ${getTypeDescription(
                fallbackKeys
            )}.`
        );
        return targetKeys !== undefined ? targetKeys : fallbackKeys;
    }
}

function isResourceKeyObject(
    value: ResourceKey | undefined
): value is { [key: string]: ResourceKey } {
    return (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value)
    );
}

function getTypeDescription(value: ResourceKey | undefined): string {
    if (value === undefined) return 'undefined';
    if (value === null) return 'null';
    if (typeof value === 'string') return 'string';
    if (typeof value === 'object') return 'object';
    return typeof value;
}

export type Dictionary = string | DictionaryObject;
type DictionaryObject = { [K: string]: Dictionary };

export interface TypedTFunction<D extends Dictionary> {
    <K extends keyof D>(args: K): D[K];
    <K extends keyof D, K1 extends keyof D[K]>(...args: [K, K1]): D[K][K1];
    <K extends keyof D, K1 extends keyof D[K], K2 extends keyof D[K][K1]>(...args: [K, K1, K2]): D[K][K1][K2];
    <K extends keyof D, K1 extends keyof D[K], K2 extends keyof D[K][K1], K3 extends keyof D[K][K1][K2]>(...args: [K, K1, K2, K3]): D[K][K1][K2][K3];
    <K extends keyof D, K1 extends keyof D[K], K2 extends keyof D[K][K1], K3 extends keyof D[K][K1][K2], K4 extends keyof D[K][K1][K2][K3]>(...args: [K, K1, K2, K3, K4]): D[K][K1][K2][K3][K4];
    <K extends keyof D, K1 extends keyof D[K], K2 extends keyof D[K][K1], K3 extends keyof D[K][K1][K2], K4 extends keyof D[K][K1][K2][K3], K5 extends keyof D[K][K1][K2][K3][K4]>(...args: [K, K1, K2, K3, K4, K5]): D[K][K1][K2][K3][K4][K5];
    // ... up to a reasonable key parameters length of your choice ...
}

export interface TypedTPFunction<D extends Dictionary> {
    <K extends keyof D>(param: unknown, args: K): D[K];
    <K extends keyof D, K1 extends keyof D[K]>(param: unknown, ...args: [K, K1]): D[K][K1];
    <K extends keyof D, K1 extends keyof D[K], K2 extends keyof D[K][K1]>(param: unknown, ...args: [K, K1, K2]): D[K][K1][K2];
    <K extends keyof D, K1 extends keyof D[K], K2 extends keyof D[K][K1], K3 extends keyof D[K][K1][K2]>(param: unknown, ...args: [K, K1, K2, K3]): D[K][K1][K2][K3];
    <K extends keyof D, K1 extends keyof D[K], K2 extends keyof D[K][K1], K3 extends keyof D[K][K1][K2], K4 extends keyof D[K][K1][K2][K3]>(param: unknown, ...args: [K, K1, K2, K3, K4]): D[K][K1][K2][K3][K4];
    <K extends keyof D, K1 extends keyof D[K], K2 extends keyof D[K][K1], K3 extends keyof D[K][K1][K2], K4 extends keyof D[K][K1][K2][K3], K5 extends keyof D[K][K1][K2][K3][K4]>(param: unknown, ...args: [K, K1, K2, K3, K4, K5]): D[K][K1][K2][K3][K4][K5];
    // ... up to a reasonable key parameters length of your choice ...
}

export const localeNumberSeparator = (locale: string, type: "decimal" | "group"): string =>
    Intl.NumberFormat(locale)
        .formatToParts(1000.1)
        .find(part => part.type === type)?.value || (type === "decimal" ? ',' : '.');

export type NumberFormat = {
    decimalSeparator: string,
    thousandSeparator: string
}

const serializeKeys = (keys: string[], bundleKey?: string) => {
    const prefix = bundleKey ? bundleKey + ":" : "";
    return prefix + keys.join(':');
}

/** @param bundleKey optional for frames, but mandatory for lib components */
export function useTypedTranslationHook<T extends Dictionary>(bundleKey?: string): {
    t: TypedTFunction<T>,
    tp: TypedTPFunction<T>,
    changeLanguage: (language: string | undefined) => void,
    i18n: i18n,
    language: string,
    numberFormat: NumberFormat
} {
    const { t, i18n } = useTranslation();

    // implementation goes here: join keys by dot (depends on your config)
    // and delegate to lib t
    return useMemo(() => {
        return {
            t(...keys: string[]) { return t(serializeKeys(keys, bundleKey)) },
            tp(params: unknown, ...keys: string[]) { return t(serializeKeys(keys, bundleKey), params as string | TOptions<StringMap> | undefined) },
            changeLanguage: i18n.changeLanguage,
            i18n,
            language: i18n.language,
            numberFormat: {
                thousandSeparator: localeNumberSeparator(i18n.language, "group"),
                decimalSeparator: localeNumberSeparator(i18n.language, "decimal")
            }
        }
    }, [bundleKey, i18n, t]);
}

export function typedTranslationBase<T extends Dictionary>(bundleKey?: string): { t: TypedTFunction<T>, tp: TypedTPFunction<T>, language: string } {
    return {
        t(...keys: string[]) { return i18next.t(serializeKeys(keys, bundleKey)) },
        tp(params: unknown, ...keys: string[]) { return i18next.t(serializeKeys(keys, bundleKey), params as TOptionsBase & StringMap) },
        language: i18next.language
    };
}
