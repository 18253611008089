import ReactDOM from "react-dom";
import { Log } from "../utils/debug";

export interface ProgressWindow {
    supportedLocales: string[],
}

declare global {
    interface Window {
        supportedLocales: string[],
    }
}

const logger = Log.createSubcategoryLogger("Frame");

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ProgressApp {

    /**
     * Returns an array of supported locales. 'en' will be replaced to 'en-GB'
     */
    export function getSupportedLocales(): string[] {
        return window.supportedLocales ?? [];
    }

    /**
     * function that returns the base path of the application
     * @returns  string that contains the base path of the application
     */
    export function getApplicationRoot(): string {

        const baseNode = document.querySelector('base');

        const href = baseNode?.getAttribute('href');
        let pathBase = "/";
        if (href) {
            pathBase = href.replace(/\/$/, '');
        }
        logger.debug("Application base:", href)
        return pathBase;
    }

    /**
     * removes the custom html loading screen
     */
    export function removeLoadingScreen() {
        const element = document.getElementById('loading');
        if (element) element.style.visibility = 'hidden';
    }

    /**
     * shows the custom html loading screen
     */
    export function showLoadingScreen() {
        const element = document.getElementById('loading');
        if (element) element.style.visibility = 'visible';
    }

    /**
     * renders the react client if the specified condition is met and a predefined fallback otherwise
     * @param displayApp boolean that defines if the application should be rendered, otherwise an error is shown
     * @param content react jsx content that should be displayed
     */
    export function conditionalRender(displayApp: boolean, content: JSX.Element) {
        if (displayApp) {
            ReactDOM.render(
                content,
                document.getElementById('root'));
            removeLoadingScreen();
        } else {
            showConnectionError();
        }
    }

    /**
     * displays a connection error fallback element
     */
    export function showConnectionError() {
        console.warn("Server (API) apears to be offline");
        const serverWarning = document.getElementById('server-warning');
        const spinner = document.getElementById('spinner');
        if (serverWarning && spinner) {
            serverWarning.style.display = 'block';
            spinner.style.display = 'none';
        }
    }

}
