
/**
 * Numeric helper functions.
 * Taken from R5dLib.Calc, if any functions you need are missing, check the R5dLib.Calc code for reference.
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Num {
    /**
     * The smallest value by which two numbers must differ in order to be considered similar.
     * The value was chosen so that it is optimized for numbers in the range from 0.1 to 100000
     * (This is a typical mm range in finished products).
     * This value does not work for very large or very small numbers.
    */
    export const alikeEpsilon = 1e-6

    /**
     * Returns true if the two given numbers are approximately equal 
     * (within 1e-6 difference).
     *
     * @param {number} a - The first number to compare.
     * @param {number} b - The second number to compare.
     * @return {boolean} - True if the numbers are approximately equal, false otherwise.
     */
    export function alike(a: number, b: number): boolean {
        return Math.abs(a - b) < alikeEpsilon;
    }

    /**
     * Determines if the first number is less than the second number `and they are not approximately equal.
     *
     * @param {number} a - The first number to compare.
     * @param {number} b - The second number to compare.
     * @return {boolean} True if the first number is less than the second number and they are not approximately equal, otherwise false.
     */
    export function less(a: number, b: number): boolean {
        return a < b && !Num.alike(a, b);
    }

    /**
     * Returns true if the first number is greater than the second number and they are not approximately equal.
     *
     * @param {number} a - The first number to compare.
     * @param {number} b - The second number to compare.
     * @returns {boolean} - True if the first number is greater than the second number and they are not approximately equal.
     */
    export function greater(a: number, b: number): boolean {
        return Num.less(b, a)
    }

    /**
     * Returns a boolean indicating whether the first number is less than or
     * approximately equal to the second number.
     *~
      * @param {number} a - The first number to be compared.
      * @param {number} b - The second number to be compared.
      * @return {boolean} True if the first number is less than or approximately equal to the
      * second number, false otherwise.
      */
    export function lessOrAlike(a: number, b: number): boolean {
        return !Num.greater(a, b)
    }

    /**
     * Returns a boolean indicating whether the first number is greater than or
     * approximately equal to the second number.
     *~
     * @param {number} a - The first number to be compared.
     * @param {number} b - The second number to be compared.
     * @return {boolean} True if the first number is greater than or approximately equal to the
     * second number, false otherwise.
     */
    export function greaterOrAlike(a: number, b: number): boolean {
        return !Num.less(a, b)
    }
}
