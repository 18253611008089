import { Container, styled, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { DataGridPremium as DataGrid, GridColDef, GridRenderCellParams, GridRowParams, GridSlotsComponent, GridSortModel } from '@mui/x-data-grid-premium';
import { AutoRefreshButton, Logger, nameof, useOnMount } from '@progress/base-ui';
import { getMuiDataGridTranslations } from '@progress/base-ui/src/definitions/languages';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CustomGridToolbar, MobileGrid, MobileItemRow, defaultGridProps, limitedColumnProps, minWidthColumnProps } from '../components/CustomGridComponents';
import { useTypedTranslation } from '../definitions';
import { Deployment } from '../definitions/autogenerated/types';
import { getImage } from '../helpers';
import { useReduxDispatch, useTypedSelector } from '../store/configureStore';
import { fetchProgressApps } from '../store/data/progressApps/actions';
import { progressAppsSelectors } from '../store/data/progressApps/progressAppsSlice';

const StyledContainer = styled(Container)(({ theme }) => ({
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: "2100px !important"
}));

const rootStyle: React.CSSProperties = {
    margin: "0",
    minHeight: "100%"
}
const divStyle: React.CSSProperties = {
    display: "block",
    width: "100%"
}

const divStyleHead: React.CSSProperties = {
    display: "inline-block",
    width: "100%",
    alignItems: "relative",
    margin: "1"

}

const customComponents: Partial<GridSlotsComponent> = ({
    toolbar: CustomGridToolbar,
})

const defaultSortModel: GridSortModel = [{ field: 'ApplicationType', sort: 'asc' }];
const handleRenderCell = ({ row }: GridRenderCellParams<Deployment>) => getImage(row.ApplicationType);

const ProgressAppsList: React.FC = () => {
    const [sort, setSort] = useState<GridSortModel>(defaultSortModel ?? []);
    const [isLoading, setIsLoading] = useState(false);
    const apps = useTypedSelector(progressAppsSelectors.selectAll);

    const { t, language } = useTypedTranslation();
    const dispatch = useReduxDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const loadApps = useCallback(() => {
        setIsLoading(true);

        dispatch(fetchProgressApps())
            .unwrap()
            .catchError((e) => {
                enqueueSnackbar(t("main", "loadingError"), { variant: 'error' })
                Logger.error(e.Description)
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [dispatch, enqueueSnackbar, t])

    // fetch the data once the component is mounted
    useOnMount(() => {
        loadApps();
    });

    const widthLargerThan900 = useMediaQuery("(min-width:900px)");
    const headerHeight = useMemo(() => widthLargerThan900 ? 42 : 0, [widthLargerThan900]);
    const getRowHeight = useCallback(() => widthLargerThan900 ? 32 : 'auto', [widthLargerThan900]);

    const messages = useMemo(() => getMuiDataGridTranslations(language), [language]);

    const columns = useMemo<GridColDef<Deployment>[]>(() => [
        { ...limitedColumnProps, field: nameof<Deployment>('ApplicationType'), headerName: ' ', minWidth: 200, width: 400, align: "center", headerAlign: "center", renderCell: handleRenderCell },
        { ...limitedColumnProps, ...minWidthColumnProps, field: nameof<Deployment>('AppName'), headerName: t("main", "name") },
        { ...limitedColumnProps, ...minWidthColumnProps, field: nameof<Deployment>('ReleaseTag'), headerName: t("main", "releaseTag") },
    ], [t]);

    useEffect(() => {
        if (widthLargerThan900) {
            setSort(defaultSortModel ?? [])
        }
        else {
            setSort([]);
        }
    }, [widthLargerThan900])

    const mobileColumnRenderer = useCallback(({ row }: GridRenderCellParams<Deployment>) => (
        <MobileGrid gridKey="appsMobile" spacing={0} sx={{ marginBottom: '10px' }}>
            <MobileItemRow title="" isObject>
                <div style={{ margin: "auto", marginBottom: 10 }}>{getImage(row.ApplicationType)}</div>
            </MobileItemRow>

            <MobileItemRow title={`${t("main", "name")}:`}>
                {row.AppName}
            </MobileItemRow>

            <MobileItemRow title={`${t("main", "releaseTag")}:`}>
                {row.ReleaseTag}
            </MobileItemRow>

            {/* <div style={{ display: "flex", width: "100%", marginTop: 10 }}>
                <div style={{ margin: "auto" }}>
                    <a href={row.Url} target="_blank" rel="noopener noreferrer">
                        <OpenInNewIcon style={{ color: "blue" }} />
                    </a>
                </div>
            </div> */}
        </MobileGrid>
    ), [t]);

    const mobileColumn = useMemo<GridColDef<Deployment>[]>(() => [
        { ...limitedColumnProps, field: 'mobile', headerName: ' ', renderCell: mobileColumnRenderer, sortable: false, flex: 1 }
    ], [mobileColumnRenderer]);

    const gridColumns = useMemo<GridColDef<Deployment>[]>(() => widthLargerThan900 ? columns : mobileColumn, [columns, mobileColumn, widthLargerThan900]);

    return (
        (<StyledContainer maxWidth="lg" className="container">
            <div className='container' style={rootStyle}>
                <div className='container' style={divStyle}>

                    <div style={divStyleHead}>

                        <Box
                            m={1}
                            //margin
                            display="flex"
                            justifyContent="space-between"
                            alignItems="flex-end"

                        >
                            <h1 className="containerTitle">{t("apps", "title")}</h1>
                            <AutoRefreshButton
                                variant="outlined"
                                loading={isLoading}
                                onRefresh={loadApps}
                                onClick={loadApps}
                                refreshInterval={120000}
                            >
                                {t("main", "refresh")}
                            </AutoRefreshButton>
                        </Box>
                    </div>

                    <Paper className="container">
                        <DataGrid
                            {...defaultGridProps}
                            rows={apps}
                            columns={gridColumns}
                            getRowId={getRowId}
                            onRowClick={onAppRowClick}
                            sortModel={sort}
                            onSortModelChange={setSort}
                            columnHeaderHeight={headerHeight}
                            getRowHeight={getRowHeight}

                            loading={isLoading}

                            slots={customComponents}
                            slotProps={{loadingOverlay: {variant: "linear-progress"}}}

                            localeText={messages}
                        />
                    </Paper>
                </div>
            </div>
        </StyledContainer>)
    );
}

export default ProgressAppsList;

const getRowId = (app: Deployment) => app.Url;
const onAppRowClick = (params: GridRowParams<Deployment>) => window.open(params.row.Url, '_blank')?.focus();