import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useTypedTranslation } from '../definitions/languages';
import { SvgFromRawSourceProps } from './SvgViewer';

/**
 * determines various settings to specify how the component will operate
 * 
 * @param wrapperRef
 * @param svgContainerId
 */
export type ZoomComponentProps = {
    wrapperRef: React.RefObject<ReactZoomPanPinchRef>
    svgContainerId: string
    containerId?: string
    loading?: boolean
    initialScale?: number
    minScale?: number
    maxScale?: number
    wheel?: { step?: number, disabled?: boolean }
    zoomAnimation?: { animationTime?: number }
    disableDoubleClick?: boolean
    wrapperStyle?: React.CSSProperties
    /** Accepts either a a custom toolbar or a boolean. If true, default toolbar is displayed.*/
    toolbar?: boolean | React.ReactElement
}

/**
 * determines where the component is pointed to
 */
export type ZoomComponentRef = {
    zoomToElement: (svg: HTMLElement) => void
}

const StyledToolbar = styled('div')({
    position: "absolute",
    right: "0",
    bottom: "10px",
    left: "0",
    textAlign: "center",
    margin: "8px",

    "&>button:not(:last-child)": {
        marginRight: "8px"
    },

    '@media(max - width: 400px)': {
        '&> button': {
            minWidth: "50px"
        }
    }
})

const StyledContainer = styled('div')({
    width: '100%',
    height: '100%',
    boxSizing: "border-box",
    cursor: 'pointer',
    position: 'relative'
})

const StyledLoadingContainer = styled('div')({
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
})

/**
 * Enables the user to Zoom in and Zoom out in a provided component
 * 
 * @param children uses an SVG Component as refernce to work on
 * @returns ForwardRefExoticComponent 
 */
export const ZoomComponent = React.forwardRef<ZoomComponentRef, ZoomComponentProps & { children: React.ReactElement<SvgFromRawSourceProps> }>(function ZoomComponent({ wrapperRef, ...props }, ref) {
    const { t } = useTypedTranslation();
    React.useImperativeHandle(ref, () => ({
        zoomToElement: (svg) => zoomToElement(wrapperRef.current, svg, props.svgContainerId)
    }), [props.svgContainerId, wrapperRef]);

    return (
        <StyledContainer id={props.containerId}>
            {
                props.loading &&
                <StyledLoadingContainer>
                    <CircularProgress disableShrink size={100} />
                </StyledLoadingContainer>
            }

            <TransformWrapper
                ref={wrapperRef}
                initialScale={props.initialScale}
                minScale={props.minScale}
                maxScale={props.maxScale}
                wheel={props.wheel}
                zoomAnimation={props.zoomAnimation}
                doubleClick={{ disabled: props.disableDoubleClick }}
            >
                <TransformComponent wrapperStyle={props.wrapperStyle}>
                    {props.children}
                </TransformComponent>

                {
                    props.toolbar && props.toolbar === true &&
                    <StyledToolbar>
                        <Tooltip title={t("zoomComponent", "zoomOut")}>
                            <Button onClick={() => wrapperRef.current?.zoomOut()} variant='contained'>
                                <ZoomOutIcon />
                            </Button>
                        </Tooltip>
                        <Tooltip title={t("zoomComponent", "zoomToElement")}>
                            <Button onClick={() => wrapperRef.current?.zoomToElement(props.svgContainerId)} variant='contained'>
                                <FullscreenIcon />
                            </Button>
                        </Tooltip>
                        <Tooltip title={t("zoomComponent", "zoomIn")}>
                            <Button onClick={() => wrapperRef.current?.zoomIn()} variant='contained'>
                                <ZoomInIcon />
                            </Button>
                        </Tooltip>
                    </StyledToolbar>
                }
                {
                    props.toolbar && React.isValidElement(props.toolbar) && <>
                        {props.toolbar}
                    </>
                }
            </TransformWrapper>
        </StyledContainer>
    );
});

//#region helper functions

function zoomToElement(wrapperRef: ReactZoomPanPinchRef | null, svg: HTMLElement, svgContainerId: string) {
    if (!wrapperRef) { return; }

    const component = wrapperRef.instance.wrapperComponent;
    const svgContainer = document.getElementById(svgContainerId);

    if (component && svgContainer) {
        const componentRect = component.getBoundingClientRect();
        const svgContainerRect = svgContainer.getBoundingClientRect();
        const rect = svg.getBoundingClientRect();

        const scale = calculateScale(componentRect, rect, wrapperRef.state.scale);

        const x = ((svgContainerRect.x - rect.x) * scale) / wrapperRef.state.scale;
        const y = ((svgContainerRect.y - rect.y) * scale) / wrapperRef.state.scale;

        const newPositionX = x + (componentRect.width) / 2;
        const newPositionY = y + (componentRect.height) / 2;

        wrapperRef.setTransform(newPositionX, newPositionY, scale);
    }
}

function calculateScale(componentRect: DOMRect, svgRect: DOMRect, currentScale: number): number {
    let scale = 1;
    if (componentRect.height < componentRect.width) {
        //landscape -> 1/4th of height
        scale = (componentRect.height * 0.25) / (svgRect.height / currentScale);
    }
    else {
        //portrait -> 1/4th of width
        scale = (componentRect.width * 0.25) / (svgRect.width / currentScale);
    }

    return scale;
}

//#endregion