import { Delete } from '@mui/icons-material';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import ImageIcon from '@mui/icons-material/Image';
import { Button, ButtonGroup, FormControlLabel, Switch } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { fabric } from 'fabric';
import { debounce } from 'lodash';
import React, { useEffect, useState, useMemo } from 'react';
import { withCanvasContext } from '../FabricContext';
import { readFileAsDataURL } from '../fileHelpers';
import { ProgressCanvas } from '../ProgressCanvas';

export const PropertyGridEditor = withCanvasContext(({ canvas }) => {
    const [currentObject, setCurrentObject] = useState<(fabric.Object & {controlType: string }) | undefined>()
    const [, setCounter] = useState(0)

    useEffect(() => {
        canvas.on('selection:created', (e) => {
            const currentObject = e.selected?.[0] as (fabric.Object & { controlType: string }) | undefined
            setCurrentObject(currentObject)
            setCounter(c => ++c);
        })

        canvas.on('selection:updated', (e) => {
            const currentObject = e.selected?.[0] as (fabric.Object & { controlType: string }) | undefined
            setCurrentObject(currentObject)
            setCounter(c => ++c);
        })

        canvas.on('selection:cleared', () => {
            // setContextMenu(null)
            setCurrentObject(undefined)
            setCounter(c => ++c);
        })

        canvas.on('object:modified', () => {
            canvas.renderAll()
            setCounter(c => ++c);
        })

        canvas.on('initialized', () => {
            setCounter(c => ++c);
        })
    }, [canvas])

    const debouncedFireModified = useMemo(() => {
        return debounce(() => {
            canvas.fire('object:modified');
            canvas.renderAll();
        }, 250)
    }, [canvas]);

    useEffect(() => {
        return () => {
            debouncedFireModified.cancel();
        };
    }, [debouncedFireModified]);

    const formats = useMemo(() => ['bold', 'italic', 'underline'], []);

    const editableObject: fabric.Object | ProgressCanvas = useMemo(() => currentObject ?? canvas, [canvas, currentObject])

    return <div>
        {
            !!editableObject && !editableObject.group ? <div tabIndex={0} style={{ margin: 12 }}>

                {
                    (editableObject).getProperties().map((prop) => {

                        if (prop.type === 'string') {
                            return (
                                <div key={prop.propertyName + currentObject?.["controlType"]} style={{ margin: '5px auto' }}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label={prop.displayName}
                                        value={editableObject.get(prop.propertyName)}
                                        onChange={
                                            (event: { target: { value: string; }; }) => {
                                                editableObject.update(prop.propertyName, event.target.value);
                                                setCounter(c => ++c);
                                                debouncedFireModified();
                                            }
                                        }
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                            );
                        } else if (prop.type === 'number') {
                            return (
                                <div key={prop.propertyName + currentObject?.["controlType"]} style={{ margin: '5px auto' }}>
                                    <NullableNumberField
                                        setCounter={setCounter}
                                        editableObject={editableObject}
                                        label={prop.displayName}
                                        debouncedFireModified={debouncedFireModified}
                                        propertyName={prop.propertyName}
                                    />
                                </div>
                            );
                        } else if (prop.type === 'boolean') {
                            return (
                                <div key={prop.propertyName + currentObject?.["controlType"]} style={{ margin: '5px auto' }}>
                                    <FormControlLabel
                                        label={prop.displayName}
                                        labelPlacement="start"
                                        style={{ margin: 0 }}
                                        control={
                                            <Switch
                                                checked={editableObject.get(prop.propertyName) as boolean} onChange={
                                                    (event) => {
                                                        editableObject.update(prop.propertyName, event.target.checked);
                                                        setCounter(c => ++c);
                                                        debouncedFireModified();
                                                    }
                                                }
                                            />
                                        }
                                    />
                                </div>
                            );
                        }
                        else if (prop.type === 'italic') {
                            return (
                                <div key={prop.propertyName + currentObject?.["controlType"]} style={{ margin: '5px 5px', display: 'inline-block' }}>
                                    <ToggleButtonGroup
                                        size="small"
                                        value={formats}
                                        onChange={
                                            () => {
                                                if (editableObject.get(prop.propertyName) === 'italic') {
                                                    editableObject.update(prop.propertyName, 'normal')
                                                }
                                                else if (editableObject.get(prop.propertyName) === 'normal') {
                                                    editableObject.update(prop.propertyName, 'italic')
                                                }
                                                setCounter(c => ++c);
                                                debouncedFireModified();
                                            }
                                        }
                                    >
                                        <ToggleButton value="italic" aria-label="italic">
                                            <FormatItalicIcon />
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                            );
                        }
                        else if (prop.type === 'bold') {
                            return (
                                <div key={prop.propertyName + currentObject?.["controlType"]} style={{ margin: '5px 5px', display: 'inline-block' }}>
                                    <ToggleButtonGroup
                                        size="small"
                                        value={formats}
                                        onChange={
                                            () => {
                                                if (editableObject.get(prop.propertyName) === 'bold') {
                                                    editableObject.update(prop.propertyName, 'normal')
                                                }
                                                else if (editableObject.get(prop.propertyName) === 'normal') {
                                                    editableObject.update(prop.propertyName, 'bold')
                                                }
                                                setCounter(c => ++c);
                                                debouncedFireModified();
                                            }
                                        }
                                    >
                                        <ToggleButton value="bold" aria-label="bold">
                                            <FormatBoldIcon />
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                            );
                        }
                        else if (prop.type === 'underlined') {
                            return (
                                <div key={prop.propertyName + currentObject?.["controlType"]} style={{ margin: '5px 5px', display: 'inline-block' }}>
                                    <ToggleButtonGroup
                                        size="small" sx={{ backgroundColor: "#EBEBEB", color: "black" }}
                                        value={formats}
                                        onChange={
                                            () => {
                                                if (editableObject.get(prop.propertyName) === true) {
                                                    editableObject.update(prop.propertyName, false)
                                                }

                                                else if (editableObject.get(prop.propertyName) === false) {
                                                    editableObject.update(prop.propertyName, true)
                                                }
                                                setCounter(c => ++c);
                                                debouncedFireModified();
                                            }
                                        }
                                    >
                                        <ToggleButton value="underlined" aria-label="underlined" sx={{ color: "black" }}>
                                            <FormatUnderlinedIcon />
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                            );
                        }
                        else if (prop.type === 'originX') {
                            const handleOriginX = (
                                event: React.MouseEvent<HTMLElement>,
                                newOriginX: string | null,
                            ) => {
                                setCounter(c => ++c);
                                debouncedFireModified();
                                editableObject.update(prop.propertyName, newOriginX);
                            };
                            return (
                                <div key={prop.propertyName + currentObject?.["controlType"]} style={{ margin: '15px 5px', display: 'block' }}>
                                    
                                    <ToggleButtonGroup
                                        size="small" sx={{ backgroundColor: "#EBEBEB", color: "black" }}
                                        value={editableObject.get(prop.propertyName) as string}
                                        exclusive 
                                        onChange={handleOriginX}
                                    > 
                                        <ToggleButton value="left" aria-label="originX-left" sx={{ color: "black" }}>
                                            <FormatAlignLeftIcon />
                                        </ToggleButton>
                                        <ToggleButton value="center" aria-label="originX-center" sx={{ color: "black" }} >
                                            <FormatAlignCenterIcon />
                                        </ToggleButton>
                                        <ToggleButton value="right" aria-label="originX-right" sx={{ color: "black" }} >
                                            <FormatAlignRightIcon />
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                            );
                        }
                        else if (prop.type === 'font') {
                            return (<FormControl
                                key={prop.propertyName + currentObject?.["controlType"]} sx={{ my: '5px' }} fullWidth
                                size="small"
                            >
                                <InputLabel id="demo-simple-select-label">Font</InputLabel>
                                <Select
                                    labelId="fontPicker"
                                    id="FontPicker"
                                    value={editableObject.get(prop.propertyName) as string}
                                    label="Font"
                                    fullWidth
                                    onChange={
                                        (event: SelectChangeEvent) => {
                                            editableObject.update(prop.propertyName, event.target.value);
                                            setCounter(c => ++c);
                                            debouncedFireModified();
                                        }
                                    }
                                >
                                    <MenuItem value="Arial">Arial</MenuItem>
                                    <MenuItem value="Monospace">Monospace</MenuItem>
                                    <MenuItem value="Brush Script MT">Brush Script MT</MenuItem>
                                    <MenuItem value="Courier New">Courier New</MenuItem>
                                    <MenuItem value="Verdana">Verdana</MenuItem>
                                    <MenuItem value="Garamond">Garamond </MenuItem>
                                    <MenuItem value="Georgia">Georgia </MenuItem>
                                    <MenuItem value="Tahoma">Tahoma </MenuItem>
                                    <MenuItem value="Trebuchet MS">Trebuchet MS</MenuItem>
                                    <MenuItem value="Times New Roman">Times New Roman</MenuItem>
                                </Select>
                            </FormControl>
                            );
                        }
                        else if (prop.type === 'color') {
                            return (
                                <div key={prop.propertyName + currentObject?.["controlType"]} style={{ margin: '5px auto', display: 'flex', alignItems: 'center' }}>
                                    <label htmlFor={prop.propertyName}>
                                        {prop.displayName}
:
                                        {' '}
                                    </label>
                                    <input
                                        id={prop.propertyName}
                                        style={{ marginLeft: 10, height: "40px", width: "50px" }}
                                        type="color"
                                        value={editableObject.get(prop.propertyName) as readonly string[]}
                                        onChange={
                                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                                editableObject.update(prop.propertyName, e.target.value);
                                                setCounter(c => ++c);
                                                debouncedFireModified();
                                            }
                                        }
                                    />

                                </div>
                            );
                        } else if (prop.type === 'string[]') {
                            return (
                                <FormControl
                                    key={prop.propertyName + currentObject?.["controlType"]} sx={{ my: '5px' }} fullWidth
                                    size="small"
                                >
                                    <InputLabel id="demo-simple-select-label">{prop.displayName}</InputLabel>
                                    <Select
                                        value={editableObject.get(prop.propertyName) as string ?? ""}
                                        label={prop.displayName}
                                        fullWidth
                                        onChange={
                                            (event: SelectChangeEvent) => {
                                                editableObject.update(prop.propertyName, event.target.value);
                                                setCounter(c => ++c);
                                                debouncedFireModified();
                                            }
                                        }
                                    >
                                        {
                                            prop.elements.map((elem, i) =>
                                                <MenuItem key={elem + "_" + i} value={elem}>{elem}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            );
                        } else if (prop.type === 'image') {
                            return (
                                <div key={prop.propertyName + currentObject?.["controlType"]} style={{ margin: '5px auto' }}>
                                    <FormControlLabel
                                        label={prop.displayName}
                                        labelPlacement="start"
                                        style={{ margin: 0 }}
                                        control={
                                            <ButtonGroup variant="outlined" size="small" style={{ marginLeft: 10 }}>
                                                <Button
                                                    onClick={
                                                        async () => {
                                                            const pickerOpts = {
                                                                types: [
                                                                    {
                                                                        description: 'Image',
                                                                        accept: {
                                                                            'image/*': ['.png', '.jpg', '.jpeg', '.svg']
                                                                        }
                                                                    },
                                                                ],
                                                                excludeAcceptAllOption: true,
                                                                multiple: false
                                                            };

                                                            const [fileHandle] = await window.showOpenFilePicker(pickerOpts)

                                                            const file = await fileHandle.getFile() as File

                                                            const base64 = await readFileAsDataURL(file);

                                                            editableObject.update(prop.propertyName, base64);
                                                            setCounter(c => ++c);
                                                            debouncedFireModified();
                                                        }
                                                    }
                                                >
                                                    {
                                                        editableObject.get(prop.propertyName) ?
                                                            <img
                                                                height="24px" width="24px" src={editableObject.get(prop.propertyName) as string}
                                                                alt={prop.propertyName + "_Image"}
                                                            />
                                                            :
                                                            <ImageIcon />
                                                    }
                                                </Button>
                                                {
                                                    prop.optional &&
                                                    <Button
                                                        disabled={editableObject.get(prop.propertyName) === undefined}
                                                        onClick={
                                                            () => {
                                                                editableObject.update(prop.propertyName, undefined);
                                                                setCounter(c => ++c);
                                                                debouncedFireModified();
                                                            }
                                                        }
                                                    >
                                                        <Delete />
                                                    </Button>
                                                }
                                            </ButtonGroup>
                                        }
                                    />
                                </div>
                            );
                        }
                        return (
                            <div key={prop.propertyName}>
                                Not supported 
                                {' '}
                                {prop.type}
                            </div>
                        )
                    }
                    )
                }

            </div>
                :
                <div style={{ display: 'flex', flexDirection: 'column' }}></div>
        }
    </div>
})

type NullableNumberFieldProps = {
    label: string,
    debouncedFireModified: () => void | undefined,
    editableObject: fabric.Object | ProgressCanvas,
    propertyName: string,
    setCounter: (value: React.SetStateAction<number>) => void
}

const NullableNumberField: React.FC<NullableNumberFieldProps> = ({ debouncedFireModified, label, editableObject, propertyName, setCounter }) => {
    const value = isNaN(parseFloat(editableObject.get(propertyName) as string)) ? null : parseFloat(editableObject.get(propertyName) as string);

    // refresh the value on unmount if needed
    useEffect(() => {
        return () => {
            const value = isNaN(parseFloat(editableObject.get(propertyName) as string)) ? null : parseFloat(editableObject.get(propertyName) as string);
            if (value === null) {
                editableObject.update(propertyName, 0);
                debouncedFireModified();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <TextField
        fullWidth
        size="small"
        type="number"
        label={label}
        value={value !== null ? value : ''}
        onChange={
            (e) => {
                const newValue = parseFloat(e.target.value);
                if (!isNaN(newValue)) {
                    editableObject.update(propertyName, newValue);
                } else {
                    editableObject.update(propertyName, null);
                }
                setCounter(c => ++c);
                debouncedFireModified();
            }
        }
        onBlur={
            () => {
                if (value === null) {
                    editableObject.update(propertyName, 0);
                    debouncedFireModified();
                }
            }
        }
    />

}