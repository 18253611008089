import { DecryptionRequest, EncryptionRequest } from '@/definitions/autogenerated/types';
import { Clear, ContentCopy } from '@mui/icons-material';
import { Button, IconButton, InputAdornment, Paper, TextField } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { api, useTypedTranslation } from '../../definitions';

const displayPasswordStyle: React.CSSProperties = {
    overflowWrap: 'break-word',
}

const divStyleBody: React.CSSProperties = {
    width: "100%",
    padding: "20px",
    paddingTop: 0
}

const Password: React.FC = () => {

    const { t } = useTypedTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [inputToEncrypt, setInputToEncrypt] = useState("");
    const [inputToDecrypt, setInputToDecrypt] = useState("");
    const [encryptedPwd, setEncryptedPwd] = useState("");
    const [decryptedPwd, setDecryptedPwd] = useState("");

    const handleInputToEncrypt = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setInputToEncrypt(String(e.target.value))
    }, [])

    const handleInputToDecrypt = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setInputToDecrypt(String(e.target.value))
    }, [])

    const handleClearInputToEncrypt = useCallback(() => {
        setInputToEncrypt('')
        setEncryptedPwd('')
    }, [])

    const handleClearInputToDecrypt = useCallback(() => {
        setInputToDecrypt('')
        setDecryptedPwd('')
    }, [])

    const encrypt = useCallback(() => {
        const request: EncryptionRequest = { InputToEncrypt: inputToEncrypt }

        axios.post<string>(api.encryptPassword(), request).then(response => {
            setEncryptedPwd(response.data)
        }).catchError((e) => enqueueSnackbar(e.Description, { variant: "error" }))
    }, [enqueueSnackbar, inputToEncrypt])

    const decrypt = useCallback(() => {
        const request: DecryptionRequest = { InputToDecrypt: inputToDecrypt }

        axios.post<string>(api.decryptPassword(), request).then(response => {
            setDecryptedPwd(response.data)
        }).catchError((e) => enqueueSnackbar(e.Description, { variant: "error" }))
    }, [enqueueSnackbar, inputToDecrypt])

    const copyOnClick = useCallback((text: string) => {
        navigator.clipboard.writeText(text);
    }, [])

    return (
        <Paper style={{ margin: "20px", marginBottom: "0px" }} elevation={0} variant="outlined">
            <div style={{ padding: "20px", paddingTop: 0 }}>
                <h3 >{t("tools", "password", "descriptionTitle")}</h3>
                {t("tools", "password", "description") + " "}
                {' '}
                {t("tools", "password", "passwordObject2")}
            </div>
            <div style={divStyleBody}>
                <h4>{t("tools", "password", "encrypt")}</h4>
                <TextField
                    type="string" onChange={handleInputToEncrypt} value={inputToEncrypt}
                    style={{ marginRight: 10, maxWidth: "500px" }} fullWidth autoComplete="off"
                    size="small" variant="outlined" placeholder={t("tools", "password", "placeholderEncrypt")}
                    InputProps={
                        {
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={handleClearInputToEncrypt}>
                                    <Clear />
                                </IconButton>
                            </InputAdornment>
                        }
                    }
                />
                <Button
                    disabled={!inputToEncrypt} onClick={encrypt} value={encryptedPwd}
                    style={{ height: "40px" }} variant="outlined"
                >
                    {t("tools", "password", "encrypt")}
                </Button>
                {
                    encryptedPwd && <p style={displayPasswordStyle}>
                        {encryptedPwd}
                        <IconButton onClick={() => copyOnClick(encryptedPwd)}>
                            <ContentCopy />
                        </IconButton>
                    </p>
                }
            </div>

            <div style={divStyleBody}>
                <h4>{t("tools", "password", "decrypt")}</h4>
                <TextField
                    type="string" onChange={handleInputToDecrypt} value={inputToDecrypt}
                    style={{ marginRight: 10, maxWidth: "500px" }} fullWidth autoComplete="off"
                    size="small" variant="outlined" placeholder={t("tools", "password", "placeholderDecrypt")}
                    InputProps={
                        {
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={handleClearInputToDecrypt}>
                                    <Clear />
                                </IconButton>
                            </InputAdornment>
                        }
                    }
                />
                <Button
                    disabled={!inputToDecrypt} onClick={decrypt} value={decryptedPwd}
                    style={{ height: "40px" }} variant="outlined"
                >
                    {t("tools", "password", "decrypt")}
                </Button>
                {
                    decryptedPwd && <p style={displayPasswordStyle}>
                        {decryptedPwd}
                        <IconButton onClick={() => copyOnClick(decryptedPwd)}>
                            <ContentCopy />
                        </IconButton>
                    </p>
                }
            </div>
        </Paper>
    )
}

export default Password