import { minWidthColumnProps } from '@/components/CustomGridComponents';
import { createGithubRelease, fetchGithubReleases } from '@/store/data/github/actions';
import { OpenInNew } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, Container, IconButton, styled, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { DataGridPremium, GridColDef, GridInitialState, GridRenderCellParams, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { AutoRefreshButton, Logger, nameof } from '@progress/base-ui';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { defaultGridProps } from '../components/CustomGridComponents';
import { useTypedTranslation } from '../definitions';
import { GithubRelease, Release } from '../definitions/autogenerated/types';
import { useReduxDispatch, useTypedSelector } from '../store/configureStore';

const StyledContainer = styled(Container)(({ theme }) => ({
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: "2100px !important"
}));

const RedText = styled(Container)({
    color: "#ff3d00",
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
});

const GreenText = styled(Container)(() => ({
    color: "#00a152",
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
}));

const rootStyle: React.CSSProperties = {
    margin: "0",
    textAlign: "center"
}

const divStyle: React.CSSProperties = {
    width: "100%",
}

const divStyleHead: React.CSSProperties = {
    display: "inline-block",
    width: "100%",
    alignItems: "relative",
    margin: "1"
}

const defaultSortModel: GridSortModel = [
    { field: nameof<Release>('ApplicationType'), sort: 'asc' },
];

const copyText = (text: string) => {
    navigator.clipboard.writeText(text);
}

const handleRenderCellState = ({ row }: GridRenderCellParams<GithubRelease, GithubRelease>, maxState: number) => {
    return (
        <div>
            {
                row.ReleaseState < maxState ?
                    <RedText >
                        {row.ReleaseState + "/" + maxState}
                    </RedText>
                    :
                    <GreenText >
                        {row.ReleaseState + "/" + maxState}
                    </GreenText>
            }
        </div>
    )
};

const handleRenderCellCreator = ({ row }: GridRenderCellParams<GithubRelease, GithubRelease>) => {
    return (
        <div>
            {row.ReleaseCreator}
        </div>
    )
};

const handleRenderCellTag = ({ row }: GridRenderCellParams<GithubRelease, GithubRelease>) => {
    return (
        <div >
            {row.ReleaseTag}

            <IconButton
                color='primary'
                size="small"
                onClick={
                    () => {
                        copyText(row.ReleaseTag);
                    }
                }
            >
                <ContentCopyIcon />
            </IconButton>
        </div>
    )
};

const handleRenderCellLink = ({ row }: GridRenderCellParams<GithubRelease, GithubRelease>) => {
    return (
        <div>
            <IconButton href={"https://github.com/ProgressGroupDev/Projekte/actions/runs/" + row.ReleaseId} target="_blank" size="medium" disabled= {row.ReleaseId==null}>
                <OpenInNew/>
            </IconButton>
        </div>
    )
};

const headerHeight = 42;

export default function GithubList() {
    const rows = useTypedSelector(state => state.data.github.releases);
    const maxState = useTypedSelector(state => state.data.github.maxState);
    const [isLoading, setIsLoading] = useState(false);

    const widthLargerThan900 = useMediaQuery("(min-width:900px)");
    const apiRef = useGridApiRef();
    const { t } = useTypedTranslation();
    const dispatch = useReduxDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const getRowHeight = useCallback(() => widthLargerThan900 ? 32 : 'auto', [widthLargerThan900]);

    const initialState = useMemo<GridInitialState>(() => ({
        sorting: {
            sortModel: defaultSortModel
        },
    }), [])

    const columns = useMemo<GridColDef<GithubRelease>[]>(() => [
        { ...minWidthColumnProps, field: 'ReleaseTag', headerName: t("main", "releaseTag"), renderCell: handleRenderCellTag },
        { ...minWidthColumnProps, field: 'ReleaseState', headerName: t("github", "releaseState"), description: t("github", "releaseStateDescription"), renderCell: (params) => handleRenderCellState(params, maxState) },
        { ...minWidthColumnProps, field: 'ReleaseCreator', headerName: t("github", "releaseCreator"), renderCell: handleRenderCellCreator },
        { ...minWidthColumnProps, field: 'ReleaseTagLink', headerName: t("github", "pipelineLink"), renderCell: handleRenderCellLink }
    ], [t, maxState]);

    const loadGithubReleases = useCallback(() => {
        setIsLoading(true);

        dispatch(fetchGithubReleases())
            .unwrap()
            .catchError((e) => {
                enqueueSnackbar(t("main", "loadingError"), { variant: 'error' })
                Logger.error(e.Description)
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [dispatch, enqueueSnackbar, t]);

    useEffect(() => {
        loadGithubReleases();
    }, [loadGithubReleases]);

    const newGithubRelease = useCallback(() => {
        dispatch(createGithubRelease())
            .unwrap()
            .then(() => {
                enqueueSnackbar(t("github", "createSuccess"), { variant: 'success' });
                loadGithubReleases();
            })
            .catchError(() => {
                enqueueSnackbar(t("github", "createError"), { variant: 'error' })
            });
    }, [dispatch, enqueueSnackbar, t, loadGithubReleases]);

    return (
        (<StyledContainer maxWidth="lg" className="container">
            <div className='container' style={rootStyle}>
                <div className='container' style={divStyle}>

                    <div style={divStyleHead}>

                        <Box
                            m={1}
                            //margin
                            display="flex"
                            justifyContent="space-between"
                            alignItems="flex-end"

                        >
                            <h1 className="containerTitle">{t("github", "title")}</h1>
                            <Box>
                                <AutoRefreshButton
                                    variant="outlined"
                                    loading={isLoading}
                                    onRefresh={loadGithubReleases}
                                    onClick={loadGithubReleases}
                                    refreshInterval={30000}
                                >
                                    {t("main", "refresh")}
                                </AutoRefreshButton>

                                <Button
                                    color='primary'
                                    size="small"
                                    variant="contained"
                                    style={{ marginLeft: 10, height: 36.5 }}

                                    onClick={newGithubRelease}
                                >
                                    {t("releaseList", "createRelease")}
                                </Button>
                            </Box>
                        </Box>
                    </div>

                    <Paper className="container">
                        <DataGridPremium
                            {...defaultGridProps}

                            className="progress-table"

                            key={widthLargerThan900.toString()}
                            rows={rows}
                            columns={columns}

                            apiRef={apiRef}

                            initialState={initialState}

                            getRowId={getRowId}

                            columnHeaderHeight={headerHeight}
                            getRowHeight={getRowHeight}

                            loading={isLoading}

                            slotProps={{ loadingOverlay: {variant: "linear-progress"} }}
                        />
                    </Paper>
                </div>
            </div>
        </StyledContainer>)
    );
}

const getRowId = (release: GithubRelease) => `${release.ReleaseTag}`
