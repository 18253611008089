import { PaletteColor } from '@mui/material';
import { grey } from '@mui/material/colors';

//When adding new colors, refer to these palettes if possible
//Make sure colors are specified as three-channel hex strings
//https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors

//----------------------------
// Base Colors
//----------------------------

export const ProgressBlue: PaletteColor = {
    light: '#33A1D5',
    main: '#008ACB',
    dark: '#00608E',
    contrastText: '#ffffff',
}

export const ProgressGrey: PaletteColor = {
    light: '#6E6D6D',
    main: '#4A4949',
    dark: '#333333',
    contrastText: '#ffffff',
}

export const ProgressYellow: PaletteColor = {
    light: '#FFE666',
    main: '#FFDD00',
    dark: '#E6BF00',
    contrastText: ProgressGrey.main,
}

export const SentColor: PaletteColor = {
    main: "#90CAF9",
    light: "#90CAF9",
    dark: "#90CAF9",
    contrastText: ProgressGrey.dark
}

export const ProducedColor: PaletteColor = {
    main: "#AED581",
    light: "#AED581",
    dark: "#AED581",
    contrastText: ProgressGrey.dark
}

export const AssemblyColor: PaletteColor = {
    main: "#FFD54F",
    light: "#FFD54F",
    dark: "#FFD54F",
    contrastText: ProgressGrey.dark
}

export const BendingColor: PaletteColor = {
    light: "#F9BF90",
    main: "#F9BF90",
    dark: "#F9BF90",
    contrastText: ProgressGrey.dark
}

export const SawingColor: PaletteColor = {
    light: "#fff59d",
    main: "#fff59d",
    dark: "#fff59d",
    contrastText: ProgressGrey.dark
}

export const ThreadingColor: PaletteColor = {
    light: "#b39ddb",
    main: "#b39ddb",
    dark: "#b39ddb",
    contrastText: ProgressGrey.dark
}

//MUI V4 Default color
export const DefaultColor: PaletteColor = {
    light: grey[200],
    main: grey[300],
    dark: grey[400],
    contrastText: '#000'
}

//----------------------------
// Layout Colors
//----------------------------

export type ColorScheme = {
    activeText: string,
    inactiveText: string,
    background: string,
    accent: string
}

export const greyScheme: ColorScheme = {
    activeText: ProgressGrey.contrastText,
    inactiveText: "#EEEEEE", // material grey[200]
    background: ProgressGrey.main,
    accent: ProgressBlue.main
}

export const blueScheme: ColorScheme = {
    activeText: ProgressBlue.contrastText,
    inactiveText: "#EEEEEE", // material grey[200]
    background: ProgressBlue.main,
    accent: ProgressGrey.main
}

export const yellowScheme: ColorScheme = {
    activeText: ProgressYellow.contrastText,
    inactiveText: "#616161", // material grey[700]
    background: ProgressYellow.main,
    accent: ProgressBlue.main
}

export const devScheme: ColorScheme = {
    ...greyScheme,
    background: "#B71C1C" // material red[900]
}

//----------------------------
// Helpers
//----------------------------

export const percentToHexOpacity = (n: number) => Math.trunc((n / 100) * 255).toString(16);

export const stringToHashColor = (string: string) => {
    //basic modulo hash
    let sum = 0;
    for (let i = 0; i < string.length; i++) {
        sum += string.charCodeAt(i); //ascii uppercase range
    }
    const index = sum % 360;
    return hslToHex(index, 80, 35); //low lightness for font contrast
}

function hslToHex(h: number, s: number, l: number) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = (n: number) => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
}

