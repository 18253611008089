import { Box, Container, Paper, styled } from '@mui/material';
import React from 'react';
import { useTypedTranslation } from '../definitions';
import Password from './tools/Password';
import Email from './tools/Email';

const StyledContainer = styled(Container)(({ theme }) => ({
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: "2100px !important"
}));

const rootStyle: React.CSSProperties = {
    margin: "0",
}

const divStyle: React.CSSProperties = {
    width: "100%"
}

const divStyleHead: React.CSSProperties = {
    display: "inline-block",
    width: "100%",
    alignItems: "relative",
    margin: "1"
}

const Tools: React.FC = () => {

    const { t } = useTypedTranslation();

    return (
        <StyledContainer maxWidth="lg" className="container">
            <div className='container' style={rootStyle}>
                <div className='container' style={divStyle}>
                    <div style={divStyleHead}>
                        <Box
                            m={1}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="flex-end"
                        >
                            <h1 className="containerTitle">{t("tools", "title")}</h1>
                        </Box>
                    </div>
                    <Paper className="container">
                        <Password />
                        <Email />
                    </Paper>
                </div>
            </div>
        </StyledContainer>
    )
}

export default Tools