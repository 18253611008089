import { BaseLocation, BaseLocationOptions } from "./baseLocation";
import * as d3 from 'd3';

export class StockyardLocation extends BaseLocation {
    controlType = 'StockyardLocation';

    constructor(options?: BaseLocationOptions) {
        super({
            ...options,
            // overrides
            ...{
                fill: '#86ACB7',
                stroke: '#000000',
                strokeWidth: 0.75,
                objectCaching: false,
                cornerColor: "black",
                borderColor: "black",
            }
        });
    }

    renderToSVG(svgElement: SVGSVGElement) {
        const group = d3
            .select(svgElement)
            .append("g")
            .attr("type", this.controlType ?? '')
            .attr("id", uniqueId())
            .attr("data-type", this.controlType ?? '')
            .attr("locationName", this.locationName ?? '???')
            .attr("data-location-name", this.locationName ?? '???')
            .attr("transform", "translate(" + this.left + "," + this.top + ")")
            .attr("scale", "1");

        group
            .append("rect")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", this.width ?? 0)
            .attr("height", this.height ?? 0)
            .attr("style", "fill:" + this.fill)
            .attr("stroke", 0);

        group
            .append("text")
            .text(this.locationName ?? '')
            .attr("translationkey", "")
            .attr("x", (this.width ?? 0) / 2)
            .attr("y", (this.height ?? 0) / 2 - 5)
            .attr("fill", "white")
            .attr("font-size", "10px")
            .attr("text-anchor", "middle")
            .attr("alignment-baseline", "hanging")
            .style("font", '12px sans-serif');

        return svgElement;
    }

}

const uniqueId = () => {
    const dateString = Date.now().toString(36);
    const randomness = Math.random().toString(36).substr(2);
    return dateString + randomness;
};