import { fabric } from "fabric";
import * as d3 from "d3";
import { EditableProperty } from './objects'
import { Logger } from "@progress/base-ui";

export class Ellipse extends fabric.Ellipse {
    controlType = 'Ellipse';
    isDirty = false

    constructor(options: fabric.IEllipseOptions) {
        super({
            //default
            ...{
                rx: 50,
                ry: 25,
                left: 60,
                top: 35,
                fill: '#ff8C00',
                stroke: '#000000',
                strokeWidth: 0.75,
                objectCaching: false,
                cornerColor: "black",
                borderColor: "black",
                originX: "center",
                originY: "center"
            },
            ...options
        });

        this.on('scaling', () => {

            const w = (this.width ?? 0) * (this.scaleX ?? 0);
            const h = (this.height ?? 0) * (this.scaleY ?? 0);
            this.height = h;
            this.width = w;
            this.rx = (this.rx ?? 0) * (this.scaleX ?? 0)
            this.ry = (this.ry ?? 0) * (this.scaleY ?? 0)
            this.scaleX = 1;
            this.scaleY = 1;
        })
    }

    update<K extends keyof this>(propertyName: K, value: this[K] | ((value: this[K]) => this[K])): fabric.Object {
        Logger.debug(propertyName, value)
        this.set(propertyName, value);
        this.set('dirty', true)
        this.isDirty = true;
        this.canvas?.fire('object:modified')
        return this;
    }

    getProperties(): EditableProperty[] {
        return [
            { propertyName: "left", displayName: "X", type: "number" },
            { propertyName: "top", displayName: "Y", type: "number" },
            { propertyName: "rx", displayName: "Radius X", type: "string" },
            { propertyName: "ry", displayName: "Radius Y", type: "string" },
            { propertyName: "strokeWidth", displayName: "Stroke width", type: "number" },
            { propertyName: "stroke", displayName: "Stroke color", type: "color" },
            { propertyName: "fill", displayName: "Fill color", type: "color" },
        ];
    }

    serialize() {
        const top = this.top ?? 0;
        const left = this.left ?? 0;

        const base = {
            type: this.controlType,
            rx: this.rx ?? 0,
            ry: this.ry ?? 0,
            strokeWidth: this.strokeWidth,
            fill: this.fill,
            stroke: this.stroke
        }

        if (this.group) {
            const groupTop = this.group.top ?? 0;
            const groupLeft = this.group.left ?? 0;
            const groupHeight = this.group.height ?? 0;
            const groupWidth = this.group.width ?? 0;

            return {
                ...base,
                top: top + groupTop + groupHeight / 2,
                left: left + groupLeft + groupWidth / 2,
            }
        } else {
            return {
                ...base,
                top: top,
                left: left,
            }
        }
    }

    renderToSVG(svgElement: SVGSVGElement) {
        d3.select(svgElement)
            .append("ellipse")
            .attr("cx", this.left ?? 0)
            .attr("cy", this.top ?? 0)
            .attr("rx", this.rx ?? 0)
            .attr("ry", this.ry ?? 0)
            .style("fill", (this.fill ?? '') as string)
            .style("stroke", this.stroke ?? "#000")
            .style("stroke-width", this.strokeWidth ?? 1)

        return svgElement;
    }
}