import { initializeLocalization, ProgressApp, useTypedTranslationHook } from '@progress/base-ui';
import moment from 'moment';
import apps_de from "../translations/apps/de.json";
import apps_en from "../translations/apps/en.json";
import binUpdater_de from "../translations/binUpdater/de.json";
import binUpdater_en from "../translations/binUpdater/en.json";
import customerList_de from "../translations/customerList/de.json";
import customerList_en from "../translations/customerList/en.json";
import githubList_de from "../translations/github/de.json";
import githubList_en from "../translations/github/en.json";
import main_de from "../translations/main/de.json";
import main_en from "../translations/main/en.json";
import releaseList_de from "../translations/releaseList/de.json";
import releaseList_en from "../translations/releaseList/en.json";
import tools_en from "../translations/tools/en.json";
import tools_de from "../translations/tools/de.json";
import { Settings } from 'luxon';

const translations_de = {
    main: main_de,
    apps: apps_de,
    customerList: customerList_de,
    binUpdater: binUpdater_de,
    releaseList: releaseList_de,
    github: githubList_de,
    tools: tools_de
}

const translations_en = {
    main: main_en,
    apps: apps_en,
    customerList: customerList_en,
    binUpdater: binUpdater_en,
    releaseList: releaseList_en,
    github: githubList_en,
    tools: tools_en
}

export const initI18Next = () => {
    return initializeLocalization({
        supportedCultures: ProgressApp.getSupportedLocales(),
        resourceBundles: {
            de: translations_de,
            en: translations_en
        },
        onLanguageChanged: function (lng: string) {
            // set the language for moment
            moment.locale(lng);

            Settings.defaultZone = "Europe/Rome";
        }
    });
}

export type progressDashboardTranslations = typeof translations_de;

export function useTypedTranslation() {
    return useTypedTranslationHook<progressDashboardTranslations>();
}
