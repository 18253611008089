const routePrefix = '/ui';

export const routes = {
    customerList: `${routePrefix}/customer-overview`,
    progressApps: `${routePrefix}/apps`,
    progressBinUpdater: `${routePrefix}/updater`,
    applicationInsights: `${routePrefix}/applicationInsights`,
    about: `${routePrefix}/about`,
    configSheet: `${routePrefix}/configSheet`,
    githubList: `${routePrefix}/githubList`,
    pipelines: `${routePrefix}/pipelines`,
    tools: `${routePrefix}/tools`,

    /**Returns the FULL app base path */
    getFullBasePath: () => {
        return window.location.href;
    },
};
export default routes;
