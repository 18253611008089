
import { useReduxDispatch } from '@/store/configureStore';
import { setSelectedFile } from '@/store/data/configSheet/configSheetSlice';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { AlertType, DropzoneAreaBase } from 'mui-file-dropzone';
import { useSnackbar } from 'notistack';
import React, { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { withCanvasContext } from '../FabricContext';
import { addNativeFileHandle } from '../fabricUtils';
import { readFileAsDataURL, readFileAsText } from '../fileHelpers';
import { Image } from '../objects/objects';
import { Logger } from '@progress/base-ui';

const PREFIX = 'DragAndDrop';

const classes = {
    main: `${PREFIX}-main`,
    visible: `${PREFIX}-visible`,
    inVisible: `${PREFIX}-inVisible`
};

const Root = styled('div')({
    [`& .${classes.main}`]: {
        className: "dropzone",
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        zIndex: 10000,
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: "none",
        border: "dashed"
    },
    [`& .${classes.visible}`]: {

    },
    [`& .${classes.inVisible}`]: {
        display: "none"
    }
});

export const DropZone = withCanvasContext<{ wrapperRef: MutableRefObject<HTMLDivElement> }>(({ canvas, wrapperRef }) => {
    const [isVisible, setIsVisible] = useState(false)
    const dispatch = useReduxDispatch();
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        const wrapperDiv = wrapperRef.current

        let counter = 0;
        const dragEnter = (e: DragEvent) => {
            const dt = e.dataTransfer;
            if (dt && !(dt.types && (dt.types.indexOf ? dt.types.indexOf("Files") !== -1 : dt.types.includes("Files")))) {
                Logger.debug("not a valid file")
                setIsVisible(false)
            }
            else {
                counter++;
                setIsVisible(true)
            }
        }

        const dragLeave = (e: Event) => {
            counter--;
            if (counter === 0) {
                setIsVisible(false)
            }
            e.preventDefault();
        }

        wrapperDiv?.addEventListener('dragenter', dragEnter, { passive: false })
        wrapperDiv?.addEventListener('dragleave', dragLeave, { passive: false })

        return () => {
            wrapperDiv?.removeEventListener('dragenter', dragEnter)
            wrapperDiv?.removeEventListener('dragleave', dragLeave)
        }

    }, [wrapperRef])

    const handleOnDrop = useCallback(async (files: File[], e: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement> | DragEvent | Event) => {
        setIsVisible(false)

        const acceptedImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/svg+xml'];
        const acceptedFileTypes = ['application/json'];

        if (files.length !== 1) {
            enqueueSnackbar('Dropping multiple files is not supported', { variant: 'warning' })
            return;
        }

        const [file] = files;

        if (acceptedImageTypes.includes(file.type)) {
            // handle add image
            const content = await readFileAsDataURL(file);
            const image = await Image.fromDataURL(content as string, { left: 10, top: 10 })
            image.scaleToWidth(200, false)
            canvas.discardActiveObject()
            canvas.add(image);
            canvas.setActiveObject(image);
            canvas.renderAll()
        } else if (acceptedFileTypes.includes(file.type)) {
            // try to create a read/write filehandle
            // advantage: the file can be saved/overwritten
            const wrappedEvent: { dataTransfer: DataTransfer } = e as { dataTransfer: DataTransfer }

            if (wrappedEvent && wrappedEvent.dataTransfer.items.length) {
                // Process all of the items.
                const [item] = wrappedEvent.dataTransfer.items
                // kind will be 'file' for file/directory entries.
                if (item.kind === 'file') {
                    const entry = await item?.getAsFileSystemHandle?.();
                    if (entry && entry.kind === 'file') {
                        addNativeFileHandle(canvas, entry as FileSystemFileHandle);
                    } else if (entry && entry.kind === 'directory') {
                        enqueueSnackbar(`Directories are not supported`, { variant: 'error' })
                    }
                }
            } else {
                const content = await readFileAsText(files[0]);
                const result = JSON.parse(content);
                canvas.clearHistory()
                canvas.offHistory();
                canvas.fromJSON(result);
                canvas.onHistory();

                dispatch(setSelectedFile({ sourceType: 'playground', name: 'playground' }))
            }
        } else {
            enqueueSnackbar(`Filetype '${file.type}' is not supported`, { variant: 'error' })
        }
    }, [canvas, dispatch, enqueueSnackbar]);

    const handleOnRejected = useCallback(() => {
        setIsVisible(false)
    }, [])

    const handleAlert = useCallback((message: string, variant: AlertType) => {
        if (variant !== "success") {
            enqueueSnackbar(message, { variant, autoHideDuration: 5000 })
        }
    }, [enqueueSnackbar])

    return <Root >
        <DropzoneAreaBase
            disableRejectionFeedback
            showPreviewsInDropzone={false}
            filesLimit={1}
            fileObjects={[]}
            dropzoneClass={clsx(classes.main, isVisible ? classes.visible : classes.inVisible)}
            onDrop={handleOnDrop}
            onAlert={handleAlert}
            onDropRejected={handleOnRejected}
            showAlerts={false}
            dropzoneProps={
                {
                    noClick: false,
                }
            }
        />
    </Root>
})