import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Menu, MenuItem } from '@mui/material';
import React, { useCallback, useState } from 'react';

export type Choice = {
    displayName: string,
    action: () => void,
    mainItem?: true
}

type ChoiceButtonProps = {
    choices: Choice[],
    style?: React.CSSProperties
}

const ChoiceButton = (props: ChoiceButtonProps) => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleSelection = useCallback((action: () => void) =>
        () => {
            action()
            setAnchorEl(null)
        }, [])

    const handleAction = useCallback(() => {
        setAnchorEl(null)
        props.choices.find(c => c.mainItem)?.action()
    }, [props.choices])

    return (
        <div style={{ flexShrink: 0, display: "flex", alignItems: "center", ...props.style }}>
            <Button
                variant="outlined"
                color="progressGrey"
                size="small"
                disabled={props.choices.length === 0}
                onClick={handleAction}
                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, height: 30.75, maxHeight: 30.75, minHeight: 30.75 }}
            >
                {props.choices.find(c => c.mainItem)?.displayName ?? "NONE"}
            </Button>
            <Button
                variant="outlined"
                size="small"
                color="progressGrey"
                onClick={handleClick}
                disabled={props.choices.length === 0}
                style={{ paddingLeft: 0, paddingRight: 0, width: 20, maxWidth: 20, minWidth: 20, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, height: 30.75, maxHeight: 30.75, minHeight: 30.75 }}
            >
                <ArrowDropDownIcon />
            </Button>

            <Menu
                open={anchorEl !== null}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                {
                    props.choices.map((c, i) =>
                        <MenuItem key={c.displayName + i} onClick={handleSelection(c.action)}>{c.displayName}</MenuItem>
                    )
                }
            </Menu>
        </div>
    );
}

export default ChoiceButton;