import * as d3 from "d3";
import { fabric } from "fabric";
import { BaseComponent } from "./baseComponent";
import { EditableProperty } from './objects'

export class PalletStackLabel extends BaseComponent {
    controlType = 'storePalletStack';
    stackNo: string | undefined
    isDirty = false

    constructor(options?: fabric.IRectOptions & { stackNo?: string }) {
        super({
            // defaults
            ...{
                width: 100,
                height: 50,
                fill: 'transparent',
                stroke: 'black',
                strokeWidth: 0.75,
                objectCaching: false,
                cornerColor: "black",
                borderColor: "black",
            },
            ...options,
        });

        this.stackNo = options?.stackNo
    }

    _render(ctx: CanvasRenderingContext2D) {
        super._render(ctx);
        ctx.font = '12px sans-serif';
        ctx.fillStyle = "black";
        ctx.textAlign = "center";

        ctx.fillText('StorePalletStack', 0, 5);
    }

    update<K extends keyof this>(propertyName: K, value: this[K] | ((value: this[K]) => this[K])): fabric.Object {
        this.set(propertyName, value);
        this.set('dirty', true)
        this.isDirty = true;
        this.canvas?.fire('object:modified')
        return this;
    }

    getProperties(): EditableProperty[] {
        return [
            ...super.getProperties(),
            { propertyName: 'stackNo', displayName: 'Stack number', type: 'string' },
        ];
    }

    serialize() {
        const top = this.top ?? 0;
        const left = this.left ?? 0;

        const base = {
            type: this.controlType,
            width: this.width ?? 0,
            height: this.height ?? 0,
            stackNo: this.stackNo ?? "",
            fill: this.fill,
        }

        if (this.group) {
            const groupTop = this.group.top ?? 0;
            const groupLeft = this.group.left ?? 0;
            const groupHeight = this.group.height ?? 0;
            const groupWidth = this.group.width ?? 0;

            return {
                ...base,
                top: top + groupTop + groupHeight / 2,
                left: left + groupLeft + groupWidth / 2,
            }
        } else {
            return {
                ...base,
                top: top,
                left: left,
            }
        }
    }

    renderToSVG(svgElement: SVGSVGElement) {
        d3.select(svgElement)
            .append("rect")
            .attr("x", this.left ?? 0)
            .attr("y", this.top ?? 0)
            .attr("width", this.width ?? 0)
            .attr("height", this.height ?? 0)
            .style("fill", (this.fill ?? '') as string)
            .style("stroke-width", "0.5")
            .style("stroke", "rgb(0,0,0)")
            .attr("type", this.controlType)
            .attr("stackNo", this.stackNo ?? '')

        return svgElement;
    }

}