import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from "react";
import { Log } from "../utils/debug";
import PDFPage from './PDFPage';
import { PDFPageContentSize } from './pdfViewerHooks';
import { PDFPagesContainer, pdfViewerClass } from './pdfViewerStyles';
import { PDFHtmlElementSize } from './pdfViewerTypes';

export type PDFPagesProps = {
    /** how many cells to display per row, default is 1 */
    pagesPerRow: number
    /** spacing between grids, default is 10 */
    pageGap?: number
    /** The pre-calculated size of the pages container  */
    wrapperSize: PDFHtmlElementSize
    /** array of calculated page sizes fitting the pages container */
    pageSizes: PDFPageContentSize[]
    /** render callback to render the content, when the parameter mutates it will trigger a rerender */
    renderPage: (pageIndex: number, pageSize: PDFHtmlElementSize, contentSize: PDFHtmlElementSize) => Promise<HTMLElement>
}

export type PDFPagesHandle = {
    /** retrieve a reference to a single page element */
    getPageElement: (pageIndex: number) => HTMLElement
}

export const PDFPages = forwardRef<PDFPagesHandle, PDFPagesProps>(function PDFPages({
    pagesPerRow = 1,
    pageGap = 10,
    wrapperSize,
    pageSizes,
    renderPage
}: PDFPagesProps, ref) {

    const pagesContainerRef = useRef<HTMLDivElement>(null)

    const pagesContainer = useCallback(() => {
        if (!pagesContainerRef.current)
            throw new Error("GridContainer is not initialized.")
        return pagesContainerRef.current
    }, [pagesContainerRef])

    useImperativeHandle(ref, () => ({
        getPageElement: (pageIndex: number) => {
            return pagesContainer().querySelector('.' + pdfViewerClass(`page--${pageIndex}`).className) as HTMLElement
        }
    }))

    const gridColumns = Math.min(pageSizes.length, pagesPerRow)

    const pdfPagesContainerStyles = useMemo(() => ({
        ...wrapperSize,
        gap: pageGap,
        gridTemplateColumns: `repeat(${gridColumns}, 1fr)`,
    }), [pageGap, gridColumns, wrapperSize])

    Log.debug('[Render] PDFPages', { wrapperSize, pageSizes })

    return pageSizes.length > 0
        ? <PDFPagesContainer
            {...pdfViewerClass("pages-container")}
            ref={pagesContainerRef}
            style={pdfPagesContainerStyles}
        >
            {
                pageSizes.map((pageSize) => {
                    /* we need to give the key a unique value to force a re-render of the cell when the content size changes */
                    const key = `page_${pageSize.index}_${pageSize.scaledSize.width}x${pageSize.scaledSize.height}`
                    return <div key={key} {...pdfViewerClass("page")}>
                        <PDFPage
                            {...pageSize}
                            renderPage={renderPage}
                        />
                    </div>
                })
            }
        </PDFPagesContainer>
        : null //when we have no pages, we do not render the grid
})
