import { AccessTime, AccountCircle, Apps, Language, Notifications } from '@mui/icons-material';
import { Avatar, Badge, Box, ClickAwayListener, CssBaseline, Divider, Drawer, Grid, IconButton, List, ListItem, Menu, MenuItem, PopoverOrigin, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ErrorBoundary, ProgressApp } from '@progress/base-ui';
import { DateTime, IANAZone } from 'luxon';
import React, { PropsWithChildren } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { localeToDisplayName, useTypedTranslation } from '../definitions/languages';
import { hasParentWithId } from '../utils/miscUtils';
import { ProgressBaseLayoutProps } from './ProgressBaseLayout';
import ModuleTitle from './internal/ModuleTitle';
import { ProgressAppBar } from './internal/ProgressAppBar';
import { ProgressAppLogo } from './internal/ProgressAppLogo';
import { ProgressContentContainer } from './internal/ProgressContentContainer';
import { ProgressTooltip } from './internal/ProgressTooltip';
import { ColorScheme, blueScheme, devScheme, greyScheme, percentToHexOpacity, stringToHashColor, yellowScheme } from './internal/colors';
import { RootDiv, TopBarOffset } from './internal/sharedStyledComponents';
import { AccountMenu, AppAction, AppMenu, AppModule, LayoutItem, NotificationsMenu } from './types';

//import of custom frame css to avoid issues if html, body or root height are not set properly to 100%
import './frame.css';

//#region Styled elements
type CustomNavLinkProps = NavLinkProps<unknown> & React.RefAttributes<HTMLAnchorElement> & { colors: ColorScheme }

const StyledModuleNavLink = styled(NavLink)(({ colors }: CustomNavLinkProps) => ({
    textDecoration: 'none',
    height: '100%',
    color: colors.inactiveText,
    "& >.MuiIconButton-root": {
        height: "100%",
        color: "inherit",
        "&:hover": {
            backgroundColor: "transparent",
        }
    },
    "&:hover": {
        borderBottom: `solid 5px ${colors.inactiveText}`,
        backgroundColor: `${colors.activeText}${percentToHexOpacity(10)}`,
    },
    "&.active": {
        borderBottom: `solid 5px ${colors.activeText}`,
        backgroundColor: `${colors.activeText}${percentToHexOpacity(15)}`,
        color: colors.activeText,
        "&:hover": {
            backgroundColor: `${colors.activeText}${percentToHexOpacity(20)}`,
        }
    }
}))

const StyledMenuNavLink = styled(NavLink)(({ theme }) => ({
    margin: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    textAlign: "center",
    borderRadius: "10px",
    "&:hover": {
        background: "#6e6d6d1a"

    },
    "&.active": {
        background: "#00acff20",
        color: "#008acb",
        "&:hover": {
            backgroundColor: "#00acff30",
            color: theme.palette.grey[800]
        }
    }
}))
//#endregion

const popoverOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'right' };
const transformOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'right' };

const isMenu = (item: (AppMenu | AppAction)): item is AppMenu => (item as AppMenu).content !== undefined;
const isMenuItem = (content: (React.ReactFragment | LayoutItem[])): content is LayoutItem[] => (content as LayoutItem[]).length !== undefined;

type LayoutProps = PropsWithChildren<ProgressBaseLayoutProps & {
    modules: AppModule[],
    accountMenu: AccountMenu,
    customItems: (AppMenu | AppAction)[],
    notificationsMenu?: NotificationsMenu,
    isDevelopment?: boolean,
    customContent?: React.ReactFragment,
    timeZoneName?: string
}>

const NotificationBtn = "notificationsIconBtn";
const getTimeZoneOffset = (timezone: string) => IANAZone.create(timezone).formatOffset(DateTime.now().setZone(timezone).valueOf(), "short")

export const ProgressLayout = (props: LayoutProps) => {

    const supportedLocales = ProgressApp.getSupportedLocales();
    const widthLargerThan600 = useMediaQuery("(min-width:600px)")
    const widthLargerThan900 = useMediaQuery("(min-width:900px)")
    const widthLargerThan1200 = useMediaQuery("(min-width:1200px)")

    const { t, tp, language, changeLanguage } = useTypedTranslation()

    //Rename bools for  readability
    const displayInactiveModuleIcons = widthLargerThan900;
    const displayActiveModuleTitle = widthLargerThan1200 || !displayInactiveModuleIcons;
    const displayAppLogo = widthLargerThan600;
    //Grey as default color scheme, if env is DEV, show grey color scheme with red appbar
    const colors = props.isDevelopment ? devScheme : (props.variant === "blue" ? blueScheme : (props.variant === "yellow" ? yellowScheme : greyScheme));
    const showTopBar = props.showTopBar ?? true;

    const hasDifferentTimezone = React.useMemo(() => {
        if (!props.timeZoneName) {
            return false
        }

        const currentTimeZoneOffset = getTimeZoneOffset(props.timeZoneName);
        const browserTimeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone
        const browserTimeZoneOffset = getTimeZoneOffset(browserTimeZoneName);

        return currentTimeZoneOffset !== browserTimeZoneOffset
    }, [props.timeZoneName])

    const [moduleMenuAnchor, setModuleMenuAnchor] = React.useState<(null | HTMLElement)>(null);

    const [menuAnchors, setMenuAnchors] = React.useState<(null | HTMLElement)[]>(props.customItems.map(() => null));
    const updateMenuAnchor = React.useCallback((index: number, target: null | HTMLElement) => setMenuAnchors(menuAnchors => props.customItems.map((a, i) => i === index ? target : menuAnchors[i] ?? null)), [props.customItems]);
    const handleMenuOpen = React.useCallback((index: number, event: React.MouseEvent<HTMLElement>) => updateMenuAnchor(index, event.currentTarget), [updateMenuAnchor]);
    const handleMenuClose = React.useCallback((index: number) => updateMenuAnchor(index, null), [updateMenuAnchor]);

    const [languageMenuAnchor, setLanguageMenuAnchor] = React.useState<(null | HTMLElement)>(null);
    const [accountMenuAnchor, setAccountMenuAnchor] = React.useState<(null | HTMLElement)>(null);
    const [notificationsDrawerOpen, setNotificationsDrawerOpen] = React.useState(false);

    return (
        <RootDiv>
            <CssBaseline />
            {/** Top Bar UI */}
            {
                showTopBar &&
                <ProgressAppBar colors={colors}>
                    <Box className="progress-modules-menu" sx={{ display: "flex", height: "100%", placeItems: "center center" }}>
                        {/** Modules Menu */}
                        {
                            props.modules.filter(x => !x.hidden).length > 0 && <IconButton
                                onClick={(e) => setModuleMenuAnchor(e.currentTarget)}
                                sx={
                                    {
                                        color: moduleMenuAnchor !== null ? colors.activeText : colors.inactiveText,
                                        "&:hover": {
                                            color: colors.activeText,
                                        }
                                    }
                                }
                            >
                                <Apps sx={{ fontSize: "28px", color: "inherit" }} />
                            </IconButton>
                        }
                        {/** Logo */}
                        {
                            displayAppLogo &&
                            <ProgressAppLogo
                                appType={props.appType}
                                route="/"
                            />
                        }
                    </Box>

                    {/** Modules */}
                    {
                        (props.modules.length > 0 || props.customContent === undefined) &&
                        <Box className="progress-modules" sx={{ height: '100%', alignItems: 'center', display: 'flex', overflow: "hidden", marginRight: "auto" }}>
                            {
                                props.modules.map((module) =>
                                    <StyledModuleNavLink
                                        key={module.baseRoute}
                                        to={module.baseRoute}
                                        exact={module.exact}
                                        style={(isActive: boolean) => ({ display: (widthLargerThan900 && !module.hidden) || isActive ? "block" : "none" })}
                                        colors={colors}
                                    >
                                        <ProgressTooltip sx={{ fontSize: 16 }} title={module.title}>
                                            <IconButton>
                                                <module.icon sx={{ ...module.sx, fontSize: "28px", color: "inherit" }} />
                                            </IconButton>
                                        </ProgressTooltip>
                                    </StyledModuleNavLink >
                                )
                            }
                            {
                                displayActiveModuleTitle &&
                                props.modules.map((module) =>
                                    <ModuleTitle key={module.baseRoute} module={module} colors={colors} />
                                )
                            }
                        </Box>
                    }

                    {/** Optional custom content */}
                    {
                        props.customContent &&
                        <Box className="progress-modules-custom" sx={{ height: '100%', alignItems: 'center', display: 'flex', overflow: "hidden", marginRight: props.modules.length === 0 ? "auto" : 0 }}>
                            {props.customContent}
                        </Box>
                    }

                    {/** Timezone icon */}
                    {
                        props.timeZoneName !== undefined && hasDifferentTimezone &&
                        <ProgressTooltip
                            sx={{ fontSize: 16 }}
                            key="timezone"
                            title={tp({ name: props.timeZoneName, offset: getTimeZoneOffset(props.timeZoneName) }, "timezone")}
                        >

                            <IconButton
                                size="large"
                                sx={
                                    {
                                        color: colors.inactiveText,
                                        stroke: colors.inactiveText,
                                        "&:hover": {
                                            color: colors.activeText,
                                            stroke: colors.activeText,
                                        }
                                    }
                                }
                            >
                                <AccessTime
                                    fontSize="medium"
                                />
                            </IconButton>
                        </ProgressTooltip>
                    }

                    {/** Custom Icons */}
                    {
                        props.customItems.map((appItem, index) =>
                            // eslint-disable-next-line react/no-array-index-key
                            <ProgressTooltip sx={{ fontSize: 16 }} key={index} title={appItem.title}>
                                {
                                    isMenu(appItem) ?
                                        <IconButton
                                            onClick={(e) => handleMenuOpen(index, e)}
                                            size="large"
                                            sx={
                                                {
                                                    color: colors.inactiveText,
                                                    "&:hover": {
                                                        color: colors.activeText,
                                                    }
                                                }
                                            }
                                        >
                                            <appItem.icon fontSize="medium" sx={{ ...appItem.sx }} />
                                        </IconButton>
                                        :
                                        <IconButton
                                            href={appItem.href || ""}
                                            onClick={appItem.onClick || undefined}
                                            size="large"
                                            sx={
                                                {
                                                    color: colors.inactiveText,
                                                    stroke: colors.inactiveText,
                                                    "&:hover": {
                                                        color: colors.activeText,
                                                        stroke: colors.activeText,
                                                    }
                                                }
                                            }
                                        >
                                            <appItem.icon
                                                sx={
                                                    appItem.iconColor ?
                                                        {
                                                            ...appItem.sx,
                                                            color: appItem.iconColor.light || appItem.iconColor.main || "inherit",
                                                            stroke: "inherit",
                                                        }
                                                        :
                                                        { ...appItem.sx }
                                                }
                                                fontSize="medium"
                                            />
                                        </IconButton>
                                }
                            </ProgressTooltip>
                        )
                    }

                    {/** Language Icon */}
                    <ProgressTooltip sx={{ fontSize: 16 }} title={t("languageSelection")}>
                        <IconButton
                            color="inherit"
                            onClick={(e) => setLanguageMenuAnchor(e.currentTarget)}
                            size="large"
                            sx={
                                {
                                    marginLeft: 0,
                                    color: colors.inactiveText,
                                    "&:hover": {
                                        color: colors.activeText,
                                    }
                                }
                            }
                        >
                            <Language sx={{ color: "inherit" }} fontSize="medium" />
                        </IconButton>
                    </ProgressTooltip>

                    {/** Notifications Icon */}
                    {
                        props.notificationsMenu &&
                        <ProgressTooltip sx={{ fontSize: 16 }} title={props.notificationsMenu.title}>
                            <IconButton
                                id={NotificationBtn}
                                color="inherit"
                                onClick={() => { props.notificationsMenu && props.notificationsMenu.onClick && props.notificationsMenu.onClick(); setNotificationsDrawerOpen(!notificationsDrawerOpen); }}
                                size="large"
                                sx={
                                    {
                                        color: colors.inactiveText,
                                        "&:hover": {
                                            color: colors.activeText,
                                        }
                                    }
                                }
                            >
                                <Badge
                                    badgeContent={props.notificationsMenu.unreadCount}
                                    sx={
                                        {
                                            "& .MuiBadge-badge": {
                                                color: "white",
                                                backgroundColor: colors.accent,
                                            }
                                        }
                                    }
                                >
                                    <Notifications sx={{ color: "inherit" }} fontSize="medium" />
                                </Badge>
                            </IconButton>
                        </ProgressTooltip>
                    }
                    <Divider orientation="vertical" sx={{ background: colors.inactiveText, height: "28px", mx: "15px" }} />

                    {/** Account Icon */}
                    <ProgressTooltip sx={{ fontSize: 16 }} title={props.accountMenu.title}>
                        <IconButton
                            color="inherit"
                            onClick={(e) => setAccountMenuAnchor(e.currentTarget)}
                            size="small"
                            sx={
                                {
                                    marginRight: "10px",
                                    color: colors.activeText,
                                    "&:hover": {
                                        color: colors.activeText,
                                        backgroundColor: "transparent",
                                    },
                                }
                            }
                        >
                            {
                                props.accountMenu.username
                                    ?
                                    <Avatar {...stringAvatarProps(props.accountMenu.username, colors)} />
                                    :
                                    <AccountCircle sx={{ color: "inherit" }} fontSize="medium" />
                            }

                        </IconButton>
                    </ProgressTooltip>
                </ProgressAppBar>
            }

            {/** Top Bar Context Menus */}
            {
                showTopBar &&
                <>
                    <Menu
                        anchorEl={moduleMenuAnchor}
                        open={moduleMenuAnchor !== null}
                        onClose={() => setModuleMenuAnchor(null)}
                        sx={{ "& .MuiMenu-list": { padding: "8px" } }}
                    >
                        <Grid container>
                            {
                                props.modules.filter(m => !m.hidden).map((module) =>
                                    <Grid key={module.baseRoute} item onClick={() => setModuleMenuAnchor(null)}>
                                        <ProgressTooltip enterDelay={500} sx={{ fontSize: 16, color: theme => theme.palette.grey[800], textDecoration: "none" }} title={module.title}>
                                            <StyledMenuNavLink to={module.baseRoute} exact={module.exact}>
                                                <module.icon sx={{ ...module.sx, fontSize: 45 }} />
                                                <Typography noWrap style={{ width: "125px" }}>
                                                    {module.title}
                                                </Typography>
                                            </StyledMenuNavLink>
                                        </ProgressTooltip>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Menu>

                    {
                        props.customItems.map((appItem, index) =>
                            // Important to map over all indices and filter inside, to ensure index consistency with icons
                            isMenu(appItem) &&
                            <Menu
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                anchorEl={menuAnchors[index]}
                                anchorOrigin={popoverOrigin}
                                transformOrigin={transformOrigin}
                                open={Boolean(menuAnchors[index])}
                                onClose={() => handleMenuClose(index)}
                            >
                                {
                                    isMenuItem(appItem.content) ?
                                        appItem.content.map((menuItem, menuIndex) =>
                                            <MenuItem
                                                // eslint-disable-next-line react/no-array-index-key
                                                key={menuIndex} {...menuItem} content={undefined}
                                                onClick={() => { menuItem.onClick && menuItem.onClick(); handleMenuClose(index); }}
                                            >
                                                {menuItem.children}
                                            </MenuItem>
                                        )
                                        :
                                        <div>
                                            {appItem.content}
                                        </div>
                                }
                            </Menu>
                        )
                    }

                    <Menu
                        anchorEl={languageMenuAnchor}
                        anchorOrigin={popoverOrigin}
                        transformOrigin={transformOrigin}
                        open={languageMenuAnchor !== null}
                        onClose={() => setLanguageMenuAnchor(null)}
                    >
                        {
                            supportedLocales.map((lng) =>
                                <MenuItem
                                    key={lng}
                                    content={undefined}
                                    selected={language === lng}
                                    onClick={() => { changeLanguage(lng); setLanguageMenuAnchor(null); }}
                                >
                                    {/* We have to cast the lng to KnownLanguageCode because type string cannot be used as key for displayNames */}
                                    {localeToDisplayName(lng)}
                                </MenuItem>
                            )
                        }
                    </Menu>

                    <Menu
                        anchorEl={accountMenuAnchor}
                        anchorOrigin={popoverOrigin}
                        transformOrigin={transformOrigin}
                        open={accountMenuAnchor !== null}
                        onClose={() => setAccountMenuAnchor(null)}
                    >
                        {
                            props.accountMenu.items.map((menuItem, menuIndex) => (
                                <MenuItem
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={menuIndex} {...menuItem}
                                    onClick={() => { menuItem.onClick && menuItem.onClick(); setAccountMenuAnchor(null); }}
                                />
                            ))
                        }
                    </Menu>
                </>
            }

            {/** Content */}
            <ProgressContentContainer showTopBar={showTopBar}>
                <ErrorBoundary>
                    {props.children}
                </ErrorBoundary>
            </ProgressContentContainer>

            {/** Notifications Drawer */}
            {
                props.notificationsMenu &&
                <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={
                        (e) => {
                            // this prevents that when clicking on the notification icon, the drawer is closed and reopened due to event bubbling
                            if (e.target instanceof Element && hasParentWithId(NotificationBtn, e.target)) {
                                return;
                            }
                            setNotificationsDrawerOpen(!notificationsDrawerOpen)
                        }
                    }
                >
                    <Drawer
                        anchor="right"
                        open={notificationsDrawerOpen}
                        onClose={() => setNotificationsDrawerOpen(!notificationsDrawerOpen)}
                    >
                        {showTopBar && <TopBarOffset />}
                        <List disablePadding>
                            {props.notificationsMenu.items.map((item) => <ListItem key={item.key} disablePadding {...item as LayoutItem} />)}
                        </List>
                    </Drawer>
                </ClickAwayListener>
            }

        </RootDiv >
    );
}

//#region Helper functions for avatar
function stringAvatarProps(name: string, colors: ColorScheme) {
    name = name.toUpperCase();
    const hashedColor = stringToHashColor(name);
    return {
        sx: {
            bgcolor: hashedColor,
            fontSize: 18,
            height: "36px",
            width: "36px",
            border: "1px solid",
            borderColor: colors.inactiveText,
            color: colors.inactiveText,
            "&:hover": {
                border: "2px solid",
                borderColor: hashedColor,
                backgroundColor: colors.inactiveText,
                color: hashedColor,
            },
        },
        children: <>
            {name.split(' ')[0][0]}
            {name.split(' ').length > 1 && <>{name.split(' ')[1][0]}</>}
        </>,
    };
}

//#endregion