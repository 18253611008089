import { Container, Theme } from '@mui/material';
import { AboutPage } from '@progress/aboutpage-ui';
import React from 'react';

const containerStyle = (theme: Theme) => ({
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
});

const About: React.FC = () => {
    return (
        <Container maxWidth="lg" sx={containerStyle}>
            <AboutPage />
        </Container>
    );
}

export default About;