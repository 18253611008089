export class EnumUtils {
    static valueToKey<T extends Record<string, unknown>>(targetEnum: T, value: unknown) {
        return Object.keys(targetEnum).find(key => targetEnum[key] === value) as keyof T | undefined
    }

    static hasFlag<T extends number>(value: T, flag: T) {
        if (value === undefined || flag === undefined) {
            return false
        }
        return (value & flag) === flag
    }

    static setFlag<T extends number>(value: T, flag: T) {
        return (value | flag) as T
    }

    static removeFlag<T extends number>(value: T, flag: T) {
        return (value & (~flag)) as T
    }
}

/**
 * Parses an input value and returns the corresponding value from an enum.
 *
 * @param {T} inputEnum - The enum object.
 * @param {string | number} input - The input value to parse.
 * @return {T[keyof T] | undefined} - The parsed value from the enum, or undefined if it doesn't exist.
 */
export function parseEnum<T extends object>(inputEnum: T, input: string | number): T[keyof T] | undefined {
    let value = undefined;

    const inputKey = input as keyof T;
    const keys = Object.keys(inputEnum);

    if (typeof inputKey === "number") {
        if (keys.includes("" + inputKey)) {
            // value = inputEnum[inputKey]
            value = input as unknown as T[keyof T];
        } else {
            value = inputEnum[keys[inputKey] as keyof T];
        }
    } else {
        value = inputEnum[inputKey];
    }

    return value;
}

/**
 * Gets the numeric value or index of a specific enum key. It handles both enums with explicit numeric values and those without.
 * If the key is not found in the enum, it either returns a default value (if provided) or throws an error.
 * 
 * @param enumType The enum type.
 * @param key The key for which the numeric value or index is required.
 * @param defaultValue The default value to return if the key is not found in the enum.
 * @returns The numeric value or index of the specified enum key or the default value.
 * @throws {Error} If the key is not found in the enum and no default value is provided.
 * 
 * @example
 * enum ColorWithValues {
 *     Red = -1,
 *     Blue = 2,
 *     Green = 9
 * }
 * 
 * enum Color {
 *     Red = "Red",
 *     Blue = "Blue",
 *     Green = "Green"
 * }
 * 
 * // Get the numeric value of 'Green' from an enum with explicit numeric values
 * const valueGreen = enumToNumeric(ColorWithValues, "Green")
 * console.log(valueGreen) // Output: 9
 * 
 * // Get the index of 'Green' from an enum without explicit numeric values
 * const indexGreen = enumToNumeric(Color, "Green")
 * console.log(indexGreen) // Output: 2
 * 
 * // Attempt to get the value/index of a non-existing key with a default value
 * const valueNonExisting = enumToNumeric(Color, "Yellow", -99)
 * console.log(valueNonExisting) // Output: -99
 */
export function enumToNumeric<T extends object, K extends keyof T>(enumType: T, key: K, defaultValue?: number): number {
    const value = enumType[key]

    if (value !== undefined) {
        if (typeof value === 'number') {
            return value
        } else if (typeof value === 'string') {
            const index = Object.keys(enumType).indexOf(key as string)
            if (index !== -1) {
                return index
            }
        }
    }

    if (defaultValue !== undefined) {
        return defaultValue
    } else {
        throw new Error(`Enum key '${String(key)}' not found and no default value provided.`)
    }
}

/**
 * Retrieves the key of an enum entry based on its numeric value for numeric enums, or its index for string enums.
 * Throws an error if the value or index is not found in the enum and no default value is provided.
 * 
 * @param enumType The enum type.
 * @param valueOrIndex The numeric value (for numeric enums) or index (for string enums) for which the key is required.
 * @param defaultValue The default key to return if the value or index is not found in the enum.
 * @returns The key of the specified enum entry or the default key.
 * @throws {Error} If the value or index is not found in the enum and no default value is provided.
 * 
 * @example
 * enum ColorWithValues {
 *     Red = -1,
 *     Blue = 2,
 *     Green = 9
 * }
 * 
 * enum Color {
 *     Red = "Red",
 *     Blue = "Blue",
 *     Green = "Green"
 * }
 * 
 * // Get the key from a numeric value in an enum with explicit numeric values
 * const keyFromNumericValue = enumKeyFromValue(ColorWithValues, 9)
 * console.log(keyFromNumericValue) // Output: "Green"
 * 
 * // Get the key from an index in an enum without explicit numeric values
 * const keyFromIndex = enumKeyFromValue(Color, 2)
 * console.log(keyFromIndex) // Output: "Green"
 * 
 * // Attempt to get the key from a non-existing value/index without a default value
 * // This will throw an error
 * try {
 *     const keyNonExisting = enumKeyFromValue(Color, 5)
 *     console.log(keyNonExisting)
 * } catch (error) {
 *     console.error(error)
 * }
 */
export function numericToEnum<T extends object>(enumType: T, valueOrIndex: number, defaultValue?: keyof T): keyof T {
    const entries = Object.entries(enumType)
    const isNumericEnum = entries.every(([key, value]) => typeof value === 'number')

    if (isNumericEnum) {
        const foundEntry = entries.find(([, value]) => value === valueOrIndex)
        if (foundEntry) {
            return foundEntry[0] as keyof T
        }
    } else {
        const keys = Object.keys(enumType)
        if (keys[valueOrIndex] !== undefined) {
            return keys[valueOrIndex] as keyof T
        }
    }

    if (defaultValue !== undefined) {
        return defaultValue
    } else {
        throw new Error(`Value or index '${valueOrIndex}' not found in enum and no default value provided.`)
    }
}
