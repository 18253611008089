
import * as d3 from "d3";
import { fabric } from "fabric";
import { EditableProperty } from './objects';

export class Text extends fabric.Text {
    controlType = 'Label';
    isDirty = false;
    translationKey?: string;

    constructor(options: fabric.TextOptions & { text: string, translationKey?: string, textAnchor?: string }) {
        super(options.text, {
            // defaults
            ...{
                fill: '#000000',
                stroke: '#000000',
                fontSize: 12,
                fontFamily: "Arial",
                fontWeight: "normal",
                underline: false,
                objectCaching: false,
                originX: 'center',
                originY: 'center',
                cornerColor: 'black',
                borderColor: 'black',
                strokeWidth: 0,
            },
            ...options
        });

        this.translationKey = options.translationKey ?? ""

        this.on('scaling', () => {
            if (this.scaleX) {
                if (this.fontSize !== undefined)
                    this.fontSize *= this.scaleX;
            }
            this.scaleX = 1;
            this.scaleY = 1;
        })

        this.setControlsVisibility({ mtr: false });
    }

    update<K extends keyof this>(propertyName: K, value: this[K] | ((value: this[K]) => this[K])): fabric.Object {
        this.set(propertyName, value);
        this.set('dirty', true)
        this.isDirty = true;
        this.canvas?.fire('object:modified')
        return this
    }

    getProperties(): EditableProperty[] {
        return [
            { propertyName: "left", displayName: "X", type: "number" },
            { propertyName: "top", displayName: "Y", type: "number" },
            { propertyName: "text", displayName: "Text", type: "string" },
            { propertyName: 'translationKey', displayName: 'Translation-key', type: 'string' },
            { propertyName: "fontSize", displayName: "Font size", type: "number" },
            { propertyName: "fontFamily", displayName: "Font family", type: "font" },
            { propertyName: "underline", displayName: "Underline", type: "underlined" },
            { propertyName: "fontWeight", displayName: "Font weight", type: "bold" },
            { propertyName: "fontStyle", displayName: "Font style", type: "italic" },
            { propertyName: "originX", displayName: "Origin x", type: "originX" },
            { propertyName: "fill", displayName: "Text color", type: "color" },
        ];
    }

    serialize() {
        const top = this.top ?? 0;
        const left = this.left ?? 0;

        const base = {
            type: this.controlType,
            text: this.text,
            fontSize: this.fontSize,
            fill: this.fill,
            fontFamily: this.fontFamily,
            underline: this.underline,
            fontWeight: this.fontWeight,
            fontStyle: this.fontStyle,
            translationKey: this.translationKey,
            originX: this.originX
        }

        if (this.group) {
            const groupTop = this.group.top ?? 0;
            const groupLeft = this.group.left ?? 0;
            const groupHeight = this.group.height ?? 0;
            const groupWidth = this.group.width ?? 0;

            return {
                ...base,
                top: top + groupTop + groupHeight / 2,
                left: left + groupLeft + groupWidth / 2,
            }
        } else {
            return {
                ...base,
                top: top,
                left: left,
            }
        }
    }

    toAnchor(origin:string  | undefined): string | undefined {
        switch(origin){
            case "left":
                return "start";
            case "center":
                return "middle";
            case "right":
                return "end";
            default:
                return undefined;
        }
    }

    renderToSVG(svgElement: SVGSVGElement) {
        d3.select(svgElement)
            .append("text")
            .attr("x", this.left ?? 0)
            .attr("y", this.top ?? 0)
            .text(this.text ?? '')
            .attr("translationkey", this.translationKey ?? '')
            .style("font-size", (this.fontSize ?? "") + "px")
            .style("fill", (this.fill ?? '') as string)
            .style("font-family", this.fontFamily ?? "")
            .style("font-weight", this.fontWeight ?? "")
            .style("font-style", this.fontStyle ?? "")
            .style("text-decoration", this.underline ? "underline" : "none")
            .style("dominant-baseline", "central")
            .style("text-anchor", this.toAnchor(this.originX) ?? "");
            
        return svgElement;
    }

}