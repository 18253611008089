import { useReduxDispatch } from '@/store/configureStore';
import { fetchCustomers } from '@/store/data/customers/actions';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { Logger, ProgressDialog } from '@progress/base-ui';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { api } from '../definitions';
import { IConfigAssemblyMapping } from '../definitions/autogenerated/assemblyMappings';
import { fetchDeployments } from '../store/data/deployments/actions';
import { ApplicationFields } from './ApplicationFields';
import ScreenLoading from './ScreenLoading';

type EditApplicationProps = {
    applicationType: keyof typeof IConfigAssemblyMapping
    uniqueIdentifier: string
    title: string
    onClose: () => void
}

export const EditApplication: React.FC<EditApplicationProps> = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useReduxDispatch();

    const [data, setData] = useState<Record<string, string | number | boolean | string[]>>({})
    const [loading, setLoading] = useState(false)
    const [configLoadedAndValid, setConfigLoadedAndValid] = useState(false)

    useEffect(() => {
        setLoading(true)

        // load the current config
        axios.get<Record<string, string | number | boolean>>(api.configurationGetConfig(props.uniqueIdentifier))
            .then((result) => {
                setData(oldValue => ({ ...oldValue, ...result.data }))
                setConfigLoadedAndValid(true)
            })
            .catchError((e) => {
                enqueueSnackbar(e.Description, { variant: 'error', autoHideDuration: 3000 })
                setConfigLoadedAndValid(false)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [enqueueSnackbar, props.uniqueIdentifier])

    const handleSubmit = () => {

        const assembly = IConfigAssemblyMapping[props.applicationType]

        if (!assembly) {
            const message = "[EditApplication] ApplicationType is not valid! ApplicationType=" + props.applicationType
            enqueueSnackbar(message, { variant: "error" })
            Logger.error(message)
            return;
        }
        setLoading(true)

        const request = {
            NewConfig: {
                $type: assembly,
                ...data
            },
            UniqueIdentifier: props.uniqueIdentifier
        }

        // since the uniqueIdentifier is a calculated field, make sure to send the 'old' one!
        axios.put(api.configurationUpdateConfiguration, request).then(() => {
            setData({});
            dispatch(fetchCustomers());
            dispatch(fetchDeployments());
            enqueueSnackbar('Success', { variant: 'success' })
            props.onClose();
        }).catchError((e) => {
            enqueueSnackbar(e.Description, { variant: 'error', autoHideDuration: 3000 })
            if (e.ValidationErrors) {
                Object.values(e.ValidationErrors).forEach(errors => {
                    errors?.forEach(error => {
                        enqueueSnackbar(error.Message, { variant: 'error', autoHideDuration: 3000, })
                    })
                })
            }
            setLoading(false)
        })
    }

    const handleCancel = useCallback(() => {
        props.onClose();
    }, [props])

    const handleFieldChange = useCallback((key: string, value: string | number | boolean | string[]) => {
        setData(oldValue => ({ ...oldValue, [key]: value }))
    }, [])

    return <ProgressDialog
        open
        onClose={props.onClose} title={props.title}
        maxWidth="sm"
        fullWidth
        actions={
            <>
                <Button variant="text" onClick={handleCancel} disabled={loading}>Cancel</Button>
                <LoadingButton
                    variant="contained" disabled={!configLoadedAndValid} onClick={handleSubmit}
                    loading={loading}
                >
                    Update
                </LoadingButton>
            </>
        }
    >
        <ApplicationFields
            operation="update"
            applicationType={props.applicationType}
            values={data}
            onChange={handleFieldChange}
        />
        {loading && <ScreenLoading />}
    </ProgressDialog>
}