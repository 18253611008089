import { useEffect } from 'react'
import { Log } from '../utils/debug'

/**
 * Adds an event listener to a DOM element and does the cleanup on unmount.
 * @param elementRef - A React ref object pointing to a DOM element.
 * @param eventType - The type of event to listen for.
 * @param handler - The function to handle the event.
 * @param options - Optional parameters to specify characteristics about the event listener.
 */
export function useEventListener<EventDataType extends Event, ElementType extends HTMLElement>(
    elementRef: React.RefObject<ElementType>,
    eventType: string,
    eventHandler: (event: EventDataType) => void,
    options?: boolean | AddEventListenerOptions
): void { // Removed the incorrect return type annotation as this function doesn't return anything
    useEffect(() => {
        const element = elementRef.current // Current element reference
        if (!element)
            return Log.error(`Element with ref '${elementRef}' not found.`)
        const handleEvent = (event: Event) => eventHandler(event as EventDataType)
        element.addEventListener(eventType, handleEvent, options)
        // Cleanup function to remove the event listener
        return () => element.removeEventListener(eventType, handleEvent)
    }, [elementRef, eventType, eventHandler, options])
}