
import { createTypedAsyncThunk } from "@/helpers/helpers";
import axios from "axios";
import { api } from "../../../definitions";
import { Deployment } from "../../../definitions/autogenerated/types";

export const fetchDeployments = createTypedAsyncThunk<Deployment[], void>(
    "deployments/fetchDeployments",
    async () => {
        const response = await axios.get<Deployment[]>(api.deploymentList);
        return response.data;
    }
)