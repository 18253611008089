import React, { useCallback, useState } from "react";
import { DistanceUnit, MassUnit, AreaUnit, VolumeUnit, AngleUnit, DurationUnit } from "./types";
// Hack for conver-units: Uses lodash internally, needs global to be defined!
if (!window.global) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.global = {};
}
export { }

export type UnitsContext = {
    distanceUnit: DistanceUnit,
    massUnit: MassUnit,
    areaUnit: AreaUnit,
    volumeUnit: VolumeUnit,
    durationUnit: DurationUnit,
    angleUnit: AngleUnit
}

/**
 * Default internal units used as shared fallback for hooks and context
 */
export const defaultUnits: UnitsContext = {
    distanceUnit: DistanceUnit.MILLIMETRES,
    massUnit: MassUnit.KILOGRAMS,
    areaUnit: AreaUnit.SQUARE_METRES,
    volumeUnit: VolumeUnit.CUBIC_METRES,
    durationUnit: DurationUnit.SECONDS,
    angleUnit: AngleUnit.DEGREES,
}

type UnitsContextWithChangeHandle = UnitsContext & { changeUnits: (newUnits: Partial<UnitsContext>) => void }

const fallBackContext: UnitsContextWithChangeHandle = { ...defaultUnits, changeUnits: () => { console.warn('changeUnits has no effect since no UnitsProvider is registered') } }
const Context = React.createContext<UnitsContextWithChangeHandle>(fallBackContext);

/**
 * Hook to retreive units
 * 
 * @returns currently used units
 */
export const useUnits = () => {
    const context = React.useContext(Context);
    return context
}

const getUpdatedContext = (changes: Partial<UnitsContext> | undefined, current: UnitsContext): UnitsContext => {
    if (changes && typeof changes === "object" && !Array.isArray(changes)) {
        const distanceUnit = (!!changes.distanceUnit && Object.values(DistanceUnit).includes(changes.distanceUnit)) ? changes.distanceUnit : current.distanceUnit;
        const massUnit = (!!changes.massUnit && Object.values(MassUnit).includes(changes.massUnit)) ? changes.massUnit : current.massUnit;
        const areaUnit = (!!changes.areaUnit && Object.values(AreaUnit).includes(changes.areaUnit)) ? changes.areaUnit : current.areaUnit;
        const volumeUnit = (!!changes.volumeUnit && Object.values(VolumeUnit).includes(changes.volumeUnit)) ? changes.volumeUnit : current.volumeUnit;
        const durationUnit = (!!changes.durationUnit && Object.values(DurationUnit).includes(changes.durationUnit)) ? changes.durationUnit : current.durationUnit;
        const angleUnit = (!!changes.angleUnit && Object.values(AngleUnit).includes(changes.angleUnit)) ? changes.angleUnit : current.angleUnit;

        return { distanceUnit, massUnit, areaUnit, volumeUnit, durationUnit, angleUnit }
    } else {
        return current;
    }
}

export type UnitsProviderProps = {
    initialState?: Partial<UnitsContext>,
    afterChange?: (newContext: UnitsContext) => unknown
}

/**
 * Units Provider
 * @param initialState initial state of selected units {@link UnitsContext} @defaultValue {@link defaultUnits}
 * @param afterChange called after unit change
 * @param children because he provides for the kids
 * @returns context provider
 */
export const UnitsProvider: React.FC<UnitsProviderProps> = (props) => {
    const { afterChange } = props; //spread for dependency array
    const [units, setUnits] = useState(() => getUpdatedContext(props.initialState, defaultUnits)); // it makes a difference if we just pass a value or a function!!!

    const changeUnits = useCallback((changes: Partial<UnitsContextWithChangeHandle>) => {
        setUnits(current => {
            const newContext = getUpdatedContext(changes, current);
            //app-defined custom behaviour
            afterChange?.(newContext);
            return newContext;
        });
    }, [afterChange])

    const value = React.useMemo(() => ({ changeUnits, ...units }), [changeUnits, units])

    return <Context.Provider value={value} >
        {props.children}
    </Context.Provider>
}
