import * as d3 from "d3";
import { fabric } from "fabric";
import { BaseComponent } from "./baseComponent";
import { EditableProperty } from './objects'

export class DryHourLegend extends BaseComponent {
    controlType = 'DryHourLegend';
    isDirty = false

    constructor(options?: fabric.IRectOptions) {
        super({
            // defaults
            ...{
                width: 300,
                height: 20,
                strokeWidth: 0.75,
                fill: 'transparent',
                stroke: '#000000',
                objectCaching: false,
                cornerColor: "black",
                borderColor: "black",
            },
            ...options,
        });
    }

    _render(ctx: CanvasRenderingContext2D) {
        super._render(ctx);
        ctx.font = '12px sans-serif';
        ctx.fillStyle = 'white';
        ctx.textAlign = "center";
        ctx.fillText('Dry hours legend placeholder', 0, 5);
    }

    update<K extends keyof this>(propertyName: K, value: this[K] | ((value: this[K]) => this[K])): fabric.Object {
        this.set(propertyName, value);
        this.set('dirty', true)
        this.isDirty = true;
        this.canvas?.fire('object:modified')
        return this;
    }

    getProperties(): EditableProperty[] {
        return [
            ...super.getProperties(),
        ];
    }

    renderToSVG(svgElement: SVGSVGElement) {
        d3.select(svgElement)
            .append("rect")
            .attr("x", this.left ?? 0)
            .attr("y", this.top ?? 0)
            .attr("width", this.width ?? 0)
            .attr("height", this.height ?? 0)
            .attr("style", "fill: transparent")
            .attr("type", this.controlType)

        return svgElement;
    }

}