import { useEffect, useRef } from "react";
import { Log } from "../utils/debug";
import { pdfViewerClass } from './pdfViewerStyles';
import { PDFHtmlElementSize } from './pdfViewerTypes';

export type PDFPageProps = {
    /** index of the page */
    index: number
    /** size of the page wrapper */
    scaledSize: PDFHtmlElementSize
    /** size of the page content */
    unscaledSize: PDFHtmlElementSize
    /** render the page content, when this parameter mutates, the page will be re-rendered */
    renderPage: (cellIndex: number, scaledSize: PDFHtmlElementSize, unscaledSize: PDFHtmlElementSize) => Promise<HTMLElement>
};

const PDFPage = ({
    index,
    scaledSize,
    unscaledSize,
    renderPage
}: PDFPageProps) => {

    const pageContentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        (async () => {
            if (!pageContentRef.current)
                return
            try {
                const renderedContent = await renderPage(index, scaledSize, unscaledSize)
                if (!pageContentRef.current)
                    return
                while (pageContentRef.current.firstChild)
                    pageContentRef.current.removeChild(pageContentRef.current.firstChild)
                pageContentRef.current.appendChild(renderedContent)
            } catch (error) {
                Log.error("Error rendering content:", error)
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps -- avoid re-rendering when only reference changes
    }, [renderPage, index, unscaledSize.width, unscaledSize.height, scaledSize.width, scaledSize.height])

    return (
        <div {...pdfViewerClass(`page--${index}`)} style={{ width: scaledSize.width, height: scaledSize.height }}>
            <div ref={pageContentRef} {...pdfViewerClass("page-content")}></div>
        </div>
    )
}

export default PDFPage