
import { createTypedAsyncThunk } from "@/helpers/helpers";
import axios from "axios";
import { api } from "../../../definitions";
import { GithubReleaseRespond } from "../../../definitions/autogenerated/types";
import { githubActions } from "./githubSlice";

export const fetchGithubReleases = createTypedAsyncThunk<GithubReleaseRespond, void>(
    "githubRelease/fetchReleases",
    async (params, thunkAPI) => {
        const response = await axios.get<GithubReleaseRespond>(api.githubList);
        const releases = response.data;
        thunkAPI.dispatch(githubActions.addNewElements(releases));
        return releases;
    }
)

export const createGithubRelease = createTypedAsyncThunk<void, void>( //passt
    "githubRelease/createGithubReleases",
    async () => {
        await axios.post(api.createGithubRelease);
        return;
    }
)