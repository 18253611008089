import { BaseLocation, BaseLocationOptions } from "./baseLocation";

export class DryChamberLocation extends BaseLocation {
    controlType = 'DrychamberLocation';

    constructor(options: BaseLocationOptions) {
        super({
            ...{
                fill: '#ACA794',
                stroke: '#000000',
                strokeWidth: 0.75,
                objectCaching: false,
                cornerColor: "black",
                borderColor: "black",
            },
            ...options
        });
    }

}