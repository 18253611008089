import { SxProps, Theme } from "@mui/material";
import {
    DateTimePicker,
    DateTimePickerProps
} from "@mui/x-date-pickers-pro";
import { DateTime } from "luxon";
import React, { useMemo } from "react";

export type CustomDateTimePickerProps = {
    value: DateTime;
    onChange: (date: DateTime | null) => void;
    label?: string;
    sx?: SxProps<Theme>,
    disabled?: boolean
    invalid?: boolean
};

/**
 * Minimal wrapper to handle onAccept correctly and to debounce typing
 * 
 * @param props need to be provided as {@link CustomDateTimePickerProps} containing value and onChange
 */
export const CustomDateTimePicker = React.memo(function CustomDateTimePicker(props: CustomDateTimePickerProps) {
    const [value, setValue] = React.useState(props.value)

    React.useEffect(() => {
        setValue(props.value)
    }, [props.value])

    const handleOnchange = React.useCallback((v: DateTime | null) => {
        if (v) {
            setValue(v)
        }
    }, [])

    const handleOnAccept = React.useCallback((v: DateTime | null) => {
        if (v) {
            props.onChange(v)
        }

        // after accepting the value we need to make sure the value is up to date
        setValue(props.value)
    }, [props])

    const slotProps: DateTimePickerProps<DateTime, false>["slotProps"] = useMemo(() => ({
        textField: {
            margin: "dense",
            size: "small",
            error: props.invalid
        },
        actionBar: { actions: [] }
    }), [props.invalid])

    return (
        <DateTimePicker 
            sx={props.sx}
            disabled={props.disabled}
            label={props.label}
            value={value}
            onChange={handleOnchange}
            onAccept={handleOnAccept}
            slotProps={slotProps}
        />
    );
});