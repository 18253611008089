import * as d3 from 'd3';
import { fabric } from 'fabric';
import { createFabricControl } from './fabricUtils';
import { EditableProperty } from './objects/objects';

export interface IProgressCanvas {
    originalWidth: number
    originalHeight: number
}

export class ProgressCanvas extends fabric.Canvas implements IProgressCanvas {
    originalWidth = 0;
    originalHeight = 0;

    newHeight = 0;
    newWidth = 0;

    fileHash = "";

    static getInitialJSON(): SerializedData {
        return { width: 1200, height: 840, backgroundColor: '#aabee6', controls: [] }
    }

    acceptChanges() {
        this.getObjects().forEach(object => {
            object.isDirty = false;
        });
        this.isDirty = false;
    }

    zoomIn() {
        const container = this.getElement()?.parentElement?.parentElement?.parentElement;
        const prevScrollHeight = container?.scrollHeight;
        const prevScrollWidth = container?.scrollWidth;

        this.setZoom(this.getZoom() * 1.1).renderAll();
        this.setWidth(this.originalWidth * this.getZoom());
        this.setHeight(this.originalHeight * this.getZoom());

        if (container && prevScrollHeight && prevScrollWidth) {
            container.scrollTop += (container.scrollHeight - prevScrollHeight) / 2
            container.scrollLeft += (container.scrollWidth - prevScrollWidth) / 2
        }
    }

    zoomOut() {
        const container = this.getElement()?.parentElement?.parentElement?.parentElement;
        const prevScrollHeight = container?.scrollHeight;
        const prevScrollWidth = container?.scrollWidth;

        this.setZoom(this.getZoom() / 1.1).renderAll();
        this.setWidth(this.originalWidth * this.getZoom());
        this.setHeight(this.originalHeight * this.getZoom());

        if (container && prevScrollHeight && prevScrollWidth) {
            container.scrollTop += (container.scrollHeight - prevScrollHeight) / 2
            container.scrollLeft += (container.scrollWidth - prevScrollWidth) / 2
        }
    }

    getProperties(): EditableProperty[] {
        return [
            {
                propertyName: "originalWidth",
                displayName: "Width",
                type: "number",
            },
            {
                propertyName: "originalHeight",
                displayName: "Height",
                type: "number",
            },
            {
                propertyName: "backgroundColor",
                displayName: "Color",
                type: "color",
            },
        ];
    }

    update(propertyName: string, value: unknown): ProgressCanvas {
        if (propertyName === "originalHeight") {
            const val = value as number;
            this.originalHeight = val;
            this.setHeight((val) * this.getZoom());
            this.isDirty = true;
        } else if (propertyName === "originalWidth") {
            const val = value as number;
            this.originalWidth = val;
            this.setWidth((val) * this.getZoom());
            this.isDirty = true;
        } else if (propertyName === "backgroundColor") {
            const val = value as string;
            this.setBackgroundColor(val, this.renderAll.bind(this));
            this.isDirty = true;
        }
        return this;
    }

    serialize() {
        const obje: { type: string }[] = [];
        if (this.height && this.width) {
            this.newHeight = this.height / this.getZoom();
            this.newWidth = this.width / this.getZoom();
        }

        this.getObjects().forEach(function (o) {
            if (o.serialize) {
                obje.push(o.serialize() as { type: string })
            }
        })

        return {
            height: this.newHeight,
            width: this.newWidth,
            backgroundColor: this.backgroundColor as string,
            version: "1",
            controls: obje,
        };
    }

    async fromJSON(json: SerializedData) {
        this.originalHeight = json.height;
        this.originalWidth = json.width;

        this.remove(...this.getObjects())
        this.setHeight(json.height * this.getZoom());
        this.setWidth(json.width * this.getZoom());

        if (this.backgroundColor !== json.backgroundColor) {
            this.setBackgroundColor(json.backgroundColor, this.renderAll.bind(this));
        }

        for (const control of json.controls) {
            const fabricControl = await createFabricControl(control);
            if (fabricControl) {
                this.add(fabricControl);
            }
        }

        this.discardActiveObject();
        this.renderAll()
        this.fire('initialized')

        this.isDirty = false;
    }

    renderToSVG() {
        const obje: fabric.Object[] = [];
        this.getObjects().forEach(function (o) {
            obje.push(o)
        })
        const l = obje.length;
        let i = 0;
        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svg.setAttribute("xmlns", "http://www.w3.org/2000/svg")

        if (this.originalWidth && this.originalHeight && this.backgroundColor) {
            d3.select(svg).attr('width', this.originalWidth).attr('height', this.originalHeight);
            d3.select(svg).append("rect").attr("width", this.originalWidth).attr("height", this.originalHeight).attr("style", "fill:" + this.backgroundColor);
        }
        while (i < l) {
            obje[i].renderToSVG(svg)
            i++;
        }

        return svg;
    }

}