/* eslint-disable @typescript-eslint/no-explicit-any */
import { addTranslations, useTypedTranslationHook } from '@progress/base-ui';
import about_de from "../translations/about/de.json";
import about_en from "../translations/about/en.json";
import about_fr from "../translations/about/fr.json";
import about_it from "../translations/about/it.json";
import about_nl from "../translations/about/nl.json";
import about_ru from "../translations/about/ru.json";
import about_zh from "../translations/about/zh.json";
import about_pl from "../translations/about/pl.json";
import about_hu from "../translations/about/hu.json";


const translations_de = {
    about: about_de,
}
const translations_en = {
    about: about_en,
}
const translations_fr = {
    about: about_fr,
}
const translations_it = {
    about: about_it,
}
const translations_nl = {
    about: about_nl,
}
const translations_zh = {
    about: about_zh,
}
const translations_ru = {
    about: about_ru,
}
const translations_pl = {
    about: about_pl,
}
const translations_hu = {
    about: about_hu,
}


// append the resources to the existing i18n-instance of the parent applicaiton
const bundleKey = "about_page";

export const registerTranslations = () => {
    addTranslations({
        bundleKey,
        resourceBundles: {
            de: translations_de,
            en: translations_en,
            fr: translations_fr,
            it: translations_it,
            nl: translations_nl,
            zh: translations_zh,
            ru: translations_ru,
            pl: translations_pl,
            hu: translations_hu
        }
    });
}

export type AboutPageTranslations = typeof translations_de;

export function useTypedTranslation() {
    return useTypedTranslationHook<AboutPageTranslations>(bundleKey);
}