import AddIcon from '@mui/icons-material/Add';
import { Divider, Fab, Menu, MenuItem } from '@mui/material';
import React, { useState, useCallback } from 'react';
import { AddApplication } from '../../components/AddApplication';
import { IConfigAssemblyMapping } from '../../definitions/autogenerated/assemblyMappings';
import { GenerateCrossProgressAuthToken } from './GenerateCrossProgressAuthToken';
import { AuthenticationUtils } from '@/helpers/authenticationUtils';

export const AddApplicationMenu = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [currentItemToAdd, setCurrentItemToAdd] = useState<keyof typeof IConfigAssemblyMapping | "Customer">();
    const hasPermission = AuthenticationUtils.hasAppManagementPermission();

    const handleFabAddClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        if (!hasPermission) {
            return;
        }
        setAnchorEl(event.currentTarget);
    }, [hasPermission]);

    const handleMenuClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const addItem = useCallback((item: keyof typeof IConfigAssemblyMapping | "Customer") => () => {
        setAnchorEl(null);
        setCurrentItemToAdd(item);
    }, [])

    const handleCloseDialog = useCallback(() => {
        setCurrentItemToAdd(undefined)
    }, [])

    const [currentDialog, setCurrentDialog] = useState<"generateXAuthToken">();

    const toggleDialog = useCallback((dialog: typeof currentDialog) => () => {
        setAnchorEl(null);
        setCurrentDialog(dialog)
    }, [])

    return <>
        <Fab
            onClick={handleFabAddClick}
            size="medium"
            color="primary"
            sx={{ position: 'absolute', right: '20px', bottom: '20px' }}
            disabled={!hasPermission}
        >
            <AddIcon />
        </Fab>

        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            {
                // if you modify this list, also modify the list in the controller!
            }
            <MenuItem onClick={addItem("Customer")}>Customer</MenuItem>
            <MenuItem onClick={toggleDialog('generateXAuthToken')}>Generate X-Progress-Auth Token</MenuItem>
            <Divider />
            <MenuItem onClick={addItem("EbosYCProxy")}>ebosYC (Application proxy)</MenuItem>
            <Divider />
            <MenuItem onClick={addItem("ProfitV4WebAPI")}>profitV4 PXML Api</MenuItem>
            <MenuItem onClick={addItem("ProfitYCProxy")}>profitYC (Application proxy)</MenuItem>
            <Divider />
            <MenuItem onClick={addItem("StabosYCV2Proxy")}>stabosYCv2 (Application proxy)</MenuItem>
            <Divider />
            <MenuItem onClick={addItem("MebosYCAPI")}>mebosYC API</MenuItem>
            <MenuItem onClick={addItem("MebosYCWebApp")}>mebosYC Web App</MenuItem>
            <Divider />
            <MenuItem onClick={addItem("ERPbosWebAPI")}>ERPbosWebAPI</MenuItem>
            <Divider />
            <MenuItem onClick={addItem("Ahead")}>ahead</MenuItem>
            <MenuItem onClick={addItem("AheadProxy")}>ahead (Application Proxy)</MenuItem>
            <MenuItem onClick={addItem("AdvancedPlanningService")}>AdvancedPlanningService</MenuItem>
            <Divider />
            <MenuItem onClick={addItem("AvicadYC")}>avicadYC</MenuItem>
            <MenuItem onClick={addItem("CustomerPortal")}>CustomerPortal</MenuItem>
            <MenuItem onClick={addItem("Vision")}>Vision</MenuItem>
        </Menu>

        {
            currentItemToAdd &&
            <AddApplication
                onClose={handleCloseDialog}
                title={currentItemToAdd}
                applicationType={currentItemToAdd}
            />
        }

        {
            currentDialog === 'generateXAuthToken' &&
            <GenerateCrossProgressAuthToken onClose={toggleDialog(undefined)} />
        }
    </>
}
