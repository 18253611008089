import * as d3 from "d3";
import { fabric } from "fabric";
import { BaseComponent } from "./baseComponent";
import { EditableProperty } from './objects'

export class TransportJobLabel extends BaseComponent {
    controlType = 'transportJobLabel';
    deviceId: number | undefined
    textColor: string | undefined
    testValue: number | undefined
    isDirty = false

    constructor(options?: fabric.IRectOptions & { deviceId?: number, textColor?: string }) {
        super({
            // defaults
            ...{
                width: 100,
                height: 50,
                fill: '#FFDD00',
                stroke: 'black',
                strokeWidth: 0.75,
                objectCaching: false,
                cornerColor: "black",
                borderColor: "black",
            },
            ...options
        });

        this.deviceId = options?.deviceId
        this.textColor = options?.textColor ?? "#000000"
    }

    _render(ctx: CanvasRenderingContext2D) {
        super._render(ctx);
        ctx.font = '12px sans-serif';
        ctx.fillStyle = this.textColor ?? "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        ctx.fillText('TransportJobLabel', 0, 0);
    }

    update<K extends keyof this>(propertyName: K, value: this[K] | ((value: this[K]) => this[K])): fabric.Object {
        this.set(propertyName, value);
        this.set('dirty', true)
        this.isDirty = true;
        this.canvas?.fire('object:modified')
        return this;
    }

    getProperties(): EditableProperty[] {
        return [
            ...super.getProperties(),
            { propertyName: "strokeWidth", displayName: "Stroke width", type: "number" },
            { propertyName: "stroke", displayName: "Stroke color", type: "color" },
            { propertyName: "textColor", displayName: "Text color", type: "color" },
            { propertyName: "fill", displayName: "Fill color", type: "color" },
            { propertyName: "deviceId", displayName: "DeviceId", type: "number" },
        ];
    }

    serialize() {
        const top = this.top ?? 0;
        const left = this.left ?? 0;

        const base = {
            type: this.controlType,
            width: this.width ?? 0,
            height: this.height ?? 0,
            textColor: this.textColor,
            strokeWidth: this.strokeWidth,
            stroke: this.stroke,
            fill: this.fill,
            deviceId: this.deviceId,
        }

        if (this.group) {
            const groupTop = this.group.top ?? 0;
            const groupLeft = this.group.left ?? 0;
            const groupHeight = this.group.height ?? 0;
            const groupWidth = this.group.width ?? 0;

            return {
                ...base,
                top: top + groupTop + groupHeight / 2,
                left: left + groupLeft + groupWidth / 2,
            }
        } else {
            return {
                ...base,
                top: top,
                left: left,
            }
        }
    }

    renderToSVG(svgElement: SVGSVGElement) {
        d3.select(svgElement)
            .append("rect")
            .attr("x", this.left ?? 0)
            .attr("y", this.top ?? 0)
            .attr("width", this.width ?? 0)
            .attr("height", this.height ?? 0)
            .style("fill", (this.fill ?? '') as string)
            .attr("type", this.controlType)
            .attr("textColor", this.textColor ?? "black")
            .style("stroke-width", this.strokeWidth ?? 1)
            .style("stroke", this.stroke ?? "#000")
            .attr("deviceId", this.deviceId ?? 0)

        return svgElement;
    }
}